import "./Loader.css";
import React from "react";

const Loader = () => {
   return (
      /* From Uiverse.io by abrahamcalsin */
      <div className="dot-spinner">
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
         <div className="dot-spinner__dot"></div>
      </div>
   );
};

export default Loader;
