import React, { useState, useRef } from "react";
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonPopover, IonList, IonItem, IonContent, IonButton, IonTab, IonImg } from "@ionic/react";

import { Redirect, Route, useHistory } from "react-router-dom";
import {
   settingsOutline,
   swapHorizontalOutline,
   arrowUpOutline,
   walletOutline,
   arrowDownOutline,
   ellipsisHorizontalCircleOutline,
   documentOutline,
   personOutline,
   logOutOutline,
} from "ionicons/icons";

import "./MenuTabs.css";
import Home from "../../pages/Home/Home";
import Send from "../../pages/Send/Send";
import Swap from "../../pages/Swap/Swap";
import Settings from "../../pages/Settings/Settings";
import Receive from "../../pages/Receive/Receive";
import { useCookies } from "react-cookie";
import Recovery from "../../pages/Recovery/Recovery";
import Claim from "../../pages/Claim/Claim";

interface MenuTabsProps {
   profile: any;
}

const MenuTabs: React.FC<MenuTabsProps> = ({ profile }) => {
   const history = useHistory();

   const [showSocialLinks, setShowSocialLinks] = useState(false);
   const [cookies, , removeCookie] = useCookies(["userProfile"]);
   const [, , removeClaimKeyCookie] = useCookies(["claimKey"]);
   const [showPopover, setShowPopover] = useState(false);

   const togglePopover = () => {
      setShowPopover(!showPopover);
      setShowSocialLinks(false);
   };

   const handleLogout = () => {
      cookies.userProfile = undefined;
      localStorage.clear();
      removeCookie("userProfile", { path: "/" });
      removeClaimKeyCookie("claimKey", { path: "/" });
      history.push("/home");
   };

   const moreOptions = [
      {
         label: "Settings",
         icon: settingsOutline,
         onClick: () => {
            history.push("/settings");
            togglePopover();
         },
      },
      {
         label: "Documentation",
         icon: documentOutline,

         onClick: () => {
            window.location.href = "https://docs.socialpal.io/";
            togglePopover();
         },
      },
      {
         label: "Socials",
         icon: personOutline,

         onClick: () => setShowSocialLinks(!showSocialLinks),
         // onMouseEnter: () => setShowSocialLinks(true),
         // onMouseLeave: () => setShowSocialLinks(false),
         socialLinks: [
            {
               label: "Telegram",
               img: "/assets/icon/icon-telegram2.png",
               onClick: () => {
                  window.location.href = "https://t.me/SocialPal_Web3";
                  togglePopover();
               },
            },
            {
               label: "Twitter",
               img: "/assets/icon/icon-x.png",
               onClick: () => {
                  window.location.href = "https://x.com/socialpal_web3";
                  togglePopover();
               },
            },
         ],
      },
      // {
      //    label: "Logout",
      //    icon: logOutOutline,

      //    onClick: () => {
      //       handleLogout();
      //       togglePopover();
      //    },
      // },
   ];

   return (
      <>
         <IonTabs>
            <IonRouterOutlet id="main-content">
               <Route path="/home" component={Home} exact />
               <Route path="/swap" component={Swap} exact />
               <Route path="/send" component={Send} exact />
               <Route path="/receive" component={Receive} exact />
               <Route path="/settings" component={Settings} exact />
               <Route path="/claim" component={Claim} exact={true} />
               <Route path="/recovery" component={Recovery} exact={true} />

               <Redirect from="/" to="/home" exact />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
               <IonTabButton tab="home" href="/home">
                  <IonIcon icon={walletOutline}></IonIcon>
                  <IonLabel>Assets</IonLabel>
               </IonTabButton>
               <IonTabButton tab="swap" href="/swap">
                  <IonIcon icon={swapHorizontalOutline}></IonIcon>
                  <IonLabel>Swap</IonLabel>
               </IonTabButton>
               <IonTabButton tab="send" href="/send">
                  <IonIcon icon={arrowUpOutline}></IonIcon>
                  <IonLabel>Send</IonLabel>
               </IonTabButton>
               <IonTabButton tab="receive" href="/receive">
                  <IonIcon icon={arrowDownOutline}></IonIcon>
                  <IonLabel>Receive</IonLabel>
               </IonTabButton>
               <IonTabButton tab="more">
                  <button id="more-menu" className="menu-more-btn" onClick={() => setShowPopover(true)}>
                     <IonIcon icon={ellipsisHorizontalCircleOutline}></IonIcon>
                     <span>More</span>
                  </button>
               </IonTabButton>
            </IonTabBar>
         </IonTabs>

         <IonPopover
            trigger="more-menu"
            reference="trigger"
            isOpen={showPopover}
            onDidDismiss={() => {
               setShowPopover(false);
               setShowSocialLinks(false);
            }}
            className="more-menu-popover"
         >
            <IonContent className="popover-content">
               <IonList className="more-menu">
                  {moreOptions.map((option, index) => (
                     <div key={index} className="menu-item-wrapper">
                        <button onClick={option.onClick} className="menu-item">
                           <span className="menu-item-label">{option.label}</span>
                           <IonIcon icon={option.icon} className="menu-item-icon" />
                        </button>
                        {option.label === "Socials" && showSocialLinks && (
                           <div className="social-links">
                              {option.socialLinks?.map((socialLink, socialIndex) => (
                                 <div
                                    key={socialIndex}
                                    className="social-link"
                                    onClick={(e) => {
                                       socialLink.onClick();
                                    }}
                                 >
                                    <IonLabel>{socialLink.label}</IonLabel>
                                    <IonImg className="menu-more-item-img" src={socialLink.img} alt={socialLink.label}></IonImg>
                                 </div>
                              ))}
                           </div>
                        )}
                     </div>
                  ))}
               </IonList>
            </IonContent>
         </IonPopover>
      </>
   );
};

export default MenuTabs;
