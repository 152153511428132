import { IonButton, IonCard, IonCardContent, IonIcon, IonText } from "@ionic/react";
import "./SendStep4.css";
import { copyOutline } from "ionicons/icons";
import useSendStore from "../../../store/SendStore";
import { useEffect, useState } from "react";
import useTokenUserStore from "../../../store/TokenUserStore";
import DuoIconsApproved from "../../../assets/DuoApprovedIcon";

interface SendStep4Props {
   closeBegin: () => void;
}

const SendStep4: React.FC<SendStep4Props> = ({ closeBegin }) => {
   const [copied, setCopied] = useState(false);
   const { contactHandle, contactAmount, contactTokenSymbol, contactSendtransaction, contactClaimtransaction, contactChain } = useSendStore();

   const triggerReloadBalance = useTokenUserStore((state) => state.triggerReloadBalance);

   const handleClose = () => {
      closeBegin();
   };

   useEffect(() => {
      triggerReloadBalance();
   }, []);

   const claimText = (): string => {
      return "Hey, I've sent you some crypto with SocialPal wallet.\r\nPlease follow the link below and register an account with your social media to claim your tokens on " + contactChain.toUpperCase() + " Chain. \r\n\r\n" + createClaimAddress();
   }

   const handleAddressCopyClick = async () => {
      try {
         setCopied(true);
         await navigator.clipboard.writeText(claimText());
      } catch (err) {
         console.error("Error when copy text :", err);
      }
   };

   const addDollarForStable = (contactTokenSymbol: string): string => {
      return contactTokenSymbol === "USDC" || contactTokenSymbol === "USDT" ? "$" : "";
   };

   const createClaimAddress = (): string => {
      let domain = window.location.hostname;
      if (!/^https?:\/\//i.test(domain)) {
         domain = `https://${domain}`;
      }
      return domain + "/claim?key=" + contactClaimtransaction;
   };

   return (
      <IonCard className="send-process-card">
         <IonCardContent>
            <div className="page-title">Send</div>
            <div className="send-container-card">
               <div className="send-container-card-main">
                  <div>
                     {/* CLAIM MESSAGE */}
                     {contactClaimtransaction && (
                        <div className="send-step4-container text-center">
                           <div className="send-step4-icon">
                              <div className="send-step4-icon-circle">
                                 <DuoIconsApproved />
                              </div>
                           </div>
                           <div>
                              <br></br>
                              <IonText className="ion-text-center">
                                 <h1>
                                    <b>Transaction successful.</b>
                                 </h1>
                              </IonText>
                           </div>
                           <div className="send-step4-message">
                              {addDollarForStable(contactTokenSymbol)}
                              {contactAmount} {contactTokenSymbol} was successfully sent to {contactHandle}
                           </div>
                           <br></br>
                           <div className="ion-text-center ">
                              The recipient does not currently have a SocialPal account. <br />
                              <br></br>Please send the below text to the recipient, <br></br>so that they can sign up and claim the transaction :
                           </div>
                           <div className="send-step4-claim-url" onClick={handleAddressCopyClick}>
                              <div className="send-step4-url-container">
                                 <p className="send-step4-url-text">
                                    Hey, I've sent you some crypto with SocialPal wallet.<br></br>
                                    Please follow the link below and register an account with your social media to claim your tokens on {contactChain.toUpperCase()} Chain.<br></br><br></br>
                                    {createClaimAddress()}</p>
                              </div>

                              <div className="send-step4-container-copy-link" onClick={handleAddressCopyClick}>
                                 {copied ? (
                                    <span>Copied!</span>
                                 ) : (
                                    <div className="send-step4-icon-btn">
                                       <span>Copy link</span>
                                       <IonIcon icon={copyOutline} size="small"></IonIcon>
                                    </div>
                                 )}
                              </div>
                           </div>

                           <br></br>
                           <br></br>
                        </div>
                     )}

                     {/* SEND MESSAGE */}
                     {contactSendtransaction && (
                        <div className="send-step4-container text-center">
                           <div className="send-step4-icon">
                              <div className="send-step4-icon-circle">
                                 <DuoIconsApproved />
                              </div>
                           </div>
                           <div>
                              <br></br>
                              <IonText className="ion-text-center">
                                 <h1>
                                    <b>Transaction successful.</b>
                                 </h1>
                              </IonText>
                           </div>
                           <br />
                           <div className="send-step4-message">
                              {addDollarForStable(contactTokenSymbol)}
                              {contactAmount} {contactTokenSymbol} was successfully sent to {contactHandle}
                           </div>
                           <br />
                           <br />
                           <div>
                              <a href={contactSendtransaction} target="_blank" rel="noreferrer">
                                 View transaction
                              </a>
                           </div>
                           <br></br>
                           <br></br>
                        </div>
                     )}
                  </div>
                  
                     <div className="send-step4-close">
                        <div className="send-step4-close-btn">
                           <IonButton className="send-content-add-contact-btn" onClick={handleClose}>
                              <span className="button-add-contact">Back</span>
                           </IonButton>
                        </div>
                     </div>
                  
               </div>
            </div>
         </IonCardContent>
      </IonCard>
   );
};

export default SendStep4;
