import Select from "react-select";
import "./SelectToken.css";
import { useDefaultTokens } from "../../hooks/tokens/hooks";
import { Tokens } from "../../hooks/tokens/hooks";

interface SelectTokenProps {
   def: Tokens;
}

const SelectToken: React.FC<SelectTokenProps> = ({ def }) => {
   const customStyles = {
      container: (base: any) => ({
         ...base,
         display: "inline-block",
         width: "-webkit-fill-available",
      }),
      control: (base: any, state: any) => ({
         ...base,
         backgroundColor: "#101010",
         borderRadius: "8px",
         borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.16)",
         boxShadow: state.isFocused ? "0 0 0 1px #101010" : "none",
         minHeight: "40px",
         "&:hover": {
            borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.3)",
         },
      }),
      singleValue: (base: any) => ({
         ...base,
         color: "#ffffff",
         display: "flex",
         alignItems: "center",
         gap: "8px",
      }),
      menu: (base: any) => ({
         ...base,
         backgroundColor: "#1a1a1a",
         borderRadius: "8px",
         overflow: "hidden",
         boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
         border: "1px solid rgba(255,255,255,0.16)",
         position: "absolute",
         zIndex: 9999,
      }),
      menuList: (base: any) => ({
         ...base,
         padding: "0",
         maxHeight: "200px",
         backgroundColor: "#1a1a1a",
      }),
      option: (base: any, state: any) => ({
         ...base,
         backgroundColor: state.isSelected ? "#101010" : state.isFocused ? "rgba(49,130,206,0.2)" : "#1a1a1a",
         color: state.isSelected ? "#ffffff" : "#e0e0e0",
         display: "flex",
         alignItems: "center",
         gap: "8px",
         cursor: "pointer",
         transition: "all 0.2s ease",
         "&:active": {
            backgroundColor: "#101010",
            color: "#ffffff",
         },
      }),
      dropdownIndicator: (base: any) => ({
         ...base,
         color: "rgba(255,255,255,0.6)",
         "&:hover": {
            color: "rgba(255,255,255,0.8)",
         },
      }),
      indicatorSeparator: () => ({
         display: "none",
      }),
   };

   const tokensData = useDefaultTokens();

   return (
      <Select
         styles={customStyles}
         options={tokensData}
         placeholder={"Select token"}
         defaultValue={def}
         formatOptionLabel={(token) => (
            <div className="container-select">
               <img src={`/assets/tokens/${token.symbol}.png`} alt={token.name} />
               <span className="select-label-text">{token.name}</span>
            </div>
         )}
      />
   );
};

export default SelectToken;
