import { GoogleLogin } from "@react-oauth/google";
import "./LoginGoogle.css";
import { jwtDecode } from "jwt-decode";
import { API_URLS } from "../../../config/api/endpoint";
import { setAuthData, setProcessData, setProviderSocial } from "../../../services/auth/authService";
import { IonSpinner, IonToast } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import { SocialMedia } from "../../../enums/social-media.enum";
import { useCookies } from 'react-cookie';



const LoginGoogle: React.FC = () => {
   const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
   const [, setCookie] = useCookies(['userProfile']);
   const [claimKeyCookie, removeClaimKeyCookie] = useCookies(['claimKey']);
   const [showToast, setShowToast] = useState(false);
   const history = useHistory();
   
   return (
      <>
         {isSpinnerVisible && (
            <div className="ion-text-center">
               <IonSpinner></IonSpinner>
            </div>
         )}
         <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Authentification failed, please retry."
            duration={3000}
            color="danger"
         />
         <GoogleLogin
            onSuccess={(credentialResponse) => {
               if (credentialResponse && credentialResponse.credential) {
                  var credentialResponseDecoded = jwtDecode(credentialResponse.credential);
                  //here the data decoded
                  var authData = JSON.stringify({ auth: credentialResponseDecoded });
                  setAuthData(authData);

                  try {
                     const bodyRequest = {
                        auth: {
                           credential: credentialResponse.credential,
                           clientId: credentialResponse.clientId,
                        },
                     };

                     var rr = fetch(`${API_URLS.GOOGLE_PROCESS}`, {
                        headers: {
                           Accept: "application/json",
                           "Content-Type": "application/json",
                           "ct-token": process.env.REACT_APP_CT_TOKEN || "",
                           "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
                        },
                        method: "POST",
                        body: JSON.stringify(bodyRequest),
                     });
                     rr.then(async (response) => {
                        const responseJson = await response.json();
                        if (responseJson.status) {
                           try {

                              setProcessData(JSON.stringify(responseJson));
                              setCookie('userProfile', JSON.stringify(responseJson.message), { path: '/', maxAge: 86400 });
                              setProviderSocial(SocialMedia.GOOGLE);
                              setIsSpinnerVisible(false);
                              // redirect to home
                              const claimKey = claimKeyCookie.claimKey;
                              if (!claimKey) {
                                 history.push(`/home`);
                              } else {
                                 // from claim redirect claim
                                 removeClaimKeyCookie('claimKey', { path: '/' });
                                 history.push(`/claim?key=${claimKey}`);
                              }

                              
                           } catch (e) {
                              console.error("Error when parsing JSON:", e);
                              setIsSpinnerVisible(false);
                           }
                        } else {
                           setShowToast(true);
                           setIsSpinnerVisible(false);
                        }

                     });
                  } catch (err) {
                     console.error(err);
                     setIsSpinnerVisible(false);
                  }
               }
            }}
            onError={() => {
               console.log("Login Failed");
            }}
         />
      </>
   );
};

export default LoginGoogle;
