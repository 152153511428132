import { IonCard, IonCardContent, IonInput, IonToast } from "@ionic/react";
import "./SendStep2.css";
import { useEffect, useState } from "react";
import useSendStore from "../../../store/SendStore";
import TokenList from "./TokenList/TokenList";
import getBalance from "../../../api/balance";
import { ChainProfile } from "../../../entities/chains.entity";
import { useCookies } from "react-cookie";
import { CHAIN_NETWORKS } from "../../../utils/Constants";

interface SendStep2Props {
   prevStep: () => void;
   nextStep: () => void;
}

const SendStep2: React.FC<SendStep2Props> = ({ prevStep, nextStep }) => {
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("");
   const [searchValue, setSearchValue] = useState<string>();
   const [balance, setBalance] = useState<number>(0);
   const [cookiesChainProfile, ] = useCookies(["userChainProfile"]);

   const { contactTokenSymbol, contactTokenId, contactChain, setContactChain, setContactChainId, contactAmount, setContactAmount } = useSendStore();

   useEffect(() => {
      setContactChain(cookiesChainProfile.userChainProfile);
      const chainId = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainId;
      setContactChainId(chainId);
   }, [cookiesChainProfile.userChainProfile]);


   useEffect(() => {
      const getTokenBalance = async () => {
         const chain: ChainProfile = contactChain as ChainProfile;
         const response = await getBalance(chain, contactTokenId);
         setBalance(response.balance ?? 0);
      };
      if (contactChain && contactTokenId) {
         getTokenBalance();
      }
   }, [contactTokenId, contactChain]);

   const handleInputChange = async (event: any) => {
      setContactAmount(event.target.value);
   };

   const handleSearch = async (event: any) => {
      const newQuery = event.target.value;
      setSearchValue(newQuery);
   };

   const handleNextStep = () => {
      if (!contactTokenSymbol || contactTokenSymbol.trim().length === 0) {
         // no token choose
         setMessageToast("Please, choose one token to continue");
         setShowToast(true);
      } else if (Number(contactAmount) === 0) {
         // no amount enter
         setMessageToast("Please add an amount to be sent");
         setShowToast(true);
      } else if (Number(contactAmount) > balance) {
         // amount superior at balance
         setMessageToast("Your balance is insufficient");
         setShowToast(true);
      } else if (isNaN(+contactAmount)) {
         // not a number
         setMessageToast("Please add a correct amount");
         setShowToast(true);
      } else {
         nextStep();
      }
   };

   return (
      <>
         <IonCard className="send-process-card">
            <IonCardContent>
               <div className="page-title">Send</div>
               <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
               <div className="send-container-card">
                  <div className="send-container-card-main">
                     <div>
                        <div className="send-step2-container">
                           <IonInput className="send-step2-custom-input-search" placeholder="Search tokens" onIonInput={handleSearch}></IonInput>
                        </div>
                        <br />
                        <TokenList searchValue={searchValue}></TokenList>

                        <br />
                        <div className="send-step2-container">
                           <div className="send-step2-container-balance-label">Amount to send:</div>
                           <IonInput
                              className="send-content-input-amount"
                              value={contactAmount}
                              slot=""
                              type="text"
                              placeholder="0"
                              onIonInput={handleInputChange}
                           ></IonInput>
                        </div>

                        <div className="send-step2-container">
                           <div className="send-step2-container-balance-label">Amount Available:</div>
                           <div className="send-content-balance">{balance}</div>
                        </div>
                     </div>
                     <div className="send-content-btn-container">
                        <button className="send-content-btn" onClick={prevStep}>
                           Back
                        </button>
                        <button className="send-content-btn" onClick={handleNextStep}>
                           Next
                        </button>
                     </div>
                  </div>
               </div>
            </IonCardContent>
         </IonCard>{" "}
      </>
   );
};

export default SendStep2;
