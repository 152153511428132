import React, { useState } from "react";
import {
   IonButton,
   IonContent,
   IonPage,
   useIonModal,
   IonText,
   IonCol,
   IonGrid,
   IonRow,
   IonImg,
   IonIcon,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import { Tokens } from "../../hooks/tokens/hooks";
import "./ModalReceive.css";
import { copyOutline } from "ionicons/icons";
import SelectToken from "../SelectToken/SelectToken";

const ModalReceiveContent = ({ onDismiss, token }: { onDismiss: (data?: string | null | undefined | number, role?: string) => void; token: Tokens }) => {

   return (
      <IonPage>
         {/* BEGIN: Modal Receive CONTENT */}
         <IonContent className="primary-text">
            <div className="header-receive">
               <IonText className="header-receive-text primary-text header">RECEIVE</IonText>
               <div className="close-container">
                  <IonText className="close-text" onClick={() => onDismiss(null, "cancel")}>
                     X
                  </IonText>
               </div>
            </div>
            <IonGrid>
               <IonRow>
                  <IonCol>
                     <SelectToken def={token}></SelectToken>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol className="col-copy-link">
                     <IonIcon icon={copyOutline} size="small" className="icon-copy-link"></IonIcon>
                     <IonText className="copy-link">copy link</IonText>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol>
                     <div className="container-qr-code">
                        <IonImg src="/assets/tokens/qr-code.png" className="qr-code" alt="qr-code"></IonImg>
                     </div>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol className="primary-text">
                     <div className="container-adress">
                        @BIsk
                        <div className="copy-text">
                           Copy Handle <IonIcon icon={copyOutline} size="small"></IonIcon>
                        </div>
                     </div>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol>
                     <div className="disclaimer">
                        <div>
                           <IonImg src="/assets/icon/danger.png" className="icon-danger" alt="danger"></IonImg>
                        </div>
                        <div>This address can only accept assets on EVM network. Sending any other types of tokens to this address will result in permanent loss.</div>
                     </div>
                  </IonCol>
               </IonRow>
            </IonGrid>
         </IonContent>
         {/* END: Modal Receive CONTENT */}
      </IonPage>
   );
};

function ModalReceive({ token }: { token: Tokens }) {
   const [present, dismiss] = useIonModal(ModalReceiveContent, {
      onDismiss: (data: string, role: string) => dismiss(data, role),
      token: token,
   });

   const [, setMessage] = useState("This modal example uses the modalController to present and dismiss modals.");

   function openModal() {
      present({
         onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            if (ev.detail.role === "confirm") {
               setMessage(`Hello, ${ev.detail.data}!`);
            }
         },
      });
   }

   return (
      <IonButton className="button-send-receive button-receive" expand="block" onClick={() => openModal()}>
         Receive
      </IonButton>
   );
}

export default ModalReceive;
