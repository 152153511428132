import { IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonPage, IonText } from "@ionic/react";
import "./Receive.css";
import { QRCode } from "react-qrcode-logo";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import Trending from "../../components/Trending/Trending";
import { copyOutline } from "ionicons/icons";
import { useCookies } from "react-cookie";
import { useState } from "react";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";

const Receive: React.FC = () => {
   const [cookiesChainProfile] = useCookies(["userChainProfile"]);

   const [copied, setCopied] = useState(false);
   const [copiedHandle, setCopiedHandle] = useState(false);

   const [cookies] = useCookies(["userProfile"]);

   const handleAddressCopyClick = async () => {
      try {
         setCopied(true);
         await navigator.clipboard.writeText(cookies.userProfile.userdata.address);
         setTimeout(() => {
            setCopied(false);
         }, 3000);
      } catch (err) {
         console.error("Error when copy text :", err);
      }
   };

   const handleHandleCopyClick = async () => {
      try {
         setCopiedHandle(true);
         await navigator.clipboard.writeText(cookies.userProfile.userdata.social_handle);
         setTimeout(() => {
            setCopiedHandle(false);
         }, 3000);
      } catch (err) {
         console.error("Error when copy text :", err);
      }
   };

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            {cookies.userProfile ? (
               <>
                  <IonCard className="receive-card">
                     <IonCardContent>
                        <div className="page-title">Receive</div>

                        <div className="receive-container-card">
                           <div className="container-card-content-center">
                              <div className="scan-qr">Scan QR Code</div>
                              <div className="qr-container">
                                 <div className="qr-item">
                                    <QRCode logoImage="/assets/logo2.png" value={cookies.userProfile?.userdata.address} />
                                 </div>
                              </div>
                              <br></br>
                              <div className="receive-address-container">
                                 <h2>{cookies.userProfile?.userdata?.social == "google" ? "Your email address" : "Your social media address"}</h2>
                                 <div className="receive-address" onClick={handleHandleCopyClick}>
                                    <IonText className="receive-address-text">{cookies.userProfile?.userdata.social_handle}</IonText>{" "}
                                    <div className="receive-icon-copy">
                                       <span>Copy</span>
                                       <IonIcon icon={copyOutline} size="small"></IonIcon>
                                    </div>
                                 </div>
                                 {copiedHandle ? <div>Copied !</div> : <div>&nbsp;</div>}
                                 <br></br>
                                 <h2>Your {cookiesChainProfile.userChainProfile?.toUpperCase()} address</h2>
                                 <div className="receive-address" onClick={handleAddressCopyClick}>
                                    <IonText className="receive-address-text">{cookies.userProfile?.userdata.address}</IonText>{" "}
                                    <div className="receive-icon-copy">
                                       <span>Copy</span>
                                       <IonIcon icon={copyOutline} size="small"></IonIcon>
                                    </div>
                                 </div>
                                 {copied ? <div>Copied !</div> : <div>&nbsp;</div>}
                                 <br />
                                 <IonText className="ion-text-center text-grey">
                                    This address can only accept assets on {cookiesChainProfile.userChainProfile?.toUpperCase()} network. <br></br>Sending any other types of tokens
                                    to this address will result in permanent loss.
                                 </IonText>
                              </div>
                           </div>
                        </div>
                     </IonCardContent>
                  </IonCard>
               </>
            ) : (
               <>
                  <HomeLogin></HomeLogin>
               </>
            )}
         </IonContent>
      </IonPage>
   );
};

export default Receive;
