import "./SwapTokenModal.css";
import { searchOutline } from "ionicons/icons";
import { publicToken, userTokens } from "../../../api/token";
import React, { useState, useEffect } from "react";
import searchToken from "../../../api/search-token";
import selectToken from "../../../api/select-token";
import { convertToken2ToToken, SWAP_DEFAULT_TOKEN, useAppContext } from "../../../context/context";
import { CHAIN_NETWORKS } from "../../../utils/Constants";
import { Token } from "../../../entities/user-token.entity";
import { OverlayEventDetail } from "@ionic/core/components";
import useDebounce from "../../../hooks/debounce/useDebounce";
import SwapTokenButton from "../SwapTokenButton/SwapTokenButton";
import { IonContent, IonPage, useIonModal, IonText, IonInput, IonIcon, IonToast } from "@ionic/react";
import { useCookies } from "react-cookie";
import { ChainProfile } from "../../../entities/chains.entity";

export type SwapInstructionDest = "from" | "to";
export type Dismiss = (data?: string | null | undefined | number, role?: string) => void;

const SwapTokenModalContent = ({ onDismiss, type }: { onDismiss: Dismiss; type: SwapInstructionDest }) => {
   const [error, setError] = useState<string>("");
   const [, setIsLoading] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [results, setResults] = useState<Token[]>([]);
   const debounceSearchValue = useDebounce(searchValue, 300);
   const [showToast, setShowToast] = useState<boolean>(false);
   const { setSwapInstruction } = useAppContext();
   const [cookiesChainProfile, setCookiesChainProfile] = useCookies(['userChainProfile']);


   useEffect(() => {

      const fetchData = async () => {
         setIsLoading(true);
         try {
            let data: Token[] = [];
            const chainCall = chainSelected();

            if (!debounceSearchValue) {
               const [publicTokensData, userTokensData] = await Promise.all([publicToken(chainCall
               ), userTokens(chainCall
               )]);

               if (publicTokensData.tokens) {
                  data = [...publicTokensData.tokens.slice(1)]; // Use slice to avoid mutation
               }
               if (userTokensData.tokens) {
                  data = [...data, ...userTokensData.tokens];
               }
            } else {
               const searchData = await searchToken(debounceSearchValue, CHAIN_NETWORKS[chainCall
               ].chainId);
               if (searchData.tokens) {
                  data = searchData.tokens;
               }
            }
            const filteredDataFromDublicates = data.filter((v, i, a) => a.findIndex((t) => t.address === v.address) === i);
            const mainToken = convertToken2ToToken(SWAP_DEFAULT_TOKEN[chainCall
            ]);

            setResults([mainToken, ...filteredDataFromDublicates]);
         } catch (error) {
            console.error("Error fetching tokens: ", error);
         } finally {
            setIsLoading(false);
         }
      };

      fetchData();
   }, [debounceSearchValue]);



   const handleCardClick = async (token: Token) => {
      try {
         const selectTokenRes = await selectToken(token.address, CHAIN_NETWORKS[chainSelected()].chainId);
         setSwapInstruction((prev) => ({ ...prev, [type]: selectTokenRes.token }));
         if (selectTokenRes.error) {
            setError(selectTokenRes.error);
            setShowToast(true);
         }
         setCookiesChainProfile("userChainProfile", chainSelected())
         onDismiss(null, "cancel");
      } catch (error) {
         setError("");
         setShowToast(true);
         console.error("Error selecting token: ", error);
      }
   };

   const chainSelected = (): ChainProfile => {
      return cookiesChainProfile.userChainProfile;
   }

   return (
      <IonPage>
         {/* BEGIN: Modal login CONTENT */}
         <IonContent>
            <div className="swap-modal-header">
               <IonText className="swap-modal-header-close-text" onClick={() => onDismiss(null, "cancel")}>
                  X
               </IonText>
            </div>
            <div className="swap-modal-container">
               <IonInput className="swap-modal-input-search" placeholder="Search tokens" onIonInput={(e) => setSearchValue(e.detail.value || "")}>
                  <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="swap-modal-icon-search"></IonIcon>
               </IonInput>
            </div>

            <div className="swap-modal-token-scrool">
               {results &&
                  results.length > 0 &&
                  results.map((token: Token, index: number) => (
                     <div key={`token-box-div_` + index} className="swap-modal-container-token-box" onClick={() => handleCardClick(token)}>
                        <div className="swap-modal-container-token-box-line">
                           <div>{token.symbol}</div>
                        </div>
                        <div className="swap-modal-container-token-box-line">
                           <div>{token.address}</div>
                        </div>
                     </div>
                  ))}
            </div>
         </IonContent>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={error || "Selection failed, please try it again."} duration={3000} color="danger" />
         {/* END: Modal login CONTENT */}
      </IonPage>
   );
};

function SwapTokenModal({ type }: { type: SwapInstructionDest }) {
   const [present, dismiss] = useIonModal(SwapTokenModalContent, {
      onDismiss: (data: string, role: string) => dismiss(data, role),
      type,
   });

   function openModal() {
      present({
         onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            // console.log("==== modal swap ====");
         },
      });
   }

   return (
      <div onClick={() => openModal()}>
         <SwapTokenButton type={type} />
      </div>
   );
}

export default SwapTokenModal;
