import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import "./SocialContactSelectMini.css";
import { SOCIAL_NAMES } from "../../../../utils/Constants";
import { Socials } from "../../../../entities/socials.entity";
import { getLocalStorageProviderSocial } from "../../../../services/auth/authService";

interface SocialContactSelectMiniProps {
   onContactSocialChange: (social: Socials) => void;
   contactSocialVal: Socials;
}

const SocialContactSelectMini: FC<SocialContactSelectMiniProps> = ({ onContactSocialChange, contactSocialVal }) => {
   const customStyles = {
      container: (base: any) => ({
         ...base,
         display: "inline-block",
         width: "5rem",
         padding: "5 0 0 5",
      }),
      control: (base: any, state: any) => ({
         ...base,
         backgroundColor: "#101010",
         borderRadius: "8px",
         borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.16)",
         boxShadow: state.isFocused ? "0 0 0 1px #101010" : "none",
         minHeight: "40px",
         "&:hover": {
            borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.3)",
         },
      }),
      singleValue: (base: any) => ({
         ...base,
         color: "#ffffff",
         display: "flex",
         alignItems: "center",
         gap: "8px",
      }),
      menu: (base: any) => ({
         ...base,
         backgroundColor: "#1a1a1a",
         borderRadius: "8px",
         overflow: "hidden",
         boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
         border: "1px solid rgba(255,255,255,0.16)",
         position: "absolute",
         zIndex: 9999,
      }),
      menuList: (base: any) => ({
         ...base,
         padding: "0",
         maxHeight: "200px",
         backgroundColor: "#1a1a1a",
      }),
      option: (base: any, state: any) => ({
         ...base,
         backgroundColor: state.isSelected ? "#101010" : state.isFocused ? "rgba(49,130,206,0.2)" : "#1a1a1a",
         color: state.isSelected ? "#ffffff" : "#e0e0e0",
         display: "flex",
         alignItems: "center",
         gap: "8px",
         cursor: "pointer",
         transition: "all 0.2s ease",
         "&:active": {
            backgroundColor: "#101010",
            color: "#ffffff",
         },
      }),
      dropdownIndicator: (base: any) => ({
         ...base,
         color: "rgba(255,255,255,0.6)",
         "&:hover": {
            color: "rgba(255,255,255,0.8)",
         },
      }),
      indicatorSeparator: () => ({
         display: "none",
      }),
   };

   const [results, setResults] = useState<Socials[]>([]);

   useEffect(() => {
      const socialDefault = contactSocialVal || getDefaultValue();
      onContactSocialChange(socialDefault);
   }, [contactSocialVal]);

   useEffect(() => {
      setResults(SOCIAL_NAMES);
   }, [results]);

   const getDefaultValue = () => SOCIAL_NAMES.find((item) => item.name?.toLowerCase().includes(getLocalStorageProviderSocial()?.toLowerCase())) || SOCIAL_NAMES[0];

   const handleChange = (event: any) => {
      onContactSocialChange(event);
   };

   return (
      <div>
         {SOCIAL_NAMES && (
            <Select
               styles={customStyles}
               value={contactSocialVal}
               options={results}
               isSearchable={false}
               onChange={handleChange}
               placeholder={"Socials"}
               formatOptionLabel={(social) => (
                  <div className="select-contact-mini-social">
                     <img className="select-contact-mini-image" src={`assets/socials/${social.name.toLowerCase()}_tb40.png`} alt={social.name.toLowerCase()} />
                  </div>
               )}
            />
         )}
      </div>
   );
};

export default SocialContactSelectMini;
