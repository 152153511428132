import { API_URLS } from "../config/api/endpoint";
import { ChainProfile } from "../entities/chains.entity";
import authTokenService from "../services/auth/authTokenService";

export const userTokens = async (chain: ChainProfile) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.USER_TOKEN}${chain}`, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });

      // await ApiErrorHandler.handleResponseError(response);

      if (!response.ok) {
         const error = await response.text();
         console.log('error: ', error);
         return { tokens: null, error: "Failed to fetch user tokens" };
      }

      const data = await response.json();
      return { tokens: data.message.tokens, error: null };
   } catch (err: any) {
      console.error("Error when fetch favourite tokens: ", err);
      return { tokens: null, error: err.message };
   }
};


export const publicToken = async (chain: ChainProfile) => {
   try {
      const response = await fetch(`${API_URLS.PUBLIC_TOKEN}${chain}`, {
         method: "GET",
         headers: {
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });

      if (!response.ok) {
         const error = await response.text();
         console.log('error: ', error);
         return { tokens: null, error: "Failed to fetch user tokens" };
      }

      const data = await response.json();
      return { tokens: data.message.tokens, error: null };
   } catch (err: any) {
      return { tokens: null, error: err.message };
   }
}
