import { IonCard, IonCardContent, IonSpinner, IonText, IonToast } from "@ionic/react";
import "./SendStep3.css";

import useSendStore from "../../../store/SendStore";
import { API_URLS } from "../../../config/api/endpoint";
import { ApiErrorHandler } from "../../../utils/ApiErrorHandler";
import { useState } from "react";
import { claimApiService } from "../../../services/claim/claimService";
import { searchApiService } from "../../../services/search/searchApiService";
import authTokenService from "../../../services/auth/authTokenService";

interface SendStep3Props {
   prevStep: () => void;
   nextStep: () => void;
}

const SendStep3: React.FC<SendStep3Props> = ({ prevStep, nextStep }) => {
   const [showToast, setShowToast] = useState(false);
   const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

   const { contactHandle, contactSocial, contactAmount, contactChain, contactChainId, contactTokenSymbol, contactTokenId, setContactSendtransaction, setContactClaimtransaction } =
      useSendStore();

   const handleSend = () => {
      setIsSpinnerVisible(true);

      const sendToken = async () => {
         const result = await authTokenService();

         const response = await fetch(`${API_URLS.SEND_TOKEN}`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${result.token}`,
               "ct-token": process.env.REACT_APP_CT_TOKEN || "",
               "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
            },
            body: JSON.stringify({
               social: (contactSocial === "" ? null : contactSocial),
               dest: contactHandle,
               chain: contactChain.toLowerCase(),
               token: contactTokenId,
               amount: contactAmount,
            }),
         });

         const responseJson = await response.json();

         if (response.ok && responseJson.status) {
            try {
               const explorer = responseJson.message.send.explorer;

               setContactSendtransaction(explorer);

               if (contactTokenId !== -1) {
                  // bookmarks token
                  const bookmarksToken = async () => {
                     await searchApiService.bookmarksToken({
                        action: "add",
                        token: contactTokenId,
                        chain: contactChainId,
                     });
                  };
                  bookmarksToken();
               }

               setIsSpinnerVisible(false);
               nextStep();
            } catch (e) {
               console.error("Error when parsing JSON:", e);
               setShowToast(true);
               setIsSpinnerVisible(false);
            }
         } else if (!responseJson.status && isInvalidUser(responseJson)) {
            // create claimGen
            const claimGen = async () => {
               const response = await claimApiService.claimGen({
                  chain: contactChain.toLowerCase(),
                  token: contactTokenId,
                  amount: Number(contactAmount),
               });

               const { statusCode } = await ApiErrorHandler.handleResponseError(response);

               if (statusCode === 200) {
                  const responseJson = await response.json();
                  setContactClaimtransaction(responseJson.message.send_data.claim_key);
                  nextStep();
               } else {
                  setShowToast(true);
                  setIsSpinnerVisible(false);
               }
            };
            claimGen();
         } else {
            setShowToast(true);
            setIsSpinnerVisible(false);
         }
      };

      sendToken();
   };

   const isInvalidUser = (errorMessage: any): boolean => {
      return errorMessage.message.error === "Invalid User";
   };

   return (
      <IonCard className="send-process-card">
         <IonCardContent>
            <div className="page-title">Send</div>
            <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message="Transaction failed, please try it again." duration={3000} color="danger" />

            <div className="send-container-card">
               <div className="send-container-card-main">

                     <IonText className="ion-text-center">
                        <h1>
                           <b>
                              {contactAmount} {contactTokenSymbol}
                           </b>
                        </h1>
                     </IonText>
                     <div className="swap-step2-container">
                     <div className="send-step3-item-container">
                        <div className="send-step3-item-details  send-step3-item-details-top">
                           <div>To:</div>
                           <h4>{contactHandle}</h4>
                        </div>

                        <div className="send-step3-item-details send-step3-item-details-middle">
                           <div>Social:</div>
                           <h4>{contactSocial === "" ? "Blockchain address" : contactSocial}</h4>
                        </div>

                        <div className="send-step3-item-details send-step3-item-details-bottom">
                           <div>Network:</div>
                           <h4>{contactChain}</h4>
                        </div>
                     </div>
                     </div>
                  <div className="send-content-btn-container">
                     <button className="send-content-btn" onClick={prevStep}>
                        Back
                     </button>
                     <button className="send-content-btn" onClick={handleSend} disabled={isSpinnerVisible}>
                        {isSpinnerVisible && <IonSpinner></IonSpinner>}
                        Next
                     </button>
                  </div>
               </div>
            </div>
         </IonCardContent>
      </IonCard>
   );
};

export default SendStep3;
