import { IonButton, IonToast } from "@ionic/react";
import { claimApiService } from "../../../services/claim/claimService";
import { ApiErrorHandler } from "../../../utils/ApiErrorHandler";
import useClaimStore from "../../../store/ClaimStore";
import "./ClaimStep1.css";
import { useState } from "react";
import { useCookies } from "react-cookie";

interface Claim1Props {
   nextStep: () => void;
   claimKey: string | null;
}

const ClaimStep1: React.FC<Claim1Props> = ({ nextStep, claimKey }) => {
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("Transaction failed, please try it again.");

   const { setClaimExplorer, setClaimAmount, setClaimTokenSymbol } = useClaimStore();

   const [cookiesChainProfile] = useCookies(["userChainProfile"]);

   const handleClaim = async () => {
      if (claimKey) {
         const claimRetrieve = async () => {
            const response = await claimApiService.claimRetrieve({
               chain: cookiesChainProfile.userChainProfile,
               key: claimKey,
            });

            const status = await ApiErrorHandler.handleResponseError(response);

            if (status.statusCode === 200) {
               const responseJson = await response.json();

               if (!responseJson.status && responseJson.message.error) {
                  setMessageToast(responseJson.message.error);
                  setShowToast(true);
               } else {
                  setClaimExplorer(responseJson.message.send_data.tx_explorer);
                  setClaimTokenSymbol(responseJson.message.send_data.token);
                  setClaimAmount(responseJson.message.send_data.amount);
                  nextStep();
               }
            } else {
               setShowToast(true);
            }
         };
         claimRetrieve();
      }
   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div className="claim-container">
            <div className="claim-step1-text">
               You can now claim your tokens with your social media account. <br></br>Cool, right?
               <br></br>
               <br />
               <br />
               Please click the claim button below to complete the process and enjoy your crypto experience with SocialPal wallet.
            </div>
            <div className="claim-btn-container">
               <IonButton className="claim-btn" onClick={handleClaim}>
                  Claim
               </IonButton>
            </div>
         </div>
      </>
   );
};

export default ClaimStep1;
