import { useEffect } from "react";

import { IonSpinner } from "@ionic/react";
import { setAuthData, setProcessData, setProviderSocial } from "../../../../services/auth/authService";
import { API_URLS } from "../../../../config/api/endpoint";
import { useHistory } from "react-router";
import { SocialMedia } from "../../../../enums/social-media.enum";
import { useCookies } from "react-cookie";

interface Props {
   socialpal_authurl: string;
   newwallet_redirect: string;
   login_redirect: string;
}

export const TwitterLoginController = ({  }: Props) => {

   const history = useHistory();
   const [, setCookie] = useCookies(['userProfile']);
   const [claimKeyCookie, removeClaimKeyCookie] = useCookies(['claimKey']);
   
   useEffect(() => {
      const abortController = new AbortController();
      const authWindorURL = new URL(window.location.href);
      const uuid = authWindorURL.searchParams.get("auth_uuid");
      const code = authWindorURL.searchParams.get("code");
      const state = authWindorURL.searchParams.get("state");

      if (state && code && uuid) {
         const auth_url = `${API_URLS.TWITTER_PROCESS}/?auth_uuid=${uuid}&state=${state}&code=${code}`;
         fetch(auth_url, {
            method: "GET",
            mode: "cors",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "ct-token": process.env.REACT_APP_CT_TOKEN || "",
               "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
            },
            signal: abortController.signal,
         })
            .then((data) => data.json())
            .then((DataObj) => {
               console.log('==== controller x 001 ====', DataObj);
               if (!DataObj.hasOwnProperty("status")) {
                  window.location.href = "/";
               } else if (!DataObj.status) {
                  if (DataObj.hasOwnProperty("message") && DataObj.message.hasOwnProperty("error")) {
                     //TBD
                     window.location.href = "/";
                  }
               } else if (DataObj.status && DataObj.hasOwnProperty("message")) {

                  setProcessData(JSON.stringify(DataObj));
                  setAuthData(JSON.stringify(DataObj));
                  setCookie('userProfile', JSON.stringify(DataObj.message), { path: '/', maxAge: 86400 });
                  setProviderSocial(SocialMedia.X);
                  
                  const claimKey = claimKeyCookie.claimKey;
                  if (!claimKey) {
                     history.push(`/home`);
                  } else {
                     // from claim redirect claim
                     removeClaimKeyCookie('claimKey', { path: '/' });
                     history.push(`/claim?key=${claimKey}`);
                  }
               }
            });
      }
      return () => {
         abortController.abort();
      };
   }, []);
   return <IonSpinner />;
};

export default TwitterLoginController;
