import { API_URLS } from "../../config/api/endpoint";
import authTokenService from "../auth/authTokenService";

export interface ClaimGenParams {
  chain: string;
  token: number;
  amount: number;
}

export interface ClaimRetreiveParams {
  chain: string;
  key: string;
}


class ClaimApiService {
  private readonly headers: HeadersInit = {
    'Content-Type': 'application/json',
    "ct-token": process.env.REACT_APP_CT_TOKEN || "",
    "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
  };
  /**
   * API Claim token
   * @param params
   * @returns 
   */
  async claimGen(params: ClaimGenParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.CLAIM_GEN}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no claim generated");
    }
    const data = await response;
    return data;
  }

  async claimRetrieve(params: ClaimRetreiveParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.CLAIM_RETRIEVE}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no claim generated");
    }
    const data = await response;
    return data;
  }

}
export const claimApiService = new ClaimApiService();