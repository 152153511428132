import Menu from "./components/Menu";
import { IonApp, IonRouterOutlet, IonSplitPane, IonToast, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import AuthController from "./pages/AuthController/AuthController";
import PageOne from "./pages/PageOne/PageOne";
import Home from "./pages/Home/Home";
import Tokens from "./pages/Tokens/Tokens";
import UserProfileProvider from "./context/context";
import { useState } from "react";
import { useCookies } from "react-cookie";

import { GoogleOAuthProvider } from "@react-oauth/google";
import ExternalRedirect from "./components/ExternalRedirect/ExternalRedirect";
import ValidateTelegram from "./pages/ValidateTelegram/ValidateTelegram";
import { useMediaQuery } from "react-responsive";
import MenuTabs from "./components/MenuTabs/MenuTabs";
import Receive from "./pages/Receive/Receive";
import Send from "./pages/Send/Send";
import Swap from "./pages/Swap/Swap";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Claim from "./pages/Claim/Claim";
import Settings from "./pages/Settings/Settings";
import Recovery from "./pages/Recovery/Recovery";

setupIonicReact();

const App: React.FC = () => {
   const queryClient = new QueryClient();
   const [showToast, setShowToast] = useState(false);
   const [cookies] = useCookies(["userProfile"]);

   const isDesktop = useMediaQuery({ minWidth: 992 });

   return (
      <QueryClientProvider client={queryClient}>
         <UserProfileProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}>
               <IonApp>
                  <IonToast
                     isOpen={showToast}
                     onDidDismiss={() => setShowToast(false)}
                     message="Your session has expired. Please log in again to continue."
                     duration={3000}
                     color="danger"
                  />

                  <IonReactRouter>
                     {isDesktop ? (
                        <IonSplitPane contentId="main">
                           <Menu profile={cookies.userProfile} />
                           <IonRouterOutlet id="main">
                              <Route path="/" exact={true}>
                                 <Redirect to="/home" />
                              </Route>

                              <Route path="/auth-controller" exact={true}>
                                 <AuthController />
                              </Route>
                              <Route path="/home" exact={true}>
                                 <Home />
                              </Route>

                              <Route path="/swap" exact={true}>
                                 <Swap></Swap>
                              </Route>

                              <Route path="/send" exact={true}>
                                 <Send></Send>
                              </Route>

                              <Route path="/receive" exact={true}>
                                 <Receive></Receive>
                              </Route>

                              <Route path="/claim" exact={true}>
                                 <Claim></Claim>
                              </Route>

                              <Route path="/settings" exact={true}>
                                 <Settings></Settings>
                              </Route>

                              <Route path="/recovery" exact={true}>
                                 <Recovery></Recovery>
                              </Route>

                              <Route path="/token" exact={true}>
                                 <Tokens />
                              </Route>

                              <Route path="/telegram" exact={true}>
                                 <ExternalRedirect url="./js/telegram.html"></ExternalRedirect>
                              </Route>

                              <Route path="/telegram/validate" exact={true}>
                                 <ValidateTelegram></ValidateTelegram>
                              </Route>

                              <Route path="/PageOne" exact={true}>
                                 <PageOne />
                              </Route>
                           </IonRouterOutlet>
                        </IonSplitPane>
                     ) : (
                        <MenuTabs profile={cookies.userProfile}></MenuTabs>
                     )}
                  </IonReactRouter>
               </IonApp>
            </GoogleOAuthProvider>
         </UserProfileProvider>
      </QueryClientProvider>
   );
};

export default App;
