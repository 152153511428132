import "./SwapStep1.css";
import { useEffect, useMemo, useRef, useState } from "react";
import getBalance from "../../../api/balance";
import { swapVerticalOutline } from "ionicons/icons";
import { SWAP_DEFAULT_INSTRUCTION, useAppContext } from "../../../context/context";
import { IonIcon, IonButton, IonToast } from "@ionic/react";
import SwapTokenModal from "../SwapTokenModal/SwapTokenModal";
import swapQuote from "../../../api/quote";
import Loader from "../../Loader/Loader";
import useDebounce from "../../../hooks/debounce/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { decimalValidator } from "../../../utils/utils";
import { Token2 } from "../../../entities/user-token.entity";
import { publicToken } from "../../../api/token";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";
import selectToken from "../../../api/select-token";
import { getNetworkKeyByChainId } from "../../../utils/Constants";

interface SwapStep1Props {
   nextStep: () => void;
}

const SwapStep1: React.FC<SwapStep1Props> = ({ nextStep }) => {
   const [amount, setAmount] = useState<string>("");
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const [, setError] = useState<string>("");
   const debounceAmount = useDebounce(parseFloat(amount), 500);
   const [showToast, setShowToast] = useState<boolean>(false);
   const [callBalance, setCallBalance] = useState<boolean>(false);
   const [isQuoteLoading, setIsQuoteLoading] = useState(false);
   const { swapInstruction, setQuote, quote, setSwapInstruction } = useAppContext();
   const [cookiesChainProfile, setCookiesChainProfile] = useCookies(["userChainProfile"]);
   const [cookiesSlippageProfile, setCookiesSlippageProfile] = useCookies(["userSlippageProfile"]);
   const stableChainProfile = useMemo(() => cookiesChainProfile.userChainProfile, [cookiesChainProfile.userChainProfile]);
   const stableSwapInstructionFrom = useMemo(() => swapInstruction.from, [swapInstruction.from]);
   const stableSwapInstructionFromSwapUrl = useMemo(() => swapInstruction.fromSwapUrl, [swapInstruction.fromSwapUrl]);
   const location = useLocation();
   const lastChainProfile = useRef(stableChainProfile);
   const lastTokenId = useRef(stableSwapInstructionFrom?.token_id);
   const searchParams = new URLSearchParams(location.search);
   const swapChain = searchParams.get("chain");
   const swapAddress = searchParams.get("address");
   const [isBalanceLoading, setIsBalanceLoading] = useState(false);

   useEffect(() => {
      if (location.pathname === "/swap") {
         const slippageUser = cookiesSlippageProfile.userSlippageProfile ? cookiesSlippageProfile.userSlippageProfile : SWAP_DEFAULT_INSTRUCTION.slippage;
         // with URL params
         if (swapChain && swapAddress) {
            const networkKey = getNetworkKeyByChainId(Number(swapChain));
            setCookiesChainProfile("userChainProfile", networkKey);
            // set user slippage
            setSwapInstruction((prev) => ({ ...prev, slippage: slippageUser, fromSwapUrl: true }));
         } else {
            setSwapInstruction((prev) => ({ ...prev, slippage: slippageUser }));
         }

         setCallBalance(true);
      }
   }, [location.pathname]);

   const { data: balance } = useQuery<number>({
      queryKey: [stableSwapInstructionFrom],
      queryFn: async () => {
         // To not call many time
         if (lastChainProfile.current === stableChainProfile && lastTokenId.current === stableSwapInstructionFrom?.token_id && !swapInstruction.fromNotReload && !callBalance) {
            return balance;
         }
         lastChainProfile.current = stableChainProfile;
         lastTokenId.current = stableSwapInstructionFrom?.token_id;
         setIsBalanceLoading(true);
         const result = await getBalance(stableChainProfile, stableSwapInstructionFrom.token_id);
         setIsBalanceLoading(false);
         if (result.error && !result.balance) {
            setError(result.error);
            return 0;
         }

         return result.balance!;
      },
      enabled: !!stableChainProfile && !!stableSwapInstructionFrom?.symbol && callBalance && !stableSwapInstructionFromSwapUrl,
   });

   const { data: publicTokens } = useQuery<Token2[]>({
      queryKey: ["publicTokens", cookiesChainProfile.userChainProfile],
      queryFn: async () => (await publicToken(cookiesChainProfile.userChainProfile)).tokens,
      enabled: !!cookiesChainProfile.userChainProfile, // Ensure the query is only triggered when chainProfile is available
   });

   useEffect(() => {
      if (swapInstruction.fromNotReload) {
         setAmount(swapInstruction.amount);
      } else {
         setAmount("");
         setQuote(undefined);
      }

   }, []);

   useEffect(() => {
      if (publicTokens && !swapInstruction.fromNotReload) {
         // normal init
         handlePublicTokens();
      }
   }, [cookiesChainProfile.userChainProfile, publicTokens]);

   useEffect(() => {
      if (lastChainProfile.current === stableChainProfile) {
         return;
      }
      setSwapInstruction((prev) => ({ ...prev, amount: SWAP_DEFAULT_INSTRUCTION.amount }));
      setAmount("");
      setQuote(undefined);
   }, [stableChainProfile]);

   useEffect(() => {

      if (swapInstruction.fromNotReload) {
         handlePublicTokensNotReload();
      }
      if (swapInstruction.fromSwapUrl) {
         handlePublicTokensSwapUrl();
      }
   }, [swapInstruction.fromNotReload, swapInstruction.fromSwapUrl]);

   const handlePublicTokensSwapUrl = async () => {
      try {
         const selectTokenRes = await selectToken(swapAddress, Number(swapChain));
         setSwapInstruction((prev) => ({ ...prev, to: selectTokenRes.token, fromSwapUrl: false }));
         if (selectTokenRes.error) {
            setMessageToast(selectTokenRes.error);
            setShowToast(true);
         }
      } catch (error) {
         setShowToast(true);
         console.error("Error loading swap: ", error);
      }
   };

   const handlePublicTokensNotReload = async () => {
      setSwapInstruction((prev) => ({ ...prev, fromNotReload: false }));
   };



   const handlePublicTokens = async () => {
      // default pair with hotfix to refresh swapInstruction.from
      setSwapInstruction({
         ...swapInstruction,
         from: { ...publicTokens[0], symbol: publicTokens[0].symbol.toUpperCase(), token_id: (publicTokens[0] as any).id, chain: cookiesChainProfile.userChainProfile},
         to: { ...publicTokens[1], symbol: publicTokens[1].symbol.toUpperCase(), token_id: (publicTokens[1] as any).id },
      });
   };

   const handleAmount = (data: string) => {
      const amountDecimal = decimalValidator(data);
      setAmount(amountDecimal);
      setSwapInstruction((p) => ({ ...p, amount: amountDecimal }));
   };

   const handleNextStep = () => {
      if (swapInstruction.amount === "") {
         // no amount enter
         setMessageToast("Please add an amount to be swapped");
         setShowToast(true);
      } else if (Number(amount) > (balance || 0)) {
         // amount superior at balance
         setMessageToast("Your balance is insufficient");
         setShowToast(true);
      } else {
         nextStep();
      }
   };

   // Fetch quote based on the amount and balance
   const handleQuote = async (amount: number) => {
      try {
         setIsQuoteLoading(true);

         // Ensure valid quote amount
         // const quoteAmount = Math.min(amount, balance || 0);
         if (amount <= 0) {
            console.error("Quote amount should be greater than 0");
            return;
         }

         const payload = {
            sell_token_id: swapInstruction.from.token_id,
            buy_token_id: swapInstruction.to.token_id,
            chain: cookiesChainProfile.userChainProfile,
            amount: amount,
            slippage: Number(swapInstruction.slippage),
         };

         console.log("payload:", payload);

         // Fetch quote
         const quoteData = await swapQuote(payload);
         if (quoteData.quote) {
            setQuote(quoteData.quote);
            console.log("quote:", quoteData.quote);
         }
      } catch (error) {
         console.error("Error fetching quote:", error);
      } finally {
         setIsQuoteLoading(false); // Ensure loading state is updated
      }
   };

   useEffect(() => {
      // only if amount greater than and debounceAmount = amount no not call twice
      if (debounceAmount > 0 && debounceAmount === parseFloat(amount)) {
         handleQuote(debounceAmount);
      } else if (isNaN(+debounceAmount)) {
         setQuote(undefined);
      }
   }, [debounceAmount, swapInstruction]);

   const reverseToken = () => {
      setSwapInstruction((prev) => ({
         ...prev,
         from: prev.to,
         to: prev.from,
      }));
   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />

         <div className="swap-container-card-content">
            <div>
               {/* SECTION: FROM */}
               <div className="swap-container-card-content-group">
                  <div className="swap-container-card-content-from">
                     <span>FROM</span>{" "}
                     <span>
                        <div className="swap1-container-balance">
                           Balance: {balance?.toFixed(6)} {swapInstruction.from?.symbol}
                           {isBalanceLoading && (
                              <Loader/>
                           )}
                        </div>
                     </span>
                  </div>

                  {/* SECTION: TOKEN BUTTON FROM  */}
                  <div className="swap-container-card-content-token-btn-from">
                     <div>
                        <SwapTokenModal key={1} type="from" />
                     </div>

                     <input
                        type="text"
                        value={amount}
                        className="swap-container-card-content-token-btn-from-input"
                        onChange={(e) => handleAmount(e.target.value)}
                        placeholder="0.00"
                     />
                  </div>
               </div>

               {/* SECTION: LINE SEPARATOR */}
               <div className="swap-container-card-content-separator">
                  <div className="swap-container-card-content-separator-line"></div>
                  <button onClick={reverseToken} className="btn-unstyled">
                     <IonIcon icon={swapVerticalOutline} className="swap-container-card-content-separator-icon"></IonIcon>
                  </button>
                  <div className="swap-container-card-content-separator-line"></div>
               </div>
               {/* SECTION: TO */}

               <div className="swap-container-card-content-group">
                  <div className="swap-container-card-content-from">
                     <span>TO</span>{" "}
                  </div>

                  {/* SECTION: TOKEN BUTTON  */}
                  <div className="swap-container-card-content-token-btn-from">
                     <div>
                        <SwapTokenModal key={2} type="to" />
                     </div>

                     <div className="swap-container-card-content-token-btn-from-convert-text">{quote?.out_amount?.toFixed(6)}
                     {isQuoteLoading && (
                              <Loader/>
                           )}

                     </div>
                  </div>
               </div>


               {/* SECTION: BUTTON SWAP */}
               <br />
               <br />
            </div>
            <div className="swap-btn-container-main">
               <div className="swap-container-btn">
                  <IonButton className="swap-container-card-content-check-btn" onClick={handleNextStep} disabled={isQuoteLoading}>
                     <span>Swap</span>
                  </IonButton>
               </div>
            </div>
         </div>
      </>
   );
};

export default SwapStep1;
