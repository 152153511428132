import React, { useState, useEffect } from "react";
import { IonButton, IonContent, IonPage, useIonModal, IonText } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import { Tokens } from "../../../hooks/tokens/hooks";
import "./ModalLogin.css";
import LoginSocial from "../LoginSocial/LoginSocial";
import { useCookies } from "react-cookie";

const ModalLoginContent = ({ onDismiss }: { onDismiss: (data?: string | null | undefined | number, role?: string) => void; token: Tokens }) => {
   const [cookies] = useCookies(["userProfile"]);

   useEffect(() => {
      if (cookies.userProfile) {
         onDismiss(null, "cancel");
      }
   }, [cookies.userProfile]);

   return (
      <IonContent>
         <div className="header-login">
            <IonText className="header-log header-login-text text-balance">Connect with your favorite social media</IonText>
            <div className="close-container">
               <IonText className="close-text" onClick={() => onDismiss(null, "cancel")}>
                  X
               </IonText>
            </div>
         </div>
         <div className="modal-login-container">
            <LoginSocial></LoginSocial>
         </div>
      </IonContent>
   );
};

function ModalLogin() {
   const [present, dismiss] = useIonModal(ModalLoginContent, {
      onDismiss: (data: string, role: string) => dismiss(data, role),
   });

   const [, setMessage] = useState("This modal example uses the modalController to present and dismiss modals.");

   function openModal() {
      present({
         onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            if (ev.detail.role === "confirm") {
               setMessage(`Hello, ${ev.detail.data}!`);
            }
         },
         cssClass: "ion-modal-login",
      });
   }

   return (
      <IonButton className="log-btn bg-input" expand="block" onClick={() => openModal()}>
         <span className="button-text-sign">login</span>
      </IonButton>
   );
}

export default ModalLogin;
