import { Blockchains } from "./hooks";

export const DEFAULT_LIST_BLOCKCHAINS: Blockchains[] = [
   {
      name: "BNB",
      symbol: "BNB",
   },
   {
      name: "ETH",
      symbol: "ETH",
   },
];
