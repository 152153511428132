import { IonButton } from "@ionic/react";
import React, { useState } from "react";
import RecoveryStep1 from "./Steps/RecoveryStep1/RecoveryStep1";
import RecoveryStep2 from "./Steps/RecoveryStep2/RecoveryStep2";
import RecoveryStep3 from "./Steps/RecoveryStep3/RecoveryStep3";

interface RecoveryPhaseProps {
   twoFaStatus: boolean;
}

const RecoveryPhase: React.FC<RecoveryPhaseProps> = ({ twoFaStatus }) => {
   const [step, setStep] = useState(1);
   const [seed, setSeed] = useState("");

   const beginStep = () => {
      setStep(1);
   };

   const nextStep = () => {
      setStep(2);
   };

   const endStep = () => {
      setStep(3);
   };

   if (step === 1) {
      return <RecoveryStep1 twoFaStatus={twoFaStatus} setSeed={setSeed} nextStep={nextStep} />;
   }

   if (step === 2) {
      return <RecoveryStep2 seed={seed} endStep={endStep} />;
   }

   return <RecoveryStep3 beginStep={beginStep} />;
};

export default RecoveryPhase;
