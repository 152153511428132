import { create } from "zustand";

interface ClaimState {
    claimExplorer: string;
    claimAmount: number;
    claimTokenSymbol: string;
    setClaimExplorer: (handle: string) => void;
    setClaimAmount: (amount: number) => void;
    setClaimTokenSymbol: (chain: string) => void;
    reset: () => void;
  }

  const useClaimStore = create<ClaimState>((set) => ({
    claimExplorer: '',
    claimAmount: 0,
    claimTokenSymbol: '',
    setClaimExplorer: (explorer) => set({ claimExplorer: explorer }),
    setClaimAmount: (amount) => set({ claimAmount: amount }),
    setClaimTokenSymbol: (tokenSymbol) => set({ claimTokenSymbol: tokenSymbol }),
    reset: () => set({ claimExplorer: '', claimAmount: 0, claimTokenSymbol: '' }),
  }));
  
  export default useClaimStore;