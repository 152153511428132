import { IonButton, IonContent, IonHeader, IonModal, IonPage } from "@ionic/react";

import "./Claim.css";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import Trending from "../../components/Trending/Trending";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ClaimProcess from "../../components/Claim/ClaimProcess/ClaimProcess";
import { useCookies } from "react-cookie";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";

const Claim: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);
   const [, setClaimKeyCookie] = useCookies(["claimKey"]);
   const [showAlert, setShowAlert] = useState<boolean>(false);

   const [value, setValue] = useState<number>(0);
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const claimKey = searchParams.get("key");

   useEffect(() => {
      if (location.pathname === "/claim") {
         setClaimKeyCookie("claimKey", claimKey, { path: "/", maxAge: 600 });
         setValue(Math.random());
      }
   }, [location.pathname, claimKey, setClaimKeyCookie]);

   useEffect(() => {
      if (!cookies.userProfile) {
         setShowAlert(true);
      }
   }, [cookies.userProfile]);

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            <IonModal isOpen={showAlert} className="claim-modal">
               <div className="modal-wrapper">
                  <h4 className="modal-title">Claim Your Token</h4>
                  <p className="modal-message">Please sign up first to claim your token</p>
                  <IonButton onClick={() => setShowAlert(false)} className="modal-button" expand="block">
                     OK
                  </IonButton>
               </div>
            </IonModal>
            {cookies.userProfile ? (
               <>
                  <ClaimProcess key={value} claimKey={claimKey}></ClaimProcess>
               </>
            ) : (
               <>
                  <HomeLogin></HomeLogin>
               </>
            )}
         </IonContent>
      </IonPage>
   );
};

export default Claim;
