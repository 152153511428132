import { IonInput, IonButton, IonIcon, IonAvatar, IonCol, IonGrid, IonRow, IonToast, IonImg, IonAlert, IonLabel, IonCard, IonCardContent } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import "./SendStep1.css";
import { Contact } from "../../../entities/contact.entity";
import { useEffect, useState } from "react";
import useSendStore from "../../../store/SendStore";
import SocialSelectMini from "./SocialSelectMini/SocialSelectMini";
import { contactUserService } from "../../../services/contact/contactUserService";
import { ApiErrorHandler } from "../../../utils/ApiErrorHandler";
import { useLocation } from "react-router";
import { getLocalStorageProviderSocial } from "../../../services/auth/authService";
import { SocialMedia } from "../../../enums/social-media.enum";
import { Socials } from "../../../entities/socials.entity";
import { SOCIAL_NAMES } from "../../../utils/Constants";
import SocialContactSelectMini from "./SocialContactSelectMini/SocialContactSelectMini";
import { useQuery } from "@tanstack/react-query";
import { Tooltip } from "react-tooltip";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

interface Step1Props {
   nextStep: () => void;
}

const SendStep1: React.FC<Step1Props> = ({ nextStep }) => {
   const [resultsInit, setResultsInit] = useState<Contact[]>([]);
   const [results, setResults] = useState<Contact[]>([]);
   const [showToast, setShowToast] = useState(false);
   const [showToastMessage, setShowToastMessage] = useState("");
   const [handleValue, setHandleValue] = useState("");
   const [handleSocialValue, setHandleSocialValue] = useState("");
   const [handleAddressValue, setHandleAddressValue] = useState("");
   const [placeholderValue, setPlaceholderValue] = useState("");
   const [socialSelectValue, setSocialSelectValue] = useState<Socials>();
   const [socialContactSelectValue, setSocialContactSelectValue] = useState<Socials>();
   const [tabIndex, setTabIndex] = useState(0);
   const location = useLocation();

   const { reset, setContactHandle, setContactSocial } = useSendStore();

   // filter by social select

   const { data: contactsList } = useQuery<Contact[]>({
      queryKey: [results, socialContactSelectValue],
      queryFn: async () => {
         const filteredResults = results.filter((item) => item.social_network?.toLowerCase().includes(socialContactSelectValue.name?.toLowerCase()));
         return filteredResults;
      },
      enabled: results.length > 0 && !!socialContactSelectValue,
   });

   /**
    * To reload the componant
    */
   useEffect(() => {
      if (location.pathname === "/send") {
         init();
         initSocialSelectValue(getLocalStorageProviderSocial());
         initPlaceholderValue(getLocalStorageProviderSocial());
      }
   }, [location.pathname]);

   useEffect(() => {
      if (handleSocialValue) {
         initPlaceholderValue(handleSocialValue);
      }
   }, [handleSocialValue]);

   const handleChainSelect = (socialProvider: string) => {
      initPlaceholderValue(socialProvider);
      initSocialSelectValue(socialProvider);
      setHandleSocialValue(socialProvider);
   };

   const handleContactSocialSelect = (socialProvider: Socials) => {
      setSocialContactSelectValue(socialProvider);
   };

   const init = (): void => {
      reset();
      setHandleValue("");
      getContacts();
   };

   // init value select social left
   const initSocialSelectValue = (socialProvider: string): void => {
      const val = SOCIAL_NAMES.find((item) => item.name?.toLowerCase().includes(socialProvider?.toLowerCase())) || SOCIAL_NAMES[0];
      setSocialSelectValue(val);
      setHandleSocialValue(val.name);
   };

   // init placeholder handle
   const initPlaceholderValue = (socialProvider: string): void => {
      setPlaceholderValue(socialProvider?.toLocaleUpperCase() === SocialMedia[SocialMedia.GOOGLE]?.toString() ? "  Enter a google email" : "  e.g: @SocialPal");
   };

   // Init contact
   const getContacts = (): void => {
      const getUserContacts = async () => {
         const response = await contactUserService.getContacts();

         const status = await ApiErrorHandler.handleResponseError(response);

         if (status.statusCode === 200) {
            const responseJson = await response.json();

            let contacts = responseJson.message.contacts;

            // reset isSelected false per default
            contacts = contacts.map((item: Contact) => ({
               ...item,
               isSelected: false,
            }));

            setResults(contacts);
            setResultsInit(contacts);
         }
      };

      getUserContacts();
   };

   // filter by input query
   const handleSearch = async (event: any) => {
      const newQuery = event.target.value;
      const filteredResults = resultsInit.filter((item) => item.username?.toLowerCase().includes(newQuery?.toLowerCase()));
      setResults(filteredResults);
   };

   const handleInputChange = async (event: any) => {
      setHandleValue(event.target.value);
   };

   const handleInputAddressChange = async (event: any) => {
      setHandleAddressValue(event.target.value);
   };

   const handleAddContact = () => {
      const addContact = async () => {
         const response = await contactUserService.addContact({
            user: handleValue,
            social: handleSocialValue,
         });

         const { errorMessage } = await ApiErrorHandler.handleResponseError(response);

         if (errorMessage && errorMessage === "Invalid User") {
            setShowToast(true);
            setShowToastMessage("To add a contact, please ensure that they have signed up to SocialPal.");
         } else if (errorMessage) {
            setShowToast(true);
            setShowToastMessage(errorMessage);
         }

         init();
      };
      addContact();
   };

   const handleRemoveContact = (username: string, socialNetwork: string) => {
      const removeContact = async () => {
         await contactUserService.delContact({
            user: username,
            social: socialNetwork,
         });

         init();
      };
      removeContact();
   };

   const handleCardClick = (id: number) => {
      setResults((prevCards) =>
         prevCards.map((card) => {
            if (card.id !== id) {
               card.isSelected = false;
            }
            return card.id === id ? { ...card, isSelected: !card.isSelected } : card;
         }),
      );
   };

   // By handle user
   const handleNextStepNewUser = () => {
      const nbSelected = handleValue.trim().length > 0 ? 1 : 0;
      // if no contact selected
      if (nbSelected !== 1) {
         setShowToast(true);
         setShowToastMessage("Please enter a recipient username to continue");
      } else {
         const contactHandle = handleValue.trim();
         const contactSocial = handleSocialValue?.toLocaleLowerCase();
         setContactHandle(contactHandle);
         setContactSocial(contactSocial);
         nextStep();
      }
   };

   // By address blockchain
   const handleNextStepAddressBlockchain = () => {
      const nbSelected = handleAddressValue.trim().length > 0 ? 1 : 0;

      // if no contact selected
      if (nbSelected !== 1) {
         setShowToast(true);
         setShowToastMessage("Please enter a blockchain address to continue");
      } else {
         const contactHandle = handleAddressValue.trim();
         const contactSocial = "";
         setContactHandle(contactHandle);
         setContactSocial(contactSocial);
         nextStep();
      }
   };

   // By existing user
   const handleNextStepAddressExistingUser = () => {
      const selected = results.filter((item) => item.isSelected);
      const nbSelected = selected.length;

      // if no contact selected
      if (nbSelected !== 1) {
         setShowToast(true);
         setShowToastMessage("Please choose one contact to continue");
      } else {
         const contactHandle = selected[0].username;
         const contactSocial = selected[0]?.social_network?.toLocaleLowerCase();
         setContactHandle(contactHandle);
         setContactSocial(contactSocial);
         nextStep();
      }
   };

   return (
      <IonCard className="send1-process-card">
         <IonCardContent>
            <div className="page-title">SEND TOKENS WITH</div>
            <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={showToastMessage} duration={10000} color="danger" />

            <IonCard className="send-flex-container">
               <div className="send-flex-container-item">

                  {/* LEFT PART */}

                  <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="send1-tabs">
                     <TabList>
                        <Tab>
                           <IonLabel className="send1-tabs-label">Social media username</IonLabel>
                        </Tab>
                        <Tab>
                           <IonLabel className="send1-tabs-label">Blockchain address</IonLabel>
                        </Tab>
                        <Tab>
                           <IonLabel className="send1-tabs-label">Contacts</IonLabel>
                        </Tab>
                     </TabList>
                     <TabPanel>
                        <div className="send1-container-card">
                           <div>
                              <div className="send1-method-text">Select the social media platform and enter the recipients username below.</div>
                              <br />
                              <div className="send-content-input-handle">
                                 <div className="send-content-input-handle-select">
                                    <div className="send-content-inputs">
                                       <SocialSelectMini onChainChange={handleChainSelect} socialVal={socialSelectValue}></SocialSelectMini>
                                       <IonInput
                                          className="send-content-input-contact"
                                          placeholder={placeholderValue}
                                          id="handle"
                                          name="handle"
                                          onIonInput={handleInputChange}
                                          value={handleValue}
                                       ></IonInput>
                                    </div>
                                    <div className="send-add-to-contact" onClick={handleAddContact}>
                                       <a data-tooltip-id="tooltip-add" data-tooltip-content="Add contact" data-tooltip-place="top">
                                          <IonImg className="send-content-icon-add-contact" src="/assets/icon/add-contact.png" alt="add contact"></IonImg>
                                       </a>
                                       <span>Add to contact</span>

                                       <Tooltip id="tooltip-add" />
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="send-content-next-button">
                              <IonButton className="send-content-add-contact-btn" onClick={handleNextStepNewUser}>
                                 <span className="button-add-contact">Next</span>
                              </IonButton>
                           </div>
                        </div>
                     </TabPanel>
                     <TabPanel>
                        {/* Blockchain address */}
                        <div className="send1-container-card">
                           <div>
                              <label htmlFor="blockchainAddress" className="send1-method-text">
                                 Enter a blockchain address.
                              </label>
                              <br />
                              <IonInput
                                 className="send-content-input-blockchain-address"
                                 placeholder="  e.g: 0xcA74..."
                                 id="blockchainAddress"
                                 name="blockchainAddress"
                                 onIonInput={handleInputAddressChange}
                              ></IonInput>
                           </div>
                           <div className="send-content-next-button">
                              <IonButton className="send-content-add-contact-btn" onClick={handleNextStepAddressBlockchain}>
                                 <span className="button-add-contact">Next</span>
                              </IonButton>
                           </div>{" "}
                        </div>
                     </TabPanel>
                     <TabPanel>
                        {/* Existing contacts */}

                        <div className="send1-container-card">
                           <div>
                              <div className="send1-method-text">Select the social media platform and enter the contact username</div>
                              <br />
                              <div className="send1-container-social-contact">
                                 <SocialContactSelectMini onContactSocialChange={handleContactSocialSelect} contactSocialVal={socialContactSelectValue}></SocialContactSelectMini>
                                 <IonInput className="send-content-custom-input-search" placeholder="Search contacts" onIonInput={handleSearch} id="contact" name="contact">
                                    <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="send-content-icon-search"></IonIcon>
                                 </IonInput>
                              </div>

                              <div className="send1-container-card-contacts">
                                 {contactsList &&
                                    contactsList.map((contact: Contact, index: number) => (
                                       <div className="send1-contact-container" key={`contact-box-container_` + index}>
                                          <div
                                             key={`contact-box-div_` + index}
                                             className={`contact-box-container ${contact.isSelected ? "selected" : ""}`}
                                             onClick={() => handleCardClick(contact.id)}
                                          >
                                             <IonGrid>
                                                <IonRow key={`contact-box_` + index} className="ion-justify-content-start">
                                                   <IonCol>
                                                      <div>
                                                         <div className="contact-container-box-title">
                                                            <div>
                                                               <IonAvatar className="contact-box-social-avatar">
                                                                  <img
                                                                     src={`assets/socials/` + contact.social_network?.toLowerCase() + `_tb40.png`}
                                                                     alt={contact.social_network?.toLowerCase()}
                                                                  />
                                                               </IonAvatar>
                                                            </div>
                                                            <div>{contact.username}</div>
                                                         </div>
                                                         <span className="checkmark">&#10003;</span>
                                                      </div>
                                                   </IonCol>
                                                </IonRow>
                                             </IonGrid>
                                          </div>

                                          <IonRow key={`contact-box-del_` + index} className="send1-delete-btn">
                                             <IonButton className="send-content-icon-del-contact" id={`present-alert_` + index}>
                                                <img src="/assets/icon/remove-contact.png" alt="delete" />
                                             </IonButton>
                                             <IonAlert
                                                header="Please, confirm"
                                                trigger={`present-alert_` + index}
                                                message={`Do you want to remove the contact ` + contact.username + ` ?`}
                                                buttons={[
                                                   {
                                                      text: "Cancel",
                                                      role: "cancel",
                                                      handler: () => {
                                                         console.log("Alert canceled");
                                                      },
                                                   },
                                                   {
                                                      text: "OK",
                                                      role: "confirm",
                                                      handler: () => {
                                                         console.log("Alert confirmed");
                                                         handleRemoveContact(contact.username, contact.social_network?.toLowerCase());
                                                      },
                                                   },
                                                ]}
                                                onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
                                             ></IonAlert>
                                          </IonRow>
                                       </div>
                                    ))}
                              </div>
                           </div>
                           <div className="send-content-next-icon">
                              <IonButton className="send-content-add-contact-btn" onClick={handleNextStepAddressExistingUser}>
                                 <span className="button-add-contact">Next</span>
                              </IonButton>
                           </div>
                        </div>
                     </TabPanel>
                  </Tabs>
               </div>
            </IonCard>
         </IonCardContent>
      </IonCard>
   );
};

export default SendStep1;
