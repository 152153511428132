import "./ValidateTelegram.css";
import { useEffect } from "react";
import CryptoES from "crypto-es";

const ValidateTelegram: React.FC = () => {
   useEffect(() => {
      // Retrieve URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      console.log("==== urlParams ====", urlParams);
      // Retrieve the value of each parameter
      const id = urlParams.get("id");
      const firstName = urlParams.get("first_name");
      const username = urlParams.get("username");
      const photoUrl = urlParams.get("photo_url");
      const authDate = urlParams.get("auth_date");
      const hash = urlParams.get("hash");

      // Process with parameters
      console.log("ID:", id);
      console.log("First Name:", firstName);
      console.log("Username:", username);
      console.log("Photo URL:", photoUrl);
      console.log("Auth Date:", authDate);
      console.log("Hash:", hash);

      const dataToCheck: string[] = [];
      urlParams.forEach((val, key) => key !== "hash" && dataToCheck.push(`${key}=${val}`));

      dataToCheck.sort();

      const token_bot = "6994633891:AAG7WYK6V97rIAODBtCEoSYBBAQsMiAmX6w";
      const sha256 = CryptoES.algo.SHA256.create();
      const secret = sha256.update(token_bot).finalize();

      const hmac = CryptoES.algo.HMAC.create(CryptoES.algo.SHA256, secret).update(dataToCheck.join("\n")).finalize().toString();

      console.log("==== hash ====", hash);
      console.log("==== hmac ====", hmac);

      if (hash === hmac) {
         console.log("==== win ====");
         alert("===== authentification OK =====");
      }
   });

   return <h2>Validate Telegram</h2>;
};

export default ValidateTelegram;
