import { Tokens } from "./hooks";

export const DEFAULT_LIST_TOKENS: Tokens[] = [
   {
      name: "BNB",
      symbol: "BNB",
      chain: "BEP20",
      price: 309.123,
      volume: "2.23 B",
      holding: 1.03,
   },
   {
      name: "ETH",
      symbol: "ETH",
      chain: "BEP20",
      price: 1543.75,
      volume: "700 M",
      holding: 0.2,
   },
   {
      name: "SOL",
      symbol: "SOL",
      chain: "BEP20",
      volume: "214 M",
      price: 20.1345,
      holding: 0,
   },
   {
      name: "TRON",
      symbol: "TRON",
      chain: "BEP20",
      volume: "134 B",
      price: 1350.0,
      holding: 0,
   },
   {
      name: "wDOGE",
      symbol: "wDOGE",
      chain: "BEP20",
      volume: "4 B",
      price: 326.0,
      holding: 0,
   },
   {
      name: "wUNI",
      symbol: "wUNI",
      chain: "BEP20",
      volume: "234 M",
      price: 29.0,
      holding: 0,
   },
   {
      name: "USDT",
      symbol: "USDT",
      chain: "BEP20",
      volume: "212 M",
      price: 34.0,
      holding: 0,
   },
   {
      name: "USDC",
      symbol: "USDC",
      chain: "BEP20",
      volume: "195 M",
      price: 48.0,
      holding: 0,
   },
   {
      name: "wATOM",
      symbol: "wATOM",
      chain: "BEP20",
      volume: "183 M",
      price: 75.0,
      holding: 0,
   },
   {
      name: "wLTC",
      symbol: "wLTC",
      chain: "BEP20",
      volume: "154 M",
      price: 32.0,
      holding: 0,
   },
   {
      name: "FLOK",
      symbol: "FLOK",
      chain: "BEP20",
      volume: "142 M",
      price: 65.32,
      holding: 0,
   },
   {
      name: "wDOT",
      symbol: "wDOT",
      chain: "BEP20",
      volume: "134 M",
      price: 32.43,
      holding: 0,
   },
   {
      name: "CAKE",
      symbol: "CAKE",
      chain: "BEP20",
      volume: "121 M",
      price: 23.74,
      holding: 0,
   },
];
