import { IonSpinner, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import TelegramLoginButton, { TelegramUser } from "telegram-login-button";

import { setAuthData, setProcessData, setProviderSocial } from "../../../services/auth/authService";
import { useHistory } from "react-router";
import { API_URLS } from "../../../config/api/endpoint";
import { SocialMedia } from "../../../enums/social-media.enum";
import { useCookies } from 'react-cookie';


const TelegramWidget: React.FC = () => {
   const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

   const [, setCookie] = useCookies(['userProfile']);
   const [claimKeyCookie, removeClaimKeyCookie] = useCookies(['claimKey']);
   const [showToast, setShowToast] = useState(false);
   const history = useHistory();

   useEffect(() => {
      const iframes = document.querySelectorAll("iframe");

      iframes.forEach((objet) => {
         if (objet.id.startsWith("telegram")) {
            const idRandom = "telegram-login-" + Math.random().toString(36).substring(2, 4);
            // Modify ID iframe
            objet.id = idRandom;
         }
      });
   }, []);

   return (
      <>
         {isSpinnerVisible && (
            <div className="ion-text-center">
               <IonSpinner></IonSpinner>
            </div>
         )}
         <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Authentification failed, please retry."
            duration={3000}
            color="danger"
         />
         <TelegramLoginButton
            botName={process.env.REACT_APP_TELEGRAM_BOT_NAME || ""}
            requestAccess
            dataOnauth={(user: TelegramUser) => {
               setIsSpinnerVisible(true);
               if (user && user.hash) {
                  try {

                     var authData = JSON.stringify({ auth: user });
                     setAuthData(authData);

                     var rr = fetch(`${API_URLS.TELEGRAM_PROCESS}`, {
                        headers: {
                           Accept: "application/json",
                           "Content-Type": "application/json",
                           "ct-token": process.env.REACT_APP_CT_TOKEN || "",
                           "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
                        },
                        method: "POST",
                        body: authData,
                     });
                     rr.then(async (response) => {
                        const responseJson = await response.json();

                        if (responseJson.status) {
                           try {
                              setProcessData(JSON.stringify(responseJson));
                              setCookie('userProfile', JSON.stringify(responseJson.message), { path: '/', maxAge: 86400 });
                              setProviderSocial(SocialMedia.TELEGRAM);

                              setIsSpinnerVisible(false);
                              const claimKey = claimKeyCookie.claimKey;
                              // redirect to home
                              if (!claimKey) {
                                 history.push(`/home`);
                              } else {
                                 // from claim redirect claim
                                 removeClaimKeyCookie('claimKey', { path: '/' });
                                 history.push(`/claim?key=${claimKey}`);
                              }
                           } catch (e) {
                              console.error("Error when parsing JSON:", e);
                              setIsSpinnerVisible(false);
                           }
                        } else {
                           setShowToast(true);
                           setIsSpinnerVisible(false);
                        }
                     });
                  } catch (err) {
                     console.error(err);
                     setIsSpinnerVisible(false);
                  }
               }
            }}
            buttonSize="large"
            usePic
         />
      </>
   );
};

export default TelegramWidget;
