import React from "react";
import "./SearchToken.css"; // External CSS for styling
import { CHAIN_NETWORKS } from "../../../utils/Constants";
import { truncate } from "../../../utils/address";
import Loader from "../../Loader/Loader";
import { useCookies } from "react-cookie";

type SearchTokenListProps = {
   tokens: Token[];
   isLoading: boolean;
   width: number;
};
type Token = {
   address: string;
   name: string;
   symbol: string;
   chain: number;
   priceUsd: number;
   priceChange: number;
   volume: number;
};

const SearchTokenList = ({ tokens, isLoading, width }: SearchTokenListProps) => {
   const [cookiesChainProfile] = useCookies(["userChainProfile"]);
   const coin = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].coin;

   const listStyle = {
      width: `${width}px`,
   };

   if (isLoading) {
      return (
         <div style={listStyle} className="token-list">
            <Loader />
         </div>
      );
   }

   if (tokens.length === 0) {
      return (
         <div style={listStyle} className="token-list">
            No tokens found
         </div>
      );
   }

   const createLink = (address: string ) => {
      return `/swap?chain=${CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainId}&address=${address}`;
   };

   return (
      <>
         <div style={listStyle} className="token-list">
            {tokens.map((token, index) => (
               <a className="search-token-list-href" href={createLink(token.address)}>
               <div className="token-item" key={index}>
                  <div className="token-icon">
                     {/* Placeholder for token icon */}
                     <img className="select-image" src={`assets/blockchains/${coin}.png`} alt={coin} />
                  </div>
                  <div className="token-info">
                     <div className="token-name">
                        {token.name} <span className="token-symbol">{token.symbol}</span>
                     </div>
                     <div className="token-address">{truncate(token.address, 6, 4)}</div>
                  </div>
                  {/* <div className="token-view">
                  <a href="#">View</a>
                  </div> */}
               </div>
               </a>
            ))}
         </div>
      </>
   );
};

export default SearchTokenList;
