import "./RecoveryUser.css";
import { IonButton, IonCardContent, IonCard, IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import SocialSelectMini from "./SocialSelectMini/SocialSelectMini";
import { Socials } from "../../entities/socials.entity";
import { SOCIAL_NAMES } from "../../utils/Constants";
import { useLocation } from "react-router";
import { userApiService } from "../../services/user/userService";
import { setProcessData, setProviderSocial } from "../../services/auth/authService";
import { getEnumByKey, SocialMedia } from "../../enums/social-media.enum";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";

const RecoveryUser: React.FC = () => {
   const [oldSocial, setOldSocial] = useState("");
   const [pin, setPin] = useState("");
   const [handleSocialValue, setHandleSocialValue] = useState("");
   const [socialSelectValue, setSocialSelectValue] = useState<Socials>();
   const location = useLocation();
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const history = useHistory();
   const [, setCookie] = useCookies(["userProfile"]);
   const [placeholderValue, setPlaceholderValue] = useState("");

   /**
    * To reload the componant
    */
   useEffect(() => {
      if (location.pathname === "/recovery") {
         initSocialSelectValue("telegram");
      }
   }, [location.pathname]);

   const handleOldSocial = (event: any) => {
      setOldSocial(event.target.value);
   };

   const handlePinInput = (event: any) => {
      setPin(event.target.value);
   };

   const handleValidate = async () => {
      const handleAuth2FA = async (handleSocialValue: string) => {
         const response = await userApiService.auth2FA({
            handle: oldSocial,
            social: handleSocialValue,
            pin: pin,
         });

         const responseJson = await response.json();

         if (responseJson.status) {
            try {
               setProcessData(JSON.stringify(responseJson));
               setCookie("userProfile", JSON.stringify(responseJson.message), { path: "/", maxAge: 86400 });
               setProviderSocial(getEnumByKey(SocialMedia, handleSocialValue));

               history.push(`/home`);
            } catch (e) {
               console.error("Error when parsing JSON:", e);
            }
         } else {
            setMessageToast(responseJson.message.error);
            setShowToast(true);
         }
      };
      handleAuth2FA(handleSocialValue);
   };

   const handleSocialSelect = (socialProvider: string) => {
      setHandleSocialValue(socialProvider);
      initSocialSelectValue(socialProvider);
   };

   // init value select social left
   const initSocialSelectValue = (socialProvider: string): void => {
      const val = SOCIAL_NAMES.find((item) => item.name.toLowerCase().includes(socialProvider.toLowerCase())) || SOCIAL_NAMES[0];
      setSocialSelectValue(val);
      setHandleSocialValue(val.name);

      const isGoogle = val.name === SocialMedia[SocialMedia.GOOGLE].toLocaleLowerCase();
      if (isGoogle) {
         setPlaceholderValue("@gmail.com");
      } else {
         setPlaceholderValue("@");
      }
   };

   return (
      <>
         <IonCard className="setting-process-card">
            <IonCardContent>
               <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
               <div className="page-title">Recover your account with 2FA</div>
               <div className="settings-container-card">
                  <div className="recover-user-card-content">
                     <div className="recovery-user-form-table">
                        <div className="recovery-user-form">
                           <label>Select the social media platform and input your social media username</label>
                           <div className="recovery-user-form-row">
                              <SocialSelectMini onChainChange={handleSocialSelect} socialVal={socialSelectValue}></SocialSelectMini>
                              <input type="text" onChange={handleOldSocial} placeholder={placeholderValue} className={`recovery-user-container-btn-input`} />
                           </div>
                        </div>
                        <div className="recovery-user-form">
                           <label>Enter the authentification code:</label>
                           <div className="recovery-user-form-row form-row">
                              <input type="text" onChange={handlePinInput} className="recovery-user-container-btn-input" />
                              <IonButton className="recovery-user-btn-confirm" onClick={handleValidate}>
                                 Confirm
                              </IonButton>
                           </div>
                        </div>
                     </div>

                     <br></br>
                     <br></br>
                  </div>
               </div>
            </IonCardContent>
         </IonCard>
      </>
   );
};

export default RecoveryUser;
