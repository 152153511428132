
import { API_URLS } from "../config/api/endpoint";
import { ApiErrorHandler } from "../utils/ApiErrorHandler";
import { Quote } from "../entities/quote.entity";
import authTokenService from "../services/auth/authTokenService";

type SwapQuoteProps = {
   sell_token_id: number;
   buy_token_id: number;
   chain: string;
   amount: number;
   slippage: number;
}

/**
 * 
 * {
    "chain": "bsc",
    "estimated_gas": 211132,
    "fees": 0.00017594,
    "sell_token_id": -1,
    "sell_token_name": "bsc",
    "buy_token_id": 51,
    "buy_token_name": "PepeXplode",
    "in_amount": 1,
    "out_amount": 0.09922824551243041,
    "price": 10.0777756861,
    "price_impact": "-0.4%"
}
 */

type QuoteResponse = {
   quote: Quote | null;
   error: string | null;
}

const swapQuote = async (payload: SwapQuoteProps) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.SWAP_QUOTE}`, {
         method: "POST",
         body: JSON.stringify(payload),
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });
      await ApiErrorHandler.handleResponseError(response);

      if (response.ok) {
         const data = await response.json();
         console.log('data: ', data);
         return { quote: data.message.quote, error: null } as QuoteResponse;
      } else {
         return { quote: null, error: 'Failed to fetch quote' };
      }
   } catch (err: any) {
      console.error("Error when fetch quote : ", err);
      return { quote: null, error: err.message };
   }
};

export default swapQuote;