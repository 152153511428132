import React, { FC, useEffect, useState } from "react";
import "./MenuWalletTabs.css";
import TokenUser, { useLoadingStore } from "../../../TokenUser/TokenUser";
import { useMediaQuery } from "react-responsive";
import { IonCard, IonCardContent, IonContent, IonIcon, IonInput, IonLabel, IonModal, IonToast } from "@ionic/react";
import { addOutline, removeOutline, searchOutline } from "ionicons/icons";
import useDebounce from "../../../../hooks/debounce/useDebounce";
import searchToken from "../../../../api/search-token";
import { CHAIN_NETWORKS } from "../../../../utils/Constants";
import { useCookies } from "react-cookie";
import { Balance } from "../../../../entities/user-balances.entity";
import useTokenUserStore from "../../../../store/TokenUserStore";
import authTokenService from "../../../../services/auth/authTokenService";
import { API_URLS } from "../../../../config/api/endpoint";
import { ApiErrorHandler } from "../../../../utils/ApiErrorHandler";
import Loader from "../../../Loader/Loader";
import selectToken from "../../../../api/select-token";
import { searchApiService } from "../../../../services/search/searchApiService";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

interface MenuTabsProps { }

type Token = {
   address: string;
   name: string;
   symbol: string;
   chain: number;
   priceUsd: number;
   priceChange: number;
   volume: number;
};

const MenuWalletTabs: FC<MenuTabsProps> = () => {

   const [userBalances, setUserBalances] = useState<Balance[]>([]);
   const [isBalanceLoading, setIsBalanceLoading] = useState(false);
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const [cookiesChainProfile] = useCookies(["userChainProfile"]);
   const [error, setError] = useState(false);
   const setFirstTokenUserLoaded = useLoadingStore((state) => state.setFirstTokenUserLoaded);
   const [cookies] = useCookies(["userProfile"]);
   const reloadBalance = useTokenUserStore((state) => state.reloadBalance);


   useEffect(() => {
      if (cookiesChainProfile.userChainProfile) {
         const fetchBalances = async () => {
            setIsBalanceLoading(true);
            setFirstTokenUserLoaded();
            const result = await authTokenService();

            try {
               const response = await fetch(`${API_URLS.USER_BALANCES}${cookiesChainProfile.userChainProfile.toLowerCase()}/0`, {
                  method: "GET",
                  headers: {
                     Authorization: `Bearer ${result.token}`,
                     "ct-token": process.env.REACT_APP_CT_TOKEN || "",
                     "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
                  },
               });

               await ApiErrorHandler.handleResponseError(response);

               if (response.ok) {
                  const data = await response.json();

                  if (Array.isArray(data.message.balances)) {
                     setUserBalances(data.message.balances);
                  }
               } else {
                  setShowToast(true);
               }
            } catch (err: any) {
               console.error("Error fetching data:", err.message);
            } finally {
               setIsBalanceLoading(false);
            }
         };

         fetchBalances();
      }
   }, [cookiesChainProfile.userChainProfile, cookies.userProfile, reloadBalance]);

   const [isDesktopOverlayOpen, setIsDesktopOverlayOpen] = useState(false);
   const [activeView, setActiveView] = useState("crypto");
   const [search, setSearch] = useState("");
   const [results, setResults] = useState<Token[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const debounceSearchValue = useDebounce(search, 300);
   const triggerReloadBalance = useTokenUserStore((state) => state.triggerReloadBalance);
   const [tabIndex, setTabIndex] = useState(0);

   const isDesktop = useMediaQuery({ minWidth: 992 });

   const closeManageModal = () => {
      setIsDesktopOverlayOpen(false)
      triggerReloadBalance();
   }

   const isTokenInUserBalances = (address: string) => {
      return userBalances.some((balance) => balance?.address?.toLowerCase() === address?.toLowerCase());
   };

   const renderSearchContent = () => {
      if (isLoading) {
         return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
               <Loader />{" "}
            </div>
         );
      }

      if (search.length >= 3) {
         if (error || !results?.length) {
            return (
               <div className="no-results-message">
                  <p>No results found</p>
               </div>
            );
         }
         return <div className="asset-container">{results?.map((item, i) => renderListItem(item, i, addRemoveBookmarkToken))}</div>;
      }

      return <div className="asset-container">{userBalances.map((item, i) => renderListItem(item, i, addRemoveBookmarkToken))}</div>;
   };

   const addRemoveBookmarkToken = async (item: Token | Balance) => {

      const address = item.address;
      const isInUserBalances = isTokenInUserBalances(address);

      const isBalance = (item: Token | Balance): item is Balance => {
         return 'id' in item;
      };

      const chainId = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainId;

      // add token
      if (!isInUserBalances) {
         try {
            const selectTokenRes = await selectToken(item.address, chainId);

            if (!selectTokenRes.error) {
               const tokenId = selectTokenRes.token.token_id;
               if (tokenId !== -1) {
                  // bookmarks token
                  const bookmarksToken = async () => {
                     const bookmarksRes = await searchApiService.bookmarksToken({
                        action: "add",
                        token: tokenId,
                        chain: chainId,
                     });
                     if (bookmarksRes.status) {
                        setMessageToast("Token added to the crypto list");
                     }
                     setShowToast(true);
                  };
                  bookmarksToken();
               }

            }

         } catch (error) {
            setShowToast(true);
            console.error("Error selecting token: ", error);
         }
      } else if (isBalance(item) && item.id !== -1) {
         // remove token

         // bookmarks token
         const bookmarksToken = async () => {

            const bookmarksRes = await searchApiService.bookmarksToken({
               action: "remove",
               token: item.id,
               chain: chainId,
            });
            if (bookmarksRes.status) {
               setMessageToast("Token removed to the crypto list");
            }
            setShowToast(true);
         };


         bookmarksToken();

      }

   };


   const renderListItem = (item: Token | Balance, i, parentFunc: (item: Token | Balance) => void) => {
      const coin = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].coin;
      //1 const symbol = "symbol" in item ? item.symbol : "";
      //1 const isInUserBalances = isTokenInUserBalances(symbol);

      const address = item.address;
      const symbol = "symbol" in item ? item.symbol : "";
      const isInUserBalances = isTokenInUserBalances(address);

      const handleManageAddRemoveClick = async (isInUserBalances: boolean) => {
         addRemoveBookmarkToken(item);
      };

      return (
         <div key={i} className="asset-list-item">
            <div className="asset-list-single-item-symbol">
               <img src={`assets/blockchains/${coin}.png`} alt={coin} />
               <span>{symbol}</span>
            </div>
            <button className="btn-unstyled asset-list-item-action" onClick={() => handleManageAddRemoveClick(isInUserBalances)}>
               <IonIcon icon={isInUserBalances ? removeOutline : addOutline} />
            </button>
         </div>
      );
   };

   /**
    * To reload the componant TokenUser when change navigation
    */

   useEffect(() => {
      if (!debounceSearchValue) return;
      const fetchData = async () => {
         setIsLoading(true);
         setError(false);
         try {
            const searchData = await searchToken(debounceSearchValue, CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainId);

            setResults(searchData.tokens);
         } catch (error) {
            console.error("Error fetching tokens: ", error);
            setError(true);
         } finally {
            setIsLoading(false);
         }
      };

      fetchData();
   }, [debounceSearchValue, cookiesChainProfile.userChainProfile]);

   const handleManageClick = () => {
      if (!isDesktop) {
         setActiveView("manage");
      } else {
         setIsDesktopOverlayOpen(true);
      }
      setSearch("");
      setResults([]);
   };

   const setTabIndexCustom = (index: number) => {
      if (index === 0) {
         triggerReloadBalance();
      }
      setTabIndex(index)

   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div className="wallet-assets-container">
            <div className={`${isDesktop ? "wallet-asset-header-container-top" : ""} wallet-asset-header-container`}>


               {/* TABS FOR DESKTOP */}
               {isDesktop && (
                  <div className="wallet-asset-header">
                     <span
                        className={`${activeView === "crypto" ? "text-primary" : ""} wallet-asset-header-crypto`}
                        onClick={() => {
                           setActiveView("crypto");
                           closeManageModal();
                        }}
                     >
                        Crypto
                     </span>
                     <span className="wallet-asset-header-amount">Amount</span>
                     <span className={`${activeView === "manage" ? "text-primary" : ""} wallet-asset-header-manage`} onClick={handleManageClick} style={{ cursor: "pointer" }}>
                        Manage
                     </span>
                  </div>

               )}

               {/* TABS FOR MOBILE */}
               {!isDesktop && (

                  <>

                     <IonCard className="receive-card">
                        <IonCardContent>
                           <div className="receive-container-card">
                              <div className="container-card-content-center">
                                 <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndexCustom(index)} className="wallet-mobile-tabs">
                                    <TabList>
                                       <Tab>
                                          <IonLabel className="wallet-mobile-tabs-label">CRYPTO BALANCE</IonLabel>
                                       </Tab>
                                       <Tab>
                                          <IonLabel className="wallet-mobile-tabs-label">MANAGE</IonLabel>
                                       </Tab>
                                    </TabList>

                                    <TabPanel>
                                       { isBalanceLoading && (
                                          <div className="loader-balance">
                                             <Loader />
                                          </div>
                                       )}
                                       <TokenUser userBalances={userBalances} cookiesChainProfile={cookiesChainProfile} handleManageClick={handleManageClick} />
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="wallet-search-container">
                                          <IonInput
                                             id="message"
                                             name="message"
                                             value={search}
                                             placeholder="Search Crypto"
                                             className="wallet-search-input"
                                             onIonInput={(e) => setSearch(e.detail.value || "")}
                                          >
                                             <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="icon-search" />
                                          </IonInput>
                                       </div>
                                       <>{renderSearchContent()}</>
                                    </TabPanel>
                                 </Tabs>

                              </div>
                           </div>
                        </IonCardContent>
                     </IonCard>


                  </>
               )}


 

            </div>

            { /* MANAGE FOR DESKTOP */}
            {isDesktop && (
               <>
                  { /* TABLE TOKENUSER FOR DESKTOP */}
                  { isBalanceLoading && (
                     <div className="loader-balance">
                        <Loader />
                     </div>
                  )}
                  <TokenUser userBalances={userBalances} cookiesChainProfile={cookiesChainProfile} handleManageClick={handleManageClick} />
               </>
            )}

         </div>
         { /* MODAL MANAGE FOR DESKTOP */}
         {isDesktop && isDesktopOverlayOpen && (
            <IonModal isOpen={isDesktopOverlayOpen} onDidDismiss={() => closeManageModal()} className="manage-modal">
               <IonContent>
                  {" "}
                  <div className="wallet-search-container">
                     <IonInput
                        id="message"
                        name="message"
                        value={search}
                        placeholder="Search Crypto"
                        className="wallet-search-input"
                        onIonInput={(e) => setSearch(e.detail.value || "")}
                     >
                        <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="icon-search" />
                     </IonInput>
                  </div>
                  {renderSearchContent()}
               </IonContent>
            </IonModal>
         )}
      </>
   );
};

export default MenuWalletTabs;
