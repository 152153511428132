import React, { FC, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { useCookies } from "react-cookie";
import { API_URLS } from "../../../config/api/endpoint";
import { ApiErrorHandler } from "../../../utils/ApiErrorHandler";
import { Chains } from "../../../entities/chains.entity";
import "./MobileBlockchainSelect.css";

interface MobileBlockChainSelectProps {}

const MobileBlockChainSelect: FC<MobileBlockChainSelectProps> = () => {
   const [chains, setChains] = useState<Chains | null>(null);
   const [defaultChain, setDefaultChain] = useState<number>(0);
   const selectInputRef = useRef<any>(null);
   const [cookiesChainProfile, setCookiesChainProfile] = useCookies(["userChainProfile"]);

   const customStyles = {
      container: (base: any) => ({
         ...base,

         display: "flex",

         fontSize: "14px",
      }),
      control: (base: any, state: any) => ({
         ...base,
         backgroundColor: "transparent",
         borderColor: "transparent",
         boxShadow: "transparent",

         "&:hover": {
            borderColor: "transparent",
         },
      }),
      singleValue: (base: any) => ({
         ...base,
         color: "#ffffff",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         padding: 0,
      }),
      menu: (base: any) => ({
         ...base,
         backgroundColor: "#000000",
         borderRadius: "8px",
         overflow: "hidden",
         boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
         border: "1px solid rgba(255,255,255,0.16)",
         position: "absolute",
         zIndex: 9999,
         width: "170px", // Full width for the dropdown
         left: "-140px", // Adjust this value to center the dropdown
      }),
      menuList: (base: any) => ({
         ...base,
         padding: "0",
         maxHeight: "200px",
         backgroundColor: "#000000",
      }),
      option: (base: any, state: any) => ({
         ...base,
         backgroundColor: state.isSelected ? "#000000" : state.isFocused ? "rgba(49,130,206,0.2)" : "#000000",
         color: state.isSelected ? "#ffffff" : "#e0e0e0",
         display: "flex",
         alignItems: "center",
         gap: "8px",
         cursor: "pointer",
         transition: "all 0.2s ease",
         "&:active": {
            backgroundColor: "#000000",
            color: "#ffffff",
         },
      }),
      dropdownIndicator: () => ({
         display: "none", // Hide the dropdown indicator
      }),
      indicatorSeparator: () => ({
         display: "none",
      }),
   };

   const fetchData = async () => {
      try {
         const response = await fetch(`${API_URLS.CHAINS}`, {
            method: "GET",
            headers: {
               "ct-token": process.env.REACT_APP_CT_TOKEN || "",
               "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
            },
         });

         await ApiErrorHandler.handleResponseError(response);

         if (response.ok) {
            const data = await response.json();
            setChains(data);
            const indexDefault = data.message.chains.findIndex((item) => item.name.toLowerCase() === cookiesChainProfile.userChainProfile);

            if (indexDefault === -1) {
               setDefaultChain(0);
            } else {
               setDefaultChain(indexDefault);
            }
         }
      } catch (err: any) {
         console.error("Error fetching data:", err.message);
      }
   };

   const setChainIndex = (chain: string): void => {
      const indexDefault = chains.message.chains.findIndex((item) => item.name.toLowerCase() === chain);
      setDefaultChain(indexDefault);
   };

   useEffect(() => {
      if (!cookiesChainProfile.userChainProfile) {
         setCookiesChainProfile("userChainProfile", "bsc");
      }
   }, []);

   useEffect(() => {
      fetchData();
   }, [cookiesChainProfile.userChainProfile]);

   const handleChange = (event: any) => {
      setCookiesChainProfile("userChainProfile", event.name?.toLowerCase());
      setChainIndex(event.name?.toLowerCase());
   };

   // Custom Option component to show both icon and text
   const Option = (props: any) => (
      <components.Option {...props}>
         <img className="select-image" src={props.data.chain_logo} alt={props.data.name} />
         <span className="select-label-text">{props.data.name}</span>
      </components.Option>
   );

   // Custom SingleValue component to show only the icon
   const SingleValue = ({ data }: any) => (
      <div className="mobile-select-blockchain">
         <img className="mobile-select-image" src={data.chain_logo} alt={data.name} />
      </div>
   );

   return (
      <div>
         {chains && (
            <Select
               styles={customStyles}
               components={{
                  IndicatorSeparator: () => null,
                  Option,
                  SingleValue,
               }}
               defaultValue={chains?.message.chains[0]}
               options={chains?.message.chains}
               ref={selectInputRef}
               value={chains?.message.chains[defaultChain]}
               onChange={handleChange}
               isSearchable={false}
            />
         )}
      </div>
   );
};

export default MobileBlockChainSelect;
