import { API_URLS } from "../config/api/endpoint";
import authTokenService from "../services/auth/authTokenService";
import { ApiErrorHandler } from "../utils/ApiErrorHandler";


const searchToken = async (keyword: string, chain: number,) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.SEARCH_TOKEN}`, {
         method: "POST",
         body: JSON.stringify({ keyword, chain }),
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });
      await ApiErrorHandler.handleResponseError(response);

      if (response.ok) {
         const data = await response.json();
         return { tokens: data.message.tokens, error: null };
      } else {
         return { tokens: null, error: 'Failed to fetch tokens' };
      }
   } catch (err: any) {
      console.error("Error when fetch tokens : ", err);
      return { tokens: null, error: err.message };
   }
};

export default searchToken;
