import { IonCard, IonCardContent } from "@ionic/react";
import "./SendProcess.css";
import { useEffect, useState } from "react";
import SendStep1 from "../SendStep1/SendStep1";
import SendStep2 from "../SendStep2/SendStep2";
import SendStep3 from "../SendStep3/SendStep3";
import SendStep4 from "../SendStep4/SendStep4";
import { useLocation } from "react-router";

const SendProcess: React.FC = () => {
   const [step, setStep] = useState(1);

   const location = useLocation();

   /**
    * To reload the componant
    */
   useEffect(() => {
      if (location.pathname === "/send") {
         setStep(1);
      }
   }, [location.pathname]);

   const nextStep = () => {
      setStep((prevStep) => prevStep + 1);
   };

   const prevStep = () => {
      setStep((prevStep) => prevStep - 1);
   };

   const closeBegin = () => {
      setStep(1);
   };

   if (step === 1) {
      return <SendStep1 nextStep={nextStep} />;
   }

   if (step === 2) {
      return <SendStep2 prevStep={prevStep} nextStep={nextStep} />;
   }
   if (step === 3) {
      return <SendStep3 prevStep={prevStep} nextStep={nextStep} />;
   }

   return <SendStep4 closeBegin={closeBegin} />;
};

export default SendProcess;
