import { Socials } from "../entities/socials.entity";

/**
 * List of social media available
 */
export const SOCIAL_NAMES: Socials[] = [
   {
      name: "telegram",
   },
   {
      name: "google",
   },
   {
      name: "x",
   },
];

export const CHAIN_NETWORKS = {
   "bsc": {
      chainId: 56,
      chainName: "BSC",
      coin: "BNB",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/binance/info/logo.png",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com/"],
   },
   "eth": {
      chainId: 1,
      chainName: "ETH",
      coin: "ETH",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/ethereum/info/logo.png",
      rpcUrls: ["https://mainnet.infura.io/v3/"],
      blockExplorerUrls: ["https://etherscan.io/"],
   },
   "arbitrum": {
      chainId: 42161,
      chainName: "ARBITRUM",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/arbitrum/info/logo.png",
      coin: "ARB",
      rpcUrls: ["https://arbitrum-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://arbiscan.io/"],
   },
   "polygon": {
      chainId: 137,
      chainName: "POLYGON",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/polygon/info/logo.png",
      coin: "MATIC",
      rpcUrls: ["https://polygon-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://polygonscan.com/"],
   },
   "optimism": {
      chainId: 10,
      chainName: "OPTIMISM",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/optimism/info/logo.png",
      coin: "OP",
      rpcUrls: ["https://optimism-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://optimistic.etherscan.io/"],
   },
   "base": {
      chainId: 8453,
      chainName: "BASE",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/base/info/logo.png",
      coin: "ETH",
      rpcUrls: ["https://base-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://basescan.org/"],
   },
   "zeta": {
      chainId: 7000,
      chainName: "ZETA",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/zetachain/info/logo.png",
      coin: "ZETA",
      rpcUrls: ["https://zetachain-evm.blockpi.network/v1/rpc/public"],
      blockExplorerUrls: ["https://explorer.zetachain.com/"],
   },
   "avax": {
      chainId: 43114,
      chainName: "AVAX",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/avalanchec/info/logo.png",
      coin: "AVAX",
      rpcUrls: ["https://avalanche-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://avascan.info/"],
   },
   "cro": {
      chainId: 25,
      chainName: "CRO",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/cronos/info/logo.png",
      coin: "CRO",
      rpcUrls: ["https://rpc.vvs.finance"],
      blockExplorerUrls: ["https://cronoscan.com/"],
   },
   "fantom": {
      chainId: 250,
      chainName: "FANTOM",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/fantom/info/logo.png",
      coin: "FTM",
      rpcUrls: ["https://rpc.vvs.finance"],
      blockExplorerUrls: ["https://ftmscan.com/"],
   },
   "celo": {
      chainId: 42220,
      chainName: "CELO",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/celo/info/logo.png",
      coin: "CELO",
      rpcUrls: ["https://celo-mainnet.infura.io/v3/d9f146bcd7734fec8b82bfa9d5582bda"],
      blockExplorerUrls: ["https://celoscan.io/"],
   },
   "manta": {
      chainId: 169,
      chainName: "MANTA",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/manta/info/logo.png",
      coin: "MANTA",
      rpcUrls: ["https://pacific-rpc.manta.network/http"],
      blockExplorerUrls: ["https://manta.socialscan.io/"],
   },
   "scroll": {
      chainId: 534352,
      chainName: "SCROLL",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/scroll/info/logo.png",
      coin: "SCROLL",
      rpcUrls: ["https://scroll-rpc.publicnode.com"],
      blockExplorerUrls: ["https://scrollscan.com/"],
   },
    "linea": {
      chainId: 59144,
      chainName: "LINEA",
      chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/linea/info/logo.png",
      coin: "ETH",
      rpcUrls: ["http://172.17.0.1:8548"],
      blockExplorerUrls: ["https://lineascan.build/"],
   },
   "sei": {
     chainId: 8453,
     chainName: "SEI",
     chainLogo: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/sei/info/logo.png",
     coin: "SEI",
     rpcUrls: ["https://sei-evm-rpc.publicnode.com"],
     blockExplorerUrls: ["https://www.seiscan.app/"],
  }
} as const

export const getNetworkKeyByChainId = (chainId: number): string | undefined => {
   return Object.entries(CHAIN_NETWORKS).find(
     ([_, config]) => config.chainId === chainId
   )?.[0];
 };

export const MAIN_TOKEN_ADDRESS = '0x0';