import React, { useState } from "react";
import "./TwoFactorAuthentication.css";

import Step1 from "./Steps/Step1/Step1";

import Step2 from "./Steps/Step2/Step2";

interface TwoFactorAuthenticationProps {
   twoFaStatus: boolean;
   setTwoFaStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const TwoFactorAuthentication: React.FC<TwoFactorAuthenticationProps> = ({ twoFaStatus, setTwoFaStatus }) => {
   const [step, setStep] = useState(1);

   const beginStep = () => {
      setStep(1);
   };

   const nextStep = () => {
      setStep(2);
   };

   if (step === 1) {
      return <Step1 twoFaStatus={twoFaStatus} setTwoFaStatus={setTwoFaStatus} nextStep={nextStep} />;
   }

   return <Step2 beginStep={beginStep} setTwoFaStatus={setTwoFaStatus} />;
};

export default TwoFactorAuthentication;
