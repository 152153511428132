import { IonText, IonGrid, IonRow, IonCol, IonButton, IonContent, IonInput } from "@ionic/react";
import "./ModalSendStep2.css";
import { MouseEventHandler } from "react";
import SelectToken from "../../SelectToken/SelectToken";
import { Tokens } from "../../../hooks/tokens/hooks";

interface Props {
   clickActionTransfer: (step: number) => MouseEventHandler;
   onDismiss: (data?: string | null | undefined | number, role?: string) => void;
   token: Tokens;
}

const ModalSendStep2: React.FC<Props> = ({ clickActionTransfer, onDismiss, token }) => {

   return (
      <>
         {/* BEGIN: Modal SEND STEP1 CONTENT */}
         <IonContent className="primary-text">
            <div className="header-receive">
               <IonText className="header-receive-text primary-text header">SEND</IonText>
               <div className="close-container">
                  <IonText className="close-text" onClick={() => onDismiss(null, "cancel")}>
                     X
                  </IonText>
               </div>
            </div>
            <IonGrid>
               <IonRow>
                  <IonCol className="select-token-col">
                     <SelectToken def={token}></SelectToken>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol className="primary-text ion-text-start send-size">
                     <IonText>To</IonText>
                  </IonCol>
               </IonRow>
               <IonRow>
                  <IonCol>
                     <IonInput className="input-address" placeholder="Address or handle..."></IonInput>
                  </IonCol>
               </IonRow>
            </IonGrid>

            <IonText className="primary-text send-size text-amount">Amount</IonText>
            <br></br>
            <div className="container-send-amount send-amount">
               <div className="input-amount">
                  <IonInput placeholder="0.00" className="input-padding"></IonInput>
               </div>
               <div className="container-send-amount-max">
                  <IonText className="primary-text max-text">Max</IonText>
                  <IonText className="primary-text">BNB</IonText>
               </div>
            </div>
            <br></br>
            <div className="container-send-amount send-amount">
               <IonText className="primary-text">0.00</IonText>
               <IonText className="primary-text">USD</IonText>
            </div>
            <br></br>
            <div className="container-send-network">
               <IonText className="primary-text">Network Fee </IonText>
               <IonText className="primary-text"> 0.0034 BNB</IonText>
            </div>
            <div className="container-send">
               <IonButton className="button-send-dm text-coming-soon">
                  Send via DM <br></br>(coming soon)
               </IonButton>
               <IonButton className="button-send-social">Send via Social</IonButton>
            </div>
            <IonGrid className="container-grid primary-text">
               <IonRow>
                  <IonCol className="ion-text-start">TOTAL</IonCol>
                  <IonCol className="ion-text-end">0.5 + 0.0034 =</IonCol>
               </IonRow>
               <IonRow>
                  <IonCol className="ion-text-start">
                     Avaible Balance:<br></br>
                     1,02 BNB ($304.25)
                  </IonCol>
                  <IonCol className="ion-text-end">0.5034 BNB</IonCol>
               </IonRow>
            </IonGrid>
            <div className="container-bottom">
               <IonButton className="button-send-cancel" onClick={() => onDismiss(null, "cancel")}>
                  Cancel
               </IonButton>
               <IonButton className="button-send-continue" onClick={clickActionTransfer(1)}>
                  Continue
               </IonButton>
            </div>
         </IonContent>
         {/* END: BEGIN: Modal SEND STEP2 CONTENT */}
      </>
   );
};

export default ModalSendStep2;
