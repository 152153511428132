import React, { useState } from "react";
import { userApiService } from "../../../../../../services/user/userService";
import { ApiErrorHandler } from "../../../../../../utils/ApiErrorHandler";
import { IonButton, IonToast } from "@ionic/react";

interface Step2Props {
   beginStep: () => void;
   setTwoFaStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step2: React.FC<Step2Props> = ({ beginStep, setTwoFaStatus }) => {
   const [showDeactivate, setShowDeactivate] = useState(false);

   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const [pin, setPin] = useState("");

   const handlePinInput = (event: any) => {
      setPin(event.target.value);
   };

   const handleShowDesactivate = () => {
      setShowDeactivate(!showDeactivate);
   };

   const handleDesactivate = async () => {
      const response = await userApiService.disable2FA({
         action: "disable",
         pin: pin,
      });

      const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);

      if (statusCode === 200) {
         setShowToast(true);
         setMessageToast("Deactivation successfull");
         setTwoFaStatus(false);
         beginStep();
      } else {
         setMessageToast(errorMessage);
         setShowToast(true);
      }
   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <br></br>
         <section className="setting-card-content">
            <div className="setting-center text-center">
               <p className="setting-description text-balance">The 2FA to protect and recover your account is active.</p>
               <br />
               <p className="setting-description">Click the button below if you want to deactivate it.</p>
            </div>

            <div className="setting-center">
               <div className="setting-action-deactivate">
                  <IonButton className="setting-action-deactivate-btn" onClick={handleShowDesactivate}>
                     Deactivate
                  </IonButton>
               </div>
               <br />
            </div>
            {showDeactivate && (
               <div className="setting-center">
                  <h3>Enter the authentification code</h3>
                  <div className="setting-enter-code">
                     <input type="text" onChange={handlePinInput} className="setting-container-btn-input" />
                     {/* deactivate */}
                     <IonButton className="setting-btn-confirm" onClick={handleDesactivate}>
                        <span className="setting-btn-confirm-text">Confirm</span>
                     </IonButton>
                  </div>
               </div>
            )}
         </section>
      </>
   );
};

export default Step2;
