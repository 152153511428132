import "./SwapTokenButton.css";
import { IonIcon } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { useAppContext } from "../../../context/context";
import { CHAIN_NETWORKS } from "../../../utils/Constants";
import { useCookies } from "react-cookie";

const SwapTokenButton = ({ type }: { type: "from" | "to" }) => {
   const { swapInstruction } = useAppContext();
   const [cookiesChainProfile] = useCookies(["userChainProfile"]);

   const coin = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].coin;
   const chainLogo = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainLogo;
   const chainName = CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainName;

   return (
      <button className="swap-token-btn">
         <div className="swap-container-token-btn">
            <div>
               <img className="select-image" src={chainLogo} alt={coin} />
            </div>
            <div className="swap-button-text-token">
               <b>{swapInstruction![type]?.symbol?.toUpperCase()}</b>
            </div>
            <div className="swap-button-chevron-token">
               <IonIcon icon={chevronDownOutline} size="small"></IonIcon>
            </div>
         </div>
         <div className="vertical-line"></div>
      </button>
   );
};

export default SwapTokenButton;
