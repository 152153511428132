import { API_URLS } from "../../config/api/endpoint";
import authTokenService from "../auth/authTokenService";


export interface SearchTokenParams {
  keyword: string;
  chain: string | number;
}

export interface SelectTokenParams {
  address: string;
  chain: string | number;
}

export interface BookmarksTokenParams {
  action: string;
  token: string | number;
  chain: string | number;
}

class SearchApiService {
  private readonly headers: HeadersInit = {
    'Content-Type': 'application/json',
    "ct-token": process.env.REACT_APP_CT_TOKEN || "",
    "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
  };

  /**
   * API search token
   * @param params
   * @returns 
   */
  async searchToken(params: SearchTokenParams): Promise<Response> {

    const response = await fetch(`${API_URLS.SEARCH_TOKEN}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      console.warn("no token find");
    }

    const data = await response;
    return data;

  }

  /**
   * API select token
   * @param params 
   * @returns 
   */
  async selectToken(params: SelectTokenParams): Promise<Response> {

    const response = await fetch(`${API_URLS.SELECT_TOKEN}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(params)
    });


    if (!response.ok) {
      console.warn("no token select");
    }

    const data = await response;
    return data;

  }

    /**
   * API bookmarks token
   * @param params 
   * @returns 
   */
    async bookmarksToken(params: BookmarksTokenParams): Promise<Response> {

      const result = await authTokenService();
      
      const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

      const response = await fetch(`${API_URLS.BOOKMARKS_TOKEN}`, {
        method: 'POST',
        headers: mergeHeaders,
        body: JSON.stringify(params)
      });
  
  
      if (!response.ok) {
        console.warn("no token select");
      }
  
      const data = await response;
      return data;
  
    }


}

export const searchApiService = new SearchApiService();