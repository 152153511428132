import { Auth } from "../../entities/auth-telegram-data.entity";
import { Jwt } from "../../entities/auth-process.entity";
import { SocialMedia } from "../../enums/social-media.enum";

/**
 * AUTH DATA
 */
export const LOCAL_STORAGE_TELEGRAM_AUTH_DATA_KEY = "socialpal-auth-data";

/**
 * PROCESS DATA
 */
export const LOCAL_STORAGE_TELEGRAM_PROCESS_DATA_KEY = "socialpal-process-data";

/**
 * PROVIDER SOCIAL
 */
export const LOCAL_STORAGE_PROVIDER_SOCIAL = "socialpal-provider-social";

export const getUserInfo = (): any | null => {
   const socialpalAuthData = getLocalStorageTelegramProcessData();
   if (socialpalAuthData) {
      const socialPalItems = JSON.parse(socialpalAuthData);
      if (socialPalItems.hasOwnProperty("message")) {
         return socialPalItems.message;
      }
   }
   return null;
};

export const getToken = (): Jwt | null => {
   const socialpalAuthData = getLocalStorageTelegramProcessData();
   if (socialpalAuthData) {
      const socialPalItems = JSON.parse(socialpalAuthData);
      if (socialPalItems.hasOwnProperty("message")) {
         return socialPalItems.message.jwt;
      }
   }
   return null;
};

export const setTokenAccess = (newAccess: string) => {
   const socialpalAuthData = getLocalStorageTelegramProcessData();
   if (socialpalAuthData) {
      let socialPalItems = JSON.parse(socialpalAuthData);
      if (socialPalItems.hasOwnProperty("message")) {
         socialPalItems.message.jwt.access = newAccess;
         localStorage.setItem(LOCAL_STORAGE_TELEGRAM_PROCESS_DATA_KEY, JSON.stringify(socialPalItems));
      }
   }
};

export const setTokenRefresh = (newRefresh: string) => {
   const socialpalAuthData = getLocalStorageTelegramProcessData();
   if (socialpalAuthData) {
      let socialPalItems = JSON.parse(socialpalAuthData);
      if (socialPalItems.hasOwnProperty("message")) {
         socialPalItems.message.jwt.refresh = newRefresh;
         localStorage.setItem(LOCAL_STORAGE_TELEGRAM_PROCESS_DATA_KEY, JSON.stringify(socialPalItems));
      }
   }
};

export const setAuthData = (authData: string) => {
   localStorage.setItem(LOCAL_STORAGE_TELEGRAM_AUTH_DATA_KEY, authData);
};

export const setProcessData = (processData: string) => {
   localStorage.setItem(LOCAL_STORAGE_TELEGRAM_PROCESS_DATA_KEY, processData);
};

export const getLocalStorageProviderSocial =(): string | null => {
   return localStorage.getItem(LOCAL_STORAGE_PROVIDER_SOCIAL);
}

export const setProviderSocial = (providerSocial: SocialMedia) => {
   localStorage.setItem(LOCAL_STORAGE_PROVIDER_SOCIAL, SocialMedia[providerSocial]);
}

export const getAuthData = (): Auth | null => {
   const socialpalAuthData = getLocalStorageTelegramAuthData();
   if (socialpalAuthData) {
      const socialPalItems = JSON.parse(socialpalAuthData);
      if (socialPalItems.hasOwnProperty("auth")) {
         return socialPalItems.auth;
      }
   }
   return null;
};

export const getLocalStorageTelegramProcessData = (): string | null => {
   return localStorage.getItem(LOCAL_STORAGE_TELEGRAM_PROCESS_DATA_KEY);
};

export const getLocalStorageTelegramAuthData = (): string | null => {
   return localStorage.getItem(LOCAL_STORAGE_TELEGRAM_AUTH_DATA_KEY);
};


export const clearLocalStorageTelegramProcessData = (): void => {
   localStorage.clear();
};