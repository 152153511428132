import { IonAvatar, IonButton, IonChip, IonIcon, IonMenuToggle } from "@ionic/react";
import { menuSharp, searchOutline } from "ionicons/icons";
import "./TopBarConnected.css";
import { memo, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SearchTopbar from "../SearchTopbar/SearchTopbar";
import BlockchainSelect from "../BlockchainSelect/BlockchainSelect";
import { getLocalStorageProviderSocial } from "../../../services/auth/authService";
import { useCookies } from "react-cookie";
import MobileBlockChainSelect from "../MobileBlockchainSelect/MobileBlockChainSelect";

interface TopBarConnectedProps {
   handleLogout: () => void;
   profile: any;
   provider: string;
   setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
   searchOpen: boolean;
}

const TopBarConnected: React.FC<TopBarConnectedProps> = memo(({ handleLogout, provider, profile, searchOpen, setSearchOpen }: TopBarConnectedProps) => {
   const [imageSocial, setImageSocial] = useState<string>("");
   const [cookies] = useCookies(["userProfile"]);
   const isDesktop = useMediaQuery({ minWidth: 992 });

   useEffect(() => {
      const socialMediaLocalStore = getLocalStorageProviderSocial();
      if (socialMediaLocalStore) {
         const image = socialMediaLocalStore?.toLocaleLowerCase();
         if (image) setImageSocial(image);
      }
   }, [cookies.userProfile]);

   return (
      <div className="top-bar-connected-container">
         <div className="container-search">
            {isDesktop ? (
               <SearchTopbar />
            ) : (
               <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="mobile-search-icon" onClick={() => setSearchOpen(!searchOpen)} />
            )}
         </div>

         <div className="container-topbar-right">
            {isDesktop ? (
               <BlockchainSelect />
            ) : (
               <>
                  <MobileBlockChainSelect />
                  <button className="mobile-log-btn" onClick={handleLogout}>
                     {/* <IonChip className="container-topbar-chip-social">
                     {imageSocial && (
                        <IonAvatar>
                           <img alt="social media" src={`assets/socials/` + imageSocial + `_tb40.png`} />
                        </IonAvatar>
                     )}
                  </IonChip> */}
                     <img alt="log-out" className="mobile-log-btn-icon" src="assets/log-out.png" />
                  </button>
               </>
            )}
            {isDesktop && (
               <IonButton className="log-btn bg-input" onClick={handleLogout}>
                  <IonChip className="container-topbar-chip-social">
                     {imageSocial && (
                        <IonAvatar>
                           <img alt="social media" src={`assets/socials/` + imageSocial + `_tb40.png`} />
                        </IonAvatar>
                     )}
                  </IonChip>
                  <span className="button-text-sign">Logout</span> <img alt="log-out" className="log-btn-icon" src="assets/log-out.png" />
               </IonButton>
            )}
         </div>
      </div>
   );
});

export default TopBarConnected;
