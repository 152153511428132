
import { API_URLS } from "../config/api/endpoint";
import authTokenService from "../services/auth/authTokenService";

type SwapGenerateProps = {
   sell_token_id: number;
   buy_token_id: number;
   chain: string;
   amount: number;
   slippage: number;
}

type SwapValidateProps = {
   tx_uuid: string,
   confirm: boolean
}

export const swapValidate = async (payload: SwapValidateProps) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.SWAP_VALIDATE}`, {
         method: "POST",
         body: JSON.stringify(payload),
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });

      if (!response.ok) {
         const errorText = await response.text();
         console.log('errorText: ', errorText);
         console.error("HTTP error: ", `HTTP Error: ${response.status} - ${response.statusText}`);
         return { swap: null, error: "Failed to Validate Swap." };
      }

      const data = await response.json();
      return { swap: data.message, error: null };
   } catch (err: any) {
      return { swap: null, error: err.message };
   }
};

export const swapGenerate = async (payload: SwapGenerateProps) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.SWAP_GENERATE}`, {
         method: "POST",
         body: JSON.stringify(payload),
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });

      // Handle HTTP response errors
      if (!response.ok) {
         const errorText = await response.text();
         console.error("HTTP error: ", errorText);
         return { swap: null, error: "Failed to Generate Swap." };
      }

      const data = await response.json();
      return { swap: data.message, error: null };
   } catch (err: any) {
      console.error("Network or unexpected error: ", err);
      return { swap: null, error: err.message };
   }
}
