import { IonCard, IonCardContent, IonText, IonImg } from "@ionic/react";
import LoginGoogle from "../LoginGoogle/LoginGoogle";
import LoginX from "../LoginX/LoginX";
import TelegramWidget from "../TelegramWidget/TelegramWidget";
import "./LoginSocial.css";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getLocalStorageProviderSocial } from "../../../services/auth/authService";
import { SocialMedia } from "../../../enums/social-media.enum";
import SelectionProviderIcon from "../../../assets/SelectionProviderIcon";

const LoginSocial: React.FC = () => {
   const [providerSocial, setProviderSocial] = useState<string>("");
   const [cookies] = useCookies(["userProfile"]);

   useEffect(() => {
      const socialMediaLocalStore = getLocalStorageProviderSocial();

      if (socialMediaLocalStore) {
         const provider = socialMediaLocalStore?.toLocaleLowerCase();
         setProviderSocial(provider);
      }
   }, [cookies.userProfile]);

   // Login Google
   const [isLoginGoogleVisible, setIsLoginGoogleVisible] = useState(false);
   const handleLoginGoogleClick = () => {
      if (setIsLoginTelegramVisible) setIsLoginTelegramVisible(false);
      setIsLoginXVisible(false);
      setIsLoginGoogleVisible(!isLoginGoogleVisible);
   };

   // Login Telegram
   const [isLoginTelegramVisible, setIsLoginTelegramVisible] = useState(false);
   const handleLoginTelegramClick = () => {
      setIsLoginGoogleVisible(false);
      setIsLoginXVisible(false);
      if (setIsLoginTelegramVisible) {
         setIsLoginTelegramVisible(!isLoginTelegramVisible);
      }
   };

   // Login X
   const [isLoginXVisible, setIsLoginXVisible] = useState(false);
   const handleLoginXClick = () => {
      setIsLoginGoogleVisible(false);
      if (setIsLoginTelegramVisible) setIsLoginTelegramVisible(false);
      setIsLoginXVisible(!isLoginXVisible);
   };

   return (
      <>
         <div className="big-container">
            <IonCard className="container-card-login">
               <IonCardContent>
                  <IonText className="ion-text-center ion-color-text-gray ion-font-family">
                     <h2>Continue with</h2>
                  </IonText>
                  <br></br>

                  <div className="container-login-icon">
                     <div className="login-icon-circle login-icon-circle-pointer" onClick={handleLoginGoogleClick}>
                        <IonImg className="login-icon-google" src="/assets/icon/icon-google.png" alt="google"></IonImg>
                        {cookies.userProfile && providerSocial === SocialMedia[SocialMedia.GOOGLE].toLocaleLowerCase() && (
                           <span className="login-checkmark">
                              <SelectionProviderIcon />
                           </span>
                        )}
                     </div>
                     <div className="login-icon-circle login-icon-circle-pointer" onClick={handleLoginTelegramClick}>
                        <IonImg className="login-icon-telegram" src="/assets/icon/icon-telegram.png" alt="telegram"></IonImg>
                        {cookies.userProfile && providerSocial === SocialMedia[SocialMedia.TELEGRAM].toLocaleLowerCase() && (
                           <span className="login-checkmark">
                              <SelectionProviderIcon />
                           </span>
                        )}
                     </div>
                     <div className="login-icon-circle login-icon-circle-pointer" onClick={handleLoginXClick}>
                        <IonImg className="login-icon-x" src="/assets/icon/icon-x.png" alt="x"></IonImg>
                        {cookies.userProfile && providerSocial === SocialMedia[SocialMedia.X].toLocaleLowerCase() && (
                           <span className="login-checkmark">
                              <SelectionProviderIcon />
                           </span>
                        )}
                     </div>
                  </div>
                  <div className="container-login-button">
                     {isLoginGoogleVisible && !(providerSocial === SocialMedia[SocialMedia.GOOGLE].toLowerCase() && cookies.userProfile) && (
                        <div className={`slide-down ${isLoginGoogleVisible ? "visible" : ""}`}>
                           <LoginGoogle></LoginGoogle>
                           <br></br>
                        </div>
                     )}
                     {isLoginTelegramVisible && !(providerSocial === SocialMedia[SocialMedia.TELEGRAM].toLowerCase() && cookies.userProfile) && (
                        <div className={`slide-down ${isLoginTelegramVisible ? "visible" : ""}`}>
                           <TelegramWidget />
                           <br></br>
                        </div>
                     )}

                     {isLoginXVisible && !(providerSocial === SocialMedia[SocialMedia.X].toLowerCase() && cookies.userProfile) && (
                        <div className={`slide-down ${isLoginXVisible ? "visible" : ""}`}>
                           <LoginX></LoginX>
                           <br></br>
                        </div>
                     )}
                  </div>
                  {cookies.userProfile && <span className="container-logged-text">You are logged in with {providerSocial}</span>}

                  <div className="container-coming-soon">
                     <span className="container-logged-text coming-soon-text">Coming soon:</span>
                     <div className="container-login-icon">
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-facebook.png" alt="facebook"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-instagram.png" alt="instagram"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-whatsapp.png" alt="whatsapp"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-tiktok.png" alt="tiktok"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-youtube.png" alt="youtube"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-linkedin.png" alt="linkedin"></IonImg>
                        </div>
                        <div className="login-icon-circle-coming">
                           <IonImg className="login-icon-68 unavailable" src="/assets/icon/icon-discord.png" alt="discord"></IonImg>
                        </div>
                     </div>
                  </div>
               </IonCardContent>
            </IonCard>
            {!cookies.userProfile && (
               <a href="/recovery" className="recovery-link">
                  Recovery
               </a>
            )}
         </div>
      </>
   );
};

export default LoginSocial;
