import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Token, Token2 } from "../entities/user-token.entity";
import { Quote } from "../entities/quote.entity";


// Define types for SwapSuccessResponse
type SwapSuccessResponse = {
   hash: string;
   sold_amount: number;
   bought_amount: number;
   explorer_url: string;
};

// Define the type for the context value
type UserProfileProviderProps = {
   quote: Quote;
   showToast: boolean;
   isToogleBalance: boolean;
   swapSuccessResponse: SwapSuccessResponse;
   swapInstruction: typeof SWAP_DEFAULT_INSTRUCTION;
   setQuote: Dispatch<SetStateAction<Quote>>;
   setShowToast: (show: boolean) => void;
   setIsToogleBalance: Dispatch<SetStateAction<boolean>> | null;
   setSwapSuccessResponse: Dispatch<SetStateAction<SwapSuccessResponse>>;
   setSwapInstruction: Dispatch<SetStateAction<typeof SWAP_DEFAULT_INSTRUCTION>>;
};

// Constants and default values
export const SWAP_DEFAULT_TOKEN = {
   bsc: {
      symbol: "BNB",
      address: "0x0",
      token_id: -1,
   } as Token2,
   eth: {
      symbol: "ETH",
      address: "0x0",
      token_id: -1,
   } as Token2,
   arbitrum: {
      symbol: "ARB",
      address: "0x0",
      token_id: -1,
   } as Token2,
   polygon: {
      symbol: "MATIC",
      address: "0x0",
      token_id: -1,
   } as Token2,
   optimism: {
      symbol: "OP",
      address: "0x0",
      token_id: -1,
   } as Token2,
   base: {
      symbol: "ETH",
      address: "0x0",
      token_id: -1,
   } as Token2,
   zeta: {
      symbol: "ZETA",
      address: "0x0",
      token_id: -1,
   } as Token2,
   avax: {
      symbol: "AVAX",
      address: "0x0",
      token_id: -1,
   } as Token2,
   cro: {
      symbol: "CRO",
      address: "0x0",
      token_id: -1,
   } as Token2,
   fantom: {
      symbol: "FTM",
      address: "0x0",
      token_id: -1,
   } as Token2,
   celo: {
      symbol: "CELO",
      address: "0x0",
      token_id: -1,
   } as Token2,
   manta: {
      symbol: "ETH",
      address: "0x0",
      token_id: -1,
   } as Token2,
   scroll: {
      symbol: "ETH",
      address: "0x0",
      token_id: -1,
   } as Token2,
   linea: {
      symbol: "ETH",
      address: "0x0",
      token_id: -1,
   } as Token2 as Token2,
   sei: {
      symbol: "SEI",
      address: "0x0",
      token_id: -1,
   } as Token2,

};

export function convertToken2ToToken(token2: Token2): Token {
   return {
       symbol: token2.symbol,
       id: token2.token_id, 
       is_native: true,
       decimals: token2.decimals,
       address: token2.address,
       isSelected: token2.isSelected
   };
}

export const SWAP_DEFAULT_INSTRUCTION = {
   from: {} as Token2,
   to: {} as Token2,
   amount: "",
   slippage: "1",
   deadline: 0,
   fromSwapUrl: false,
   fromNotReload: false
};

const DEFAULT_VALUE = {
   quote: {} as Quote,
   userProfile: null,
   showToast: false,
   isToogleBalance: true,
   setIsToogleBalance: null,
   swapInstruction: SWAP_DEFAULT_INSTRUCTION,
   swapSuccessResponse: {} as SwapSuccessResponse,
   setQuote: () => {},
   setShowToast: () => {},
   setSwapInstruction: () => {},
   setSwapSuccessResponse: () => {},
} as UserProfileProviderProps;

export const UserProfileContext = React.createContext<UserProfileProviderProps>(DEFAULT_VALUE);

const UserProfileProvider = ({ children }: { children: ReactNode }) => {
   const [showToast, setShowToast] = useState(false);
   const [quote, setQuote] = useState<Quote>({} as Quote);
   const [isToogleBalance, setIsToogleBalance] = useState(true);
   const [swapInstruction, setSwapInstruction] = useState(SWAP_DEFAULT_INSTRUCTION);
   const [swapSuccessResponse, setSwapSuccessResponse] = useState<SwapSuccessResponse>({} as SwapSuccessResponse);


   /*
   const { data: publicTokens } = useQuery<Token2[]>({
      queryKey: ["publicTokens", cookiesChainProfile.userChainProfile],
      queryFn: async () => (await publicToken(cookiesChainProfile.userChainProfile)).tokens,
      enabled: !!cookiesChainProfile.userChainProfile, // Ensure the query is only triggered when chainProfile is available
   });

   const { mutateAsync: selectTokenMutate } = useMutation<SelectTokenResponse, unknown, { address: string; chainId: number }>({
      mutationFn: async ({ address, chainId }) => (await selectToken(address, chainId)).token,
   });
   */

   /*
   useEffect(() => {
      console.log('==== swap context 00001 ====');
      if (!publicTokens) return;
      console.log('==== swap context 00002 ====');
      if (swapInstruction.from?.symbol) return;
      console.log('==== swap context 00003 ====');
      const handlePublicTokens = async () => {
         try {
            console.log('==== useEffect dans context 001 ===');
            if (publicTokens && publicTokens.length > 0) {
               console.log('==== useEffect dans context 002 ===', publicTokens);
               setSwapInstruction({
                  ...swapInstruction,
                  from: { ...publicTokens[0], symbol: publicTokens[0].symbol.toUpperCase(), token_id: (publicTokens[0] as any).id },
                  to: { ...publicTokens[1], symbol: publicTokens[1].symbol.toUpperCase(), token_id: (publicTokens[1] as any).id },
               });
            } else {
               console.log('==== useEffect dans context 003 ===');
               setSwapInstruction({
                  ...SWAP_DEFAULT_INSTRUCTION,
                  from: SWAP_DEFAULT_TOKEN[chainProfile],
                  to: SWAP_DEFAULT_TOKEN[chainProfile],
               });
            }
         } catch (error) {
            console.error("Error selecting tokens:", error);
         }
      };

      handlePublicTokens();
   }, [publicTokens, chainProfile, selectTokenMutate, swapInstruction]);
*/

   return (
      <UserProfileContext.Provider
         value={{
            quote,
            showToast,
            isToogleBalance,
            swapInstruction,
            swapSuccessResponse,
            setQuote,
            setIsToogleBalance,
            setShowToast,
            setSwapInstruction,
            setSwapSuccessResponse,
         }}
      >
         {children}
      </UserProfileContext.Provider>
   );
};

export default UserProfileProvider;

export const useAppContext = () => {
   const context = React.useContext(UserProfileContext);
   if (context === undefined) {
      throw new Error("useUserProfile must be used within a UserProfileProvider");
   }
   return context;
};
