

export const numberValidator = (value: string | number, default_v = 1) => {
   if (isNaN(Number(value))) {
      return default_v;
   }
   return Number(value) || default_v;
}

export const decimalValidator = (value: string): string => {
   // remove non numeric
   const cleanedValue = value.replace(/[^\d.]/g, '');
   
   // check more that one point
   const decimalPoints = cleanedValue.split('.').length - 1;
   if (decimalPoints > 1) {
     // keep the first point
     const parts = cleanedValue.split('.');
     return parts[0] + '.' + parts.slice(1).join('');
   }
   
   // add 0 if value staart by point
   if (cleanedValue.startsWith('.')) {
     return '0' + cleanedValue;
   }
   return cleanedValue;
 };