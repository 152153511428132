import { useEffect, useState } from "react";
import "./LoginSocialMobile.css";
import { useCookies } from "react-cookie";
import { IonCard, IonImg } from "@ionic/react";
import { getLocalStorageProviderSocial, setProviderSocial } from "../../../services/auth/authService";
import SelectionProviderIcon from "../../../assets/SelectionProviderIcon";


const LoginSocialMobile: React.FC = () => {

   const [providerSocial, setProviderSocial] = useState<string>("");
   const [cookies] = useCookies(["userProfile"]);

   useEffect(() => {
      const socialMediaLocalStore = getLocalStorageProviderSocial();

      if (socialMediaLocalStore) {
         const provider = socialMediaLocalStore?.toLocaleLowerCase();
         setProviderSocial(provider);
      }
   }, [cookies.userProfile]);

   return (
      <>
            {cookies.userProfile &&
               <div className="container-logged">
                  <span className="container-logged-text">You are logged in with {providerSocial}</span>
                  <div className="login-icon-circle login-icon-circle-pointer">
                  <IonImg className="logged-mobile-padding login-icon-google" src={`/assets/icon/icon-${providerSocial.toLowerCase()}.png`} alt={providerSocial}></IonImg>
                          <span className="login-checkmark">
                              <SelectionProviderIcon />
                           </span>
                     
                     </div>
               </div>
            }


      </>
   );
};

export default LoginSocialMobile;
