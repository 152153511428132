const BASE_URL = process.env.REACT_APP_SOCIALPAL_AUTHURL;
export const API_BACK = process.env.REACT_APP_API_BACKEND;

// api wallet
export const API_URLS = {

   /**
    * Get the list of chains (public ex: bsc,eth)
    */
   CHAINS: `${BASE_URL}/public/chains`,

   /**
    * For the authentification with telegram
    */
   TELEGRAM_PROCESS: `${BASE_URL}/auth/telegram/process/`,

   /**
    * For the authentification with google
    */
   GOOGLE_PROCESS: `${BASE_URL}/auth/google/process/`,

   /**
    * For the authentification with twitter
    */
   TWITTER_PROCESS: `${BASE_URL}/auth/x/process/`,

   /**
    * To refresh the token
    * /auth/token/refresh/?token=${jwt.access}
    */
   REFRESH_TOKEN: `${BASE_URL}/auth/token/refresh/`,

   /**
    * Get the user balance by token (public or private token)
    * /user/balance/{chain}/{id-token}
    * chain => bsc/eth
    * id-token : id of the token
    */
   BASE_URL_BALANCE: `${BASE_URL}/user/balance/`,

   // TO FIX
   USER_BALANCE: `${BASE_URL}/user/balance/bsc/-1`,

   /**
    * Get the private token user by chain (ex: chain= bsc or eth)
    *  /user/tokens/{chain}
    */
   USER_TOKEN: `${BASE_URL}/user/tokens/`,

   /**
    * Get the balance by chain
    * /user/balances/{chain}
    *  
    */
   USER_BALANCES: `${BASE_URL}/user/balances/`,

   /**
    * Get user contacts
    */
   USER_CONTACTS: `${BASE_URL}/user/contacts/get`,

   /**
    * Add user contacts
    */
   USER_ADD_CONTACTS: `${BASE_URL}/user/contacts/add`,

   /**
    * Del user contacts
    */
   USER_DEL_CONTACTS: `${BASE_URL}/user/contacts/del`,

   /**
    * Get the list of public token
    */
   PUBLIC_TOKEN: `${BASE_URL}/public/tokens/`,

   /**
    * Send token to user
    */
   SEND_TOKEN: `${BASE_URL}/send/dest`,

   /**
   * Select Token
   */
   SELECT_TOKEN: `${BASE_URL}/tokens/select`,

   /**
    * Bookmarks token
    */
   BOOKMARKS_TOKEN: `${BASE_URL}/tokens/bookmarks`,

   /**
    * Claim gen
    */
   CLAIM_GEN: `${BASE_URL}/send/genclaim`,

   /**
   * Claim retrieve
   */
   CLAIM_RETRIEVE: `${BASE_URL}/send/retrieveclaim`,

   /**
    * Search token
    */
   SEARCH_TOKEN: `${BASE_URL}/tokens/search`,

   /**
    * Get the swap quote of token
    */

   SWAP_QUOTE: `${BASE_URL}/swap/quote`,

   /**
    * Swap Generate
    */
   SWAP_GENERATE: `${BASE_URL}/swap/generate`,

   /**
    * Swap Validate
    */
   SWAP_VALIDATE: `${BASE_URL}/swap/validate`,

   /**
    * ENABLE 2FA
    */
   ENABLE_2FA: `${BASE_URL}/user/2fa`,

   /**
   * VALIDATE 2FA
   */
   VALIDATE_2FA: `${BASE_URL}/user/2fa`,

   /**
   * DISABLE 2FA
   */
   DISABLE_2FA: `${BASE_URL}/user/2fa`,

   /**
   * RECOVERY 2FA
   */
   RECOVERY_2FA: `${BASE_URL}/user/seedrecovery`,

   /**
   * RECOVERY USER 2FA
   */
   RECOVERY_USER_2FA: `${BASE_URL}/auth/2fa/`,

};

// trending
export const API_TRENDING: string = process.env.REACT_APP_API_TRENDING as string;

// wallet
export const WALLET_VALIDATE_SEED = `${API_BACK}/validateseed/`;
