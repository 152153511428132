import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { useCookies } from "react-cookie";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import Trending from "../../components/Trending/Trending";
import "./Recovery.css";
import RecoveryUser from "../../components/RecoveryUser/RecoveryUser";

const Recovery: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            {cookies.userProfile ? (
               <>Not available</>
            ) : (
               <>
                  <RecoveryUser></RecoveryUser>
               </>
            )}
         </IonContent>
      </IonPage>
   );
};

export default Recovery;
