import React from "react";

const SelectionProviderIcon = () => {
   return (
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.586 2.09999C10.94 1.74646 11.4136 1.53844 11.9134 1.51697C12.4132 1.49549 12.903 1.66212 13.286 1.98399L13.414 2.10099L15.314 3.99999H18C18.5044 4.00008 18.9901 4.19076 19.3599 4.53382C19.7297 4.87689 19.9561 5.347 19.994 5.84999L20 5.99999V8.68599L21.9 10.586C22.2538 10.94 22.462 11.4139 22.4834 11.9139C22.5049 12.414 22.3381 12.9039 22.016 13.287L21.899 13.414L19.999 15.314V18C19.9991 18.5046 19.8086 18.9906 19.4655 19.3605C19.1224 19.7305 18.6521 19.9571 18.149 19.995L18 20H15.315L13.415 21.9C13.0609 22.2538 12.5871 22.462 12.087 22.4835C11.587 22.5049 11.097 22.3381 10.714 22.016L10.587 21.9L8.68697 20H5.99997C5.49539 20.0001 5.0094 19.8096 4.63942 19.4665C4.26944 19.1234 4.04281 18.6531 4.00497 18.15L3.99997 18V15.314L2.09997 13.414C1.74616 13.0599 1.53797 12.5861 1.51649 12.0861C1.49502 11.586 1.66182 11.0961 1.98397 10.713L2.09997 10.586L3.99997 8.68599V5.99999C4.00006 5.49558 4.19074 5.00984 4.53381 4.64007C4.87687 4.2703 5.34699 4.04382 5.84997 4.00599L5.99997 3.99999H8.68597L10.586 2.09999Z"
            fill="#00C80D"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0791 8.98298L10.8351 13.227L9.06707 11.459C8.87943 11.2715 8.62498 11.1662 8.35971 11.1663C8.09444 11.1664 7.84007 11.2718 7.65257 11.4595C7.46506 11.6471 7.35977 11.9016 7.35986 12.1668C7.35996 12.4321 7.46543 12.6865 7.65307 12.874L10.0571 15.278C10.1592 15.3802 10.2805 15.4613 10.414 15.5166C10.5475 15.5719 10.6906 15.6003 10.8351 15.6003C10.9796 15.6003 11.1226 15.5719 11.2561 15.5166C11.3896 15.4613 11.5109 15.3802 11.6131 15.278L16.4931 10.397C16.6752 10.2084 16.776 9.95578 16.7737 9.69358C16.7715 9.43138 16.6663 9.18057 16.4809 8.99516C16.2955 8.80976 16.0447 8.70459 15.7825 8.70231C15.5203 8.70003 15.2677 8.80082 15.0791 8.98298Z"
            fill="#00CD0E"
         />
      </svg>
   );
};

export default SelectionProviderIcon;
