import { IonContent, IonHeader, IonPage } from "@ionic/react";
import "./Home.css";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import MenuWalletTabs from "../../components/Home/HomeTabs/MenuWalletTabs/MenuWalletTabs";
import Trending from "../../components/Trending/Trending";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";
import MenuWalletTabsNotConnected from "../../components/Home/HomeTabs/MenuWalletTabsNotConnected/MenuWalletTabsNotConnected";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const Home: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);
   const [showComponent, setShowComponent] = useState(true);
   const location = useLocation();

   useEffect(() => {
      if (location.pathname === "/home") {
         setShowComponent(true);
      } else {
         setShowComponent(false);
      }
   }, [location.pathname]);


   return (
      <IonPage>
         <IonHeader className="home-header">
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent fullscreen>
            <div className="home-content-container">
               <HomeLogin></HomeLogin>
               {cookies.userProfile && showComponent ? (
                  <>
                     <MenuWalletTabs></MenuWalletTabs>
                  </>
               ) : (
                     <MenuWalletTabsNotConnected></MenuWalletTabsNotConnected>
               )}
            </div>
         </IonContent>
      </IonPage>
   );
};

export default Home;
