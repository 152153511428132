import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import SwapStep1 from "../SwapStep1/SwapStep1";
import SwapStep2 from "../SwapStep2/SwapStep2";
import SwapStep3 from "../SwapStep3/SwapStep3";
import { useState } from "react";
import { settingsOutline } from "ionicons/icons";
import "./SwapProcess.css";
import SwapStep0 from "../SwapStep0/SwapStep0";
import { useAppContext } from "../../../context/context";

const SwapProcess: React.FC = () => {
   const [step, setStep] = useState(1);
   const { setSwapInstruction } = useAppContext();

   const nextStep = () => {
      setStep((prevStep) => prevStep + 1);
   };

   const prevStep = () => {
      setSwapInstruction((prev) => ({ ...prev, fromNotReload: true }));
      if (step < 1) {
         setStep(1);
      } else {
         setStep((prevStep) => prevStep - 1);
      }
   };

   const openSetting = () => {
      setStep(0);
   };

   const closeBegin = () => {
      setStep(1);
   };

   return (
      <IonCard className="swap-process-container">
         <IonCardContent>
            <div className="page-title">Swap</div>

            <div className="swap-container">
               <div className="swap-container-card">
                  {step === 1 && (
                     <div className="swap-process-settings">
                        <div>
                           {" "}
                           <IonIcon className="swap-step2-icon-previous" icon={settingsOutline} onClick={openSetting}></IonIcon>
                        </div>
                     </div>
                  )}

                  {step === 0 && <SwapStep0 prevStep={prevStep}></SwapStep0>}

                  {step === 1 && <SwapStep1 nextStep={nextStep}></SwapStep1>}

                  {step === 2 && <SwapStep2 prevStep={closeBegin} nextStep={nextStep}></SwapStep2>}

                  {step === 3 && <SwapStep3 closeBegin={closeBegin}></SwapStep3>}
               </div>
            </div>
         </IonCardContent>
      </IonCard>
   );
};

export default SwapProcess;
