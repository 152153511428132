import "./LoginX.css";
import { TwitterLoginButton } from "react-social-login-buttons";
import { IResolveParams, TwitterLogin } from "../../SocialPal/Login/Twitter/Page";
import { useCallback } from "react";
import { useCookies } from 'react-cookie';
import IconX from "../IconX/IconX";

interface LoginXProps {}

const LoginX: React.FC<LoginXProps> = () => {
   const [, setCookie] = useCookies(['userProfile']);

   const onLoginStart = useCallback(() => {
      // alert("login start");
   }, []);

   const onLogoutSuccess = useCallback(() => {
      // alert("logout success");
   }, []);

   return (
      <div>
         <TwitterLogin
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            socialpal_authurl={process.env.REACT_APP_SOCIALPAL_AUTHURL_TWITTER || ""}
            isLogin={true}
            onResolve={({ data }: IResolveParams) => {
               setCookie('userProfile', JSON.stringify(data.message), { path: '/', maxAge: 86400 });
            }}
            onReject={(err: any) => {
               console.log(err);
            }}
         >
            <TwitterLoginButton icon={IconX} align="center" text="Sign in with X" className="button-twitter" />
         </TwitterLogin>
      </div>
   );
};

export default LoginX;
