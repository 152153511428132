import "./TokenList.css";
import { IonCol, IonGrid, IonRow, IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { Token } from "../../../../entities/user-token.entity";
import useSendStore from "../../../../store/SendStore";
import { API_URLS } from "../../../../config/api/endpoint";
import { ApiErrorHandler } from "../../../../utils/ApiErrorHandler";
import { searchApiService } from "../../../../services/search/searchApiService";
import { MAIN_TOKEN_ADDRESS } from "../../../../utils/Constants";

interface TokenListProps {
   searchValue: string | undefined;
}

const TokenList: React.FC<TokenListProps> = ({ searchValue }) => {
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const [results, setResults] = useState<Token[]>([]);

   const { contactChain, contactChainId, setContactTokenSymbol, setContactTokenId } = useSendStore();

   useEffect(() => {
      if (contactChain) {
         const fetchData = async () => {
            const response = await fetch(`${API_URLS.PUBLIC_TOKEN}${contactChain.toLowerCase()}`, {
               method: "GET",
               headers: {
                  "ct-token": process.env.REACT_APP_CT_TOKEN || "",
                  "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
               },
            });

            const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);

            if (statusCode === 200) {
               const data = await response.json();
               setResults(data.message.tokens);
            } else {
               setShowToast(true);
               setMessageToast(errorMessage);
            }
         };

         fetchData();
      }
   }, [contactChain]);

   useEffect(() => {
      // if user search a token
      if (searchValue && searchValue.trim().length > 0) {
         const fetchData = async () => {
            const response = await searchApiService.searchToken({
               keyword: searchValue,
               chain: contactChainId,
            });

            await ApiErrorHandler.handleResponseError(response);

            if (response.ok) {
               const data = await response.json();
               setResults(data.message.tokens);
            }
         };
         fetchData();
      } else if (!searchValue && contactChain) {
         const fetchData = async () => {
            // const result = await authTokenService();

            // at the init without search a token
            const response = await fetch(`${API_URLS.PUBLIC_TOKEN}${contactChain.toLowerCase()}`, {
               method: "GET",
               headers: {
                  "ct-token": process.env.REACT_APP_CT_TOKEN || "",
                  "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
               },
            });

            await ApiErrorHandler.handleResponseError(response);

            if (response.ok) {
               const data = await response.json();
               setResults(data.message.tokens);
            }
         };
         fetchData();
      }
   }, [searchValue]);

   const handleCardClick = (address: string) => {
      console.log("==== handleCardClick  address ===", address);

      let selectedSymbol = "";

      setResults((prevCards) =>
         prevCards.map((card) => {
            if (card.address === address) {
               // check the selected
               const isSelected = !card.isSelected;
               if (isSelected) {
                  selectedSymbol = card.symbol;
                  setContactTokenSymbol(selectedSymbol);
               }
               return { ...card, isSelected };
            }
            // uncheck not selected
            return { ...card, isSelected: false };
         }),
      );

      // if user select a token
      const fetchData = async () => {
         console.log("===== send step 2 selectToken ====");
         const response = await searchApiService.selectToken({
            address: address,
            chain: contactChainId,
         });

         await ApiErrorHandler.handleResponseError(response);

         if (response.ok) {
            const data = await response.json();
            setContactTokenId(data.message.token.token_id);
         }
      };

      // call select only if not main token
      if (address !== MAIN_TOKEN_ADDRESS) {
         fetchData();
      } else {
         setContactTokenId(-1);
      }
   };

   const shortenAddress = (address: string, chars = 4): string => {
      if (!address || address.length < 10) return "";
      return `${address.substring(0, 6)}...${address.substring(address.length - chars)}`;
   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div className="contact-box-container-list">
            {results &&
               (results as Token[]).map((token: any, index: number) => (
                  <div key={`contact-box-div_` + index} className={`contact-box-container ${token.isSelected ? "selected" : ""}`} onClick={() => handleCardClick(token.address)}>
                     <IonGrid>
                        <IonRow className="ion-justify-content-center">
                           <IonCol size="auto"></IonCol>

                           <IonCol size="4">
                              <div className="ion-float-left">
                                 <h3>{token.name}</h3>
                                 <span className="ion-float-left color-primary-contrast">
                                    <span className="amount-tokens color-primary-contrast">{token.symbol}</span>
                                 </span>
                              </div>
                           </IonCol>

                           <IonCol>
                              <h3>{shortenAddress(token.address)}</h3>
                              <span className="checkmark">&#10003;</span>
                           </IonCol>
                        </IonRow>
                     </IonGrid>
                  </div>
               ))}
         </div>
      </>
   );
};

export default TokenList;
