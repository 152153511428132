import { Link } from "react-router-dom";
import useClaimStore from "../../../store/ClaimStore";
import "./ClaimStep2.css";
import DuoIconsApproved from "../../../assets/DuoApprovedIcon";

const ClaimStep2: React.FC = () => {
   const { claimExplorer, claimAmount, claimTokenSymbol } = useClaimStore();

   return (
      <>
         <div className="claim-container">
            <div className="send-step4-icon">
               <div className="send-step4-icon-circle">
                  <DuoIconsApproved />
               </div>
            </div>
            <div className="claim-step1-text">Your tokens have now been claimed successfully and your balance should reflect the change.</div>
            <div className="claim-btn-container">
               <Link to="/home" className="claim-btn">
                  Back
               </Link>
            </div>
         </div>
      </>
   );
};

export default ClaimStep2;
