export enum SocialMedia {
   TELEGRAM,
   GOOGLE,
   X,
}

export function getEnumKeyByValue<T extends { [index: string]: string | number }>(enumObject: T, enumValue: T[keyof T]): keyof T | undefined {
   return Object.keys(enumObject).find((key) => enumObject[key] === enumValue) as keyof T | undefined;
}

export function getEnumByKey<T extends { [index: string]: string | number }>(enumObject: T, key: string): T[keyof T] | undefined {
   const matchingKey = Object.keys(enumObject).find(
       (enumKey) => enumKey.toLowerCase() === key.toLowerCase()
   );

   return matchingKey ? enumObject[matchingKey as keyof T] : undefined;
}

export function getEnumKeyByString<T extends { [index: string]: string | number }>(
   enumObject: T,
   enumString: string
): keyof T | undefined {
   return Object.keys(enumObject).find(
       (key) => key.toLowerCase() === enumString.toLowerCase()
   ) as keyof T | undefined;
}