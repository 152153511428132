import Select from "react-select";
import "./SelectBlockchain.css";
import { useDefaultBlockchain } from "../../hooks/blockchains/hooks";
import { useRef } from "react";
import { useIonViewWillLeave } from "@ionic/react";

const SelectBlockchain: React.FC = () => {
   const customStyles = {
      control: (base: any, state: any) => ({
         ...base,
         backgroundColor: "#101010",
         borderRadius: "8px",
         borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.16)",
         boxShadow: state.isFocused ? "0 0 0 1px #101010" : "none",
         minHeight: "40px",
         "&:hover": {
            borderColor: state.isFocused ? "#252525" : "rgba(255,255,255,0.3)",
         },
      }),
      singleValue: (base: any) => ({
         ...base,
         color: "#ffffff",
         display: "flex",
         alignItems: "center",
         gap: "8px",
      }),
      menu: (base: any) => ({
         ...base,
         backgroundColor: "#1a1a1a",
         borderRadius: "8px",
         overflow: "hidden",
         boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
         border: "1px solid rgba(255,255,255,0.16)",
         position: "absolute",
         zIndex: 9999,
      }),
      menuList: (base: any) => ({
         ...base,
         padding: "0",
         maxHeight: "200px",
         backgroundColor: "#1a1a1a",
      }),
      option: (base: any, state: any) => ({
         ...base,
         backgroundColor: state.isSelected ? "#101010" : state.isFocused ? "rgba(49,130,206,0.2)" : "#1a1a1a",
         color: state.isSelected ? "#ffffff" : "#e0e0e0",
         display: "flex",
         alignItems: "center",
         gap: "8px",
         cursor: "pointer",
         transition: "all 0.2s ease",
         "&:active": {
            backgroundColor: "#101010",
            color: "#ffffff",
         },
      }),
      dropdownIndicator: (base: any) => ({
         ...base,
         color: "rgba(255,255,255,0.6)",
         "&:hover": {
            color: "rgba(255,255,255,0.8)",
         },
      }),
      indicatorSeparator: () => ({
         display: "none",
      }),
   };
   const tokensData = useDefaultBlockchain();

   const selectInputRef = useRef<any>(null);

   useIonViewWillLeave(() => {
      selectInputRef.current.clearValue();
   });

   const handleChange = (event: any) => {};

   return (
      <div className="select-blockchain">
         <Select
            styles={customStyles}
            options={tokensData}
            ref={selectInputRef}
            onChange={handleChange}
            isClearable
            placeholder={"Blockchain"}
            formatOptionLabel={(token) => (
               <div className="container-select">
                  <img src={`/assets/tokens/${token.symbol}.png`} alt={token.name} />
                  <span className="select-label-text">{token.name}</span>
               </div>
            )}
         />
      </div>
   );
};

export default SelectBlockchain;
