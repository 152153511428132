import { IonButton } from "@ionic/react";
import React from "react";

interface RecoveryStep3Props {
   beginStep: () => void;
}

const RecoveryStep3: React.FC<RecoveryStep3Props> = ({ beginStep }) => {
   return (
      <div className="setting-card-content-recovery">
         <ul className="setting-ul">
            <li>You've successfully protected your wallet with recovery Seed Phrase.</li>
            <li> Keep the recovery Seed Phrase safe and secret, it's your responsibility!</li>
            <li>
               Remember: SocialPal can't recover your Seed Phrase. SocialPal will never ask you for your Seed Phrase. Never share your seed Phrase with anyone or risk your funds
               being stolen.
            </li>
         </ul>

         <div className="setting-center">
            <div className="setting-action-deactivate">
               <IonButton className="setting-action-deactivate-btn" onClick={beginStep}>
                  Back
               </IonButton>
            </div>
         </div>
      </div>
   );
};

export default RecoveryStep3;
