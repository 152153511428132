import "./SwapStep0.css";
import { optionsOutline } from "ionicons/icons";
import { useAppContext } from "../../../context/context";
import { decimalValidator } from "../../../utils/utils";
import { IonButton, IonIcon, IonRange, IonText, IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";

interface SwapStep0Props {
   prevStep: () => void;
}

const SwapStep0: React.FC<SwapStep0Props> = ({ prevStep }) => {
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("");
   const { swapInstruction, setSwapInstruction } = useAppContext();
   const [slippageClone, setSlippageClone] = useState("1");
   const [, setCookiesSlippageProfile] = useCookies(["userSlippageProfile"]);

   const isDesktop = useMediaQuery({ minWidth: 992 });

   const handleSlippage = (data: string) => {
      setCookiesSlippageProfile("userSlippageProfile", data!);
      setSwapInstruction((p) => ({ ...p, slippage: data! }));
   };

   const handleSlippageInput = (event: any) => {
      let value = decimalValidator(event.target.value);
      setCookiesSlippageProfile("userSlippageProfile", value!);
      setSwapInstruction((p) => ({ ...p, slippage: value }));
   };

   useEffect(() => {
      setSlippageClone(swapInstruction.slippage);
   }, []);

   const handlePrevStep = () => {
      setCookiesSlippageProfile("userSlippageProfile", slippageClone);
      setSwapInstruction((p) => ({ ...p, slippage: slippageClone }));
      prevStep();
   };

   const handleAutomatic = () => {
      setCookiesSlippageProfile("userSlippageProfile", "1");
      setSwapInstruction((p) => ({ ...p, slippage: "1" }));
   }

   const handleValidate = () => {
      if (Number(swapInstruction.slippage) > 25) {
         setMessageToast("Please, select a slippage under 25%");
         setShowToast(true);
      } else {
         prevStep();
      }
   }

   const disableSlipplage = (): boolean => {
      return Array.from(swapInstruction.slippage)[0] === "0" || !swapInstruction.slippage ? true : false;
   };

   return (
      <div className="swap-step0-container">
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div>
            <div>
               <IonIcon icon={optionsOutline} size="large" className="swap-step0-icon-color"></IonIcon>
            </div>
            <div>
               <IonText className="ion-text-center">
                  <h1>
                     <b>Slippage settings</b>
                  </h1>
               </IonText>
            </div>

            {isDesktop && (
               <div className="swap-step0-message">
                  <br />
                  <br />
                  <p>For immediate transactions, please default to the "Automatic" slippage.</p>
                  <br />
                  <p>Otherwise, if you wish to select manual slippage, please note that your transaction may fail if the price variance exceeds the slippage specified.</p>
               </div>
            )}

            <div className="swap-step0-message">
               <IonRange
                  min={1}
                  max={25}
                  pin={true}
                  aria-label="slippage"
                  value={Number(swapInstruction.slippage)}
                  pinFormatter={(value: number) => `${value}%`}
                  onIonChange={({ detail }) => handleSlippage(detail.value.toString())}
               ></IonRange>
               <div className="swap-step0-slippage-container-input-text">
                  
                  <div className={`swap-step0-slippage swap-step0-slippage-auto ${""+swapInstruction.slippage === "1" ? "swap-step0-slippage-selected" : ""}`}
                     onClick={handleAutomatic}>Automatic</div>
                  <div className={`swap-step0-slippage ${""+swapInstruction.slippage !== "1" ? "swap-step0-slippage-selected" : ""}`}>Manually</div>
                  <input type="text" value={swapInstruction.slippage} className="swap-step0-slippage-input" onChange={handleSlippageInput} />
                  <div>%</div>
               </div>
               {disableSlipplage() && <div className="swap-step0-slippage-error">Slippage needs to be higher or equal to 1%</div>}
            </div>

            <br />
         </div>

         {/* BUTTON BACK & SWAP */}
         <div className="swap-step0-btn-container">
            <IonButton className="swap-container-card-content-check-btn" onClick={handlePrevStep}>
               Back
            </IonButton>
            <IonButton disabled={disableSlipplage()} className="swap-container-card-content-check-btn" onClick={handleValidate}>
               Confirm
            </IonButton>
         </div>

         {!isDesktop && (
            <div className="swap-step0-message">
               <br />
               <br />
               <p className="text-balance">For immediate transactions, please default to the "Automatic" slippage.</p>
               <br />
               <p>Otherwise, if you wish to select manual slippage, please note that your transaction may fail if the price variance exceeds the slippage specified.</p>
            </div>
         )}
      </div>
   );
};

export default SwapStep0;
