import React from "react";

interface RecoveryStep2Props {
   endStep: () => void;
   seed: string;
}

const RecoveryStep2: React.FC<RecoveryStep2Props> = ({ endStep, seed }) => {
   const seeds = seed?.trim()?.split(" ");

   return (
      <div className="setting-card-content">
         <ul className="setting-ul">
            <li>Write down this 24-word Recovery Seed Phrase and save it in a place that you trust and only you can access.</li>
            <li>Never, ever share your Seed Phrase with anyone!</li>
            <li><span className="setting-recover-custom-text">Please note you can recover your Seed Phrase ONLY once.</span></li>
         </ul>
         <br />

         {seed && (
            <div className="setting-center">
               <div className="seed-grid">
                  {seeds.map((seed, index) => (
                     <div key={index} className="seed-item">
                        {seed}
                     </div>
                  ))}
               </div>
            </div>
         )}
         {seed.length > 0 && (
            <>
               <p className="setting-description">Are you sure you have written down your seedphrase?</p>
               <br />
               <div className="recovery-step2-btn-container">
                  <span>Yes, I have written it down!</span>
                  <button onClick={endStep}>Confirm</button>
               </div>
            </>
         )}
      </div>
   );
};

export default RecoveryStep2;
