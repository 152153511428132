import { DEFAULT_LIST_FOLLOWERS } from "./list";

export function useFollowerTwitter(): TwitterFollowers[] {
   return DEFAULT_LIST_FOLLOWERS;
}

export interface TwitterFollowers {
   name: string;
   handle: string;
   profileImgUrl: string;
}
