import { IonCard, IonCardContent, IonLabel, IonToast } from "@ionic/react";
import "./SettingProcess.css";
import { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import RecoveryPhase from "./RecoveryPhrase/RecoveryPhase";
import TwoFactorAuthentication from "./TwoFactorAuthentication/TwoFactorAuthentication";

const SettingProcess: React.FC = () => {
   const [twoFaStatus, setTwoFaStatus] = useState(false);
   const [tabIndex, setTabIndex] = useState(0);

   const location = useLocation();

   useEffect(() => {
      if (location.pathname === "/settings") {
         setTabIndex(0);
      }
   }, [location.pathname]);

   return (
      <>
         <IonCard className="setting-process-card">
            <IonCardContent>
               <div className="page-title">PRIVACY AND SECURITY</div>

               <IonCard className="settings-container-card">
                  <>
                     <section className="setting-totp-card">
                        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="setting-tabs">
                           <TabList>
                              <Tab>
                                 <IonLabel className="setting-tabs-label">2 FACTOR AUTHENTICATION (2FA)</IonLabel>
                              </Tab>
                              <Tab>
                                 <IonLabel className="setting-tabs-label">RECOVERY SEED PHRASE</IonLabel>
                              </Tab>
                           </TabList>

                           <TabPanel>
                              <TwoFactorAuthentication twoFaStatus={twoFaStatus} setTwoFaStatus={setTwoFaStatus} />
                           </TabPanel>
                           <TabPanel>
                              <RecoveryPhase twoFaStatus={twoFaStatus} />
                           </TabPanel>
                        </Tabs>
                     </section>
                  </>
               </IonCard>
            </IonCardContent>
         </IonCard>
      </>
   );
};

export default SettingProcess;
