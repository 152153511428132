import { TwitterFollowers } from "./hooks";

export const DEFAULT_LIST_FOLLOWERS: TwitterFollowers[] = [
   {
      name: "Elon Musk",
      handle: "@elonmusk",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "Georges Weah",
      handle: "@mr_george",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "Lily Rose",
      handle: "@lilyrose",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "Popit Mania",
      handle: "@popitmania",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "Bolero Javel",
      handle: "@bolero",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "San Gohan",
      handle: "@sangohan",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "CZ binance",
      handle: "@cz_binance",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
   {
      name: "Vitalik",
      handle: "@vitalik",
      profileImgUrl: "https://ionicframework.com/docs/img/demos/thumbnail.svg",
   },
];
