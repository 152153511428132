import React from "react";
import "./TokenUser.css";
import { IonCol, IonRow } from "@ionic/react";

import { Balance } from "../../entities/user-balances.entity";
import { create } from "zustand";
import { CHAIN_NETWORKS } from "../../utils/Constants";

interface LoadingTokenUserState {
   reset: () => void;
   isFirstTokenUserLoaded: boolean;
   setFirstTokenUserLoaded: () => void;
}

interface TokenUserProps {
   userBalances: Balance[];
   cookiesChainProfile: {
      userChainProfile?: any;
   };
   handleManageClick: () => void;
}

export const useLoadingStore = create<LoadingTokenUserState>((set) => ({
   isFirstTokenUserLoaded: false,
   setFirstTokenUserLoaded: () => set({ isFirstTokenUserLoaded: true }),
   reset: () => set({ isFirstTokenUserLoaded: false }),
}));

const TokenUser: React.FC<TokenUserProps> = ({ userBalances, cookiesChainProfile, handleManageClick }) => {

   const chain = cookiesChainProfile.userChainProfile ? CHAIN_NETWORKS[cookiesChainProfile.userChainProfile] : CHAIN_NETWORKS["bsc"];

   return (
      <div className="wallet-asset-list-container">
         {userBalances && userBalances.length > 0 ? (
            userBalances.map((token: Balance, index: number) => (
               <div className="wallet-list-single-item" key={token.symbol + index}>
                  <div className="wallet-list-single-item-symbol">
                     <img src={chain.chainLogo} alt={chain.chainName} />
                     {token.symbol}
                  </div>
                  <div className="wallet-list-single-item-amount">
                     <span>{token.balance}</span>
                  </div>
                  <div className="wallet-list-single-item-action">
                     <button className="wallet-list-single-item-action" onClick={handleManageClick}>
                        +/-
                     </button>
                  </div>
                  <div></div>
               </div>
            ))
         ) : (
            <IonRow>
               <IonCol></IonCol>
            </IonRow>
         )}
      </div>
   );
};

export default TokenUser;
