import React, { useEffect, useState } from "react";
import { userApiService } from "../../../../../../services/user/userService";
import { ApiErrorHandler } from "../../../../../../utils/ApiErrorHandler";
import { IonButton, IonToast } from "@ionic/react";
import { useLocation } from "react-router";
import Loader from "../../../../../Loader/Loader";

interface Step1Props {
   nextStep: () => void;
   twoFaStatus: boolean;
   setTwoFaStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step1: React.FC<Step1Props> = ({ nextStep, twoFaStatus, setTwoFaStatus }) => {
   const [qrCode, setQrCode] = useState("");
   const [isQrLoading, setIsQrLoading] = useState(false);
   const [showToast, setShowToast] = useState(false);
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");

   const [pin, setPin] = useState("");

   const location = useLocation();

   const init = (): void => {
      if (!isQrLoading) {
         generateQrCode();
      }
   };

   const generateQrCode = async () => {
      setIsQrLoading(true);
      const response = await userApiService.enable2FA({
         action: "enable",
      });

      const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);

      if (statusCode === 200) {
         const responseJson = await response.json();
         const imageSource = responseJson.data.qrcode.startsWith("data:image") ? responseJson.data.qrcode : `data:image/png;base64,${responseJson.data.qrcode}`;
         setQrCode(imageSource);
      } else if (statusCode === 400 && errorMessage === "2FA is already enabled") {
         setTwoFaStatus(true);
         nextStep();
      }
      setIsQrLoading(false);
   };

   useEffect(() => {
      if (location.pathname === "/settings") {
         init();
      }
   }, [location.pathname]);

   const handlePinInput = (event: any) => {
      setPin(event.target.value);
   };

   const handleValidate = async () => {
      const response = await userApiService.validate2FA({
         action: "validate",
         pin: pin,
      });

      const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);

      if (statusCode === 200) {
         setTwoFaStatus(true);
         nextStep();
      } else {
         setMessageToast(errorMessage);
         setShowToast(true);
      }
   };

   if (isQrLoading) {
      return (
         <div className="setting-loader">
            <Loader />
         </div>
      );
   }

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div className="setting-card-content">
            <p className="setting-description">
               Set up 2FA to protect and recover your account with an additional password.<br></br>
               <br />
               To get started, scan the QR code with your preferred authenticator application.
            </p>
            <br></br>
            <div className="setting-center">{qrCode && <img src={qrCode} alt="qr code" width="120" height="120" style={{ objectFit: "contain" }} className="qr-center" />}</div>

                  <div className="setting-center">
                     <h3>Enter the authentication code</h3>
                     <div className="setting-enter-code">
                        <input type="text" onChange={handlePinInput} className="setting-container-btn-input" />
                        {/* activate */}
                        <IonButton className="setting-btn-confirm" onClick={handleValidate}>
                           Confirm
                        </IonButton>
                     </div>
                  </div>
         </div>
      </>
   );
};

export default Step1;
