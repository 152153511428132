import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";
import { TrendingDTO } from "../../data/TrendingDTO";
import "./Trending.css";
import { API_TRENDING } from "../../config/api/endpoint";
const flash = "/assets/flash.png";

const defaultBotUrl = process.env.REACT_APP_TRENDING_BOT_URL;

const Trending: React.FC = () => {
   const [data, setData] = useState<any[]>([]);
   const [dataBsc, setDataBsc] = useState<any[]>([]);
   const [dataBase, setDataBase] = useState<any[]>([]);

   useEffect(() => {
      // call api with fetch
      fetch(API_TRENDING, {
         headers: {
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      })
         .then((response) => {
            if (!response.ok) {
               throw new Error("Error get data");
            }
            return response.json();
         })
         .then((data) => {
            // update data with response api
            setData(data.tokens.eth);
            setDataBsc(data.tokens.bsc);
            setDataBase(data.tokens.base);
         })
         .catch((error) => {
            console.error("Error request:", error);
         });
   }, []);

   return (
      <div className="">
         <div className="flex trending">
            <div className="flex items-center justify-center">
               <div className="text-grey trending-text">
                  <b>Trending</b>
               </div>
               <img src={flash} alt="flash" className="pr-2 icon-trending-size" />
            </div>
            <div className="overflow-auto px-4">
               <Marquee className="py-4">
                  <div className="trending-token">
                     <span className="text-grey">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ETH:
                     </span>
                     {data &&
                        (data as TrendingDTO[]).map((trending: any, index: number) => (
                           <div className="trending-token-item" key={trending.tokenSymbol + index}>
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 <img src={trending.tokenImage} className="trending-token-logo px-1" alt={trending.tokenSymbol}></img>
                              </a>
                              &nbsp;
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 #{index + 1} {trending.tokenSymbol}
                              </a>
                           </div>
                        ))}
                  </div>

                  <div className="trending-token">
                     <span className="text-grey">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; BNB:
                     </span>
                     {dataBsc &&
                        (dataBsc as TrendingDTO[]).map((trending: any, index: number) => (
                           <div className="trending-token-item" key={trending.tokenSymbol + index}>
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 <img src={trending.tokenImage} className="trending-token-logo px-1" alt={trending.tokenSymbol}></img>
                              </a>
                              &nbsp;
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 #{index + 1} {trending.tokenSymbol}
                              </a>
                           </div>
                        ))}
                  </div>

                  <div className="trending-token">
                     <span className="text-grey">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; BASE:
                     </span>
                     {dataBase &&
                        (dataBase as TrendingDTO[]).map((trending: any, index: number) => (
                           <div className="trending-token-item" key={trending.tokenSymbol + index}>
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 <img src={trending.tokenImage} className="trending-token-logo px-1" alt={trending.tokenSymbol}></img>
                              </a>
                              &nbsp;
                              <a href={trending && trending.wallet_link ? trending.wallet_link : defaultBotUrl} rel="noopener noreferrer">
                                 #{index + 1} {trending.tokenSymbol}
                              </a>
                           </div>
                        ))}
                  </div>
               </Marquee>
            </div>
         </div>
      </div>
   );
};

export default Trending;
