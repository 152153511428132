import { create } from "zustand";

interface StoreState {
    contactHandle: string;
    contactSocial: string;
    contactAmount: string;
    contactChain: string;
    contactChainId: number;
    contactTokenSymbol: string;
    contactTokenId: number;
    contactSendtransaction: string;
    contactClaimtransaction: string;
    setContactHandle: (handle: string) => void;
    setContactSocial: (handle: string) => void;
    setContactAmount: (amount: string) => void;
    setContactChain: (chain: string) => void;
    setContactChainId: (chainId: number) => void;
    setContactTokenSymbol: (tokenSymbol: string) => void;
    setContactTokenId: (amount: number) => void;
    setContactSendtransaction: (sendTransaction: string) => void;
    setContactClaimtransaction: (claimTransaction: string) => void;
    reset: () => void;
  }

  const useSendStore = create<StoreState>((set) => ({
    contactHandle: '',
    contactSocial: '',
    contactAmount: '',
    contactChain: '',
    contactChainId: 0,
    contactTokenSymbol: '',
    contactTokenId: undefined,
    contactSendtransaction: '',
    contactClaimtransaction: '',
    setContactHandle: (name) => set({ contactHandle: name }),
    setContactSocial: (social) => set({ contactSocial: social }),
    setContactAmount: (amount) => set({ contactAmount: amount }),
    setContactChain: (chain) => set({ contactChain: chain }),
    setContactChainId: (chainId) => set({ contactChainId: chainId }),
    setContactTokenSymbol: (tokenSymbol) => set({ contactTokenSymbol: tokenSymbol }),
    setContactTokenId: (tokenId) => set({ contactTokenId: tokenId }),
    setContactSendtransaction: (sendtransaction) => set({ contactSendtransaction: sendtransaction }),
    setContactClaimtransaction: (claimtransaction) => set({ contactClaimtransaction: claimtransaction }),
    reset: () => set({ contactHandle: '', contactSocial: '', contactAmount: '', contactChain: '', contactTokenSymbol: '', contactTokenId: 0, contactSendtransaction: '', contactClaimtransaction: '' }),
  }));
  
  export default useSendStore;