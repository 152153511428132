import { IonButton, IonToast } from "@ionic/react";
import React, { useState } from "react";
import { userApiService } from "../../../../../../services/user/userService";
import { ApiErrorHandler } from "../../../../../../utils/ApiErrorHandler";

interface RecoveryStep1Props {
   twoFaStatus: boolean;
   setSeed: React.Dispatch<React.SetStateAction<string>>;
   nextStep: () => void;
}

const RecoveryStep1: React.FC<RecoveryStep1Props> = ({ twoFaStatus, setSeed, nextStep }) => {
   const [pinRecover, setPinRecover] = useState("");
   const [messageToast, setMessageToast] = useState("An error occured, please try it again.");
   const [showToast, setShowToast] = useState(false);
   const [showRecoverInput, setShowRecoverInput] = useState(false);
   const [cantRecover, setCantRecover] = useState(false);

   const handleShowRecoverInput = () => {
      if (!twoFaStatus) {
         setCantRecover(!cantRecover);
      } else {
         setShowRecoverInput(!showRecoverInput);
      }
   };

   const handlePinRecoverInput = (event: any) => {
      setPinRecover(event.target.value);
   };

   const handleRecovery = async () => {
      const response = await userApiService.recovery2FA({
         pin: pinRecover,
      });

      const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);

      if (statusCode === 200) {
         const responseJson = await response.json();
         const seedJoin = responseJson.message.seed.join(" ");
         setSeed(seedJoin);
         nextStep();
      } else {
         setMessageToast(errorMessage);
         setShowToast(true);
      }
   };

   return (
      <>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={messageToast} duration={3000} color="danger" />
         <div className="setting-card-content">
            <p className="setting-description">
               <b>Your Recovery Seed Phrase is a 24-word phrase that is the "master key" to your wallet and your funds.</b>
            </p>
            <br />

            <ul className="setting-ul">
               <li>Click the Button with Recovery Seed Phrase.</li>
               <li>Write down this 24-word Recovery Seed Phrase and save it in a place that you trust and only you can access.</li>
               <li>Never, ever share your Seed Phrase with anyone!</li>
               <li><span className="setting-recover-custom-text">Please note you can recover your Seed Phrase ONLY once.</span></li>
            </ul>

            <div className="setting-center">
               <div className="setting-action-deactivate">
                  <IonButton className="setting-action-deactivate-btn" onClick={handleShowRecoverInput}>
                     Recover seedphrase
                  </IonButton>
               </div>
               <br />
            </div>

            {cantRecover ? (
               <div className="setting-description text-center">
                  <span>Please setup first the 2FA and then proceed with the recover seedphrase</span>
               </div>
            ) : (
               showRecoverInput && (
                  <div className="setting-center">
                     <h3>Enter the authentification code</h3>
                     <div className="setting-enter-code">
                        <input type="text" onChange={handlePinRecoverInput} className="setting-container-btn-input" />
                        {/* recover */}
                        <IonButton className="setting-btn-confirm" onClick={handleRecovery}>
                           <span className="setting-btn-confirm-text">Confirm</span>
                        </IonButton>
                     </div>
                  </div>
               )
            )}
         </div>
      </>
   );
};

export default RecoveryStep1;
