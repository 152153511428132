import React, { useEffect } from "react";

interface ExternalRedirectProps {
   url: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ url }) => {
   useEffect(() => {
      window.location.href = url;
   }, [url]);

   return null;
};

export default ExternalRedirect;
