import { IonCard, IonCardContent } from "@ionic/react";
import "./ClaimProcess.css";
import { useState } from "react";
import ClaimStep1 from "../ClaimStep1/ClaimStep1";
import ClaimStep2 from "../ClaimStep2/ClaimStep2";

interface ClaimProcessProps {
   claimKey: string | null;
}

const ClaimProcess: React.FC<ClaimProcessProps> = ({ claimKey }) => {
   const [step, setStep] = useState(1);

   const nextStep = () => {
      setStep((prevStep) => prevStep + 1);
   };

   return (
      <>
         <IonCard className="claim-process-card">
            <IonCardContent>
               <div className="page-title">Claim</div>

               <IonCard className="settings-container-card">
                  {step === 1 && <ClaimStep1 nextStep={nextStep} claimKey={claimKey}></ClaimStep1>}

                  {step === 2 && <ClaimStep2></ClaimStep2>}
               </IonCard>
            </IonCardContent>
         </IonCard>
      </>
   );
};

export default ClaimProcess;
