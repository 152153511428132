import React, { FC } from "react";
import "./HomeLogin.css";
import { IonText, IonGrid, IonCol, IonRow } from "@ionic/react";
import LoginSocial from "../../Login/LoginSocial/LoginSocial";
import { useMediaQuery } from "react-responsive";
import LoginSocialMobile from "../../Login/LoginSocialMobile/LoginSocialMobile";
import { useCookies } from "react-cookie";

interface HomeLoginProps { }

const HomeLogin: FC<HomeLoginProps> = () => {
   const isDesktop = useMediaQuery({ minWidth: 992 });
   const [cookies] = useCookies(["userProfile"]);

   return (
      <>
         <IonGrid fixed={true}>
            <IonCol className="container-home">
               {isDesktop && (
                  <IonText>
                     <h1 className="container-home-heading">
                        The Social <span className="text-blue-social">Media</span>
                        <span className="text-dark-blue-social"> Wallet</span>
                     </h1>
                  </IonText>
               )}
               {!isDesktop && (
                  <>
                  <IonText>
                     <h2 className="container-home-heading">
                        The Social <span className="text-blue-social">Media</span>
                        <span className="text-dark-blue-social"> Wallet</span>
                     </h2>
                  </IonText>
                  { !cookies.userProfile && (
                     <IonText className="container-home-subheading text-balance">Store and Transact Crypto with your Social Media Accounts!</IonText>
                  )}
                  
                  </>
               )}
               <br></br>

               {isDesktop ? (<>
                  <IonText className="container-home-subheading text-balance">Store and Transact Crypto with your Social Media Accounts!</IonText>
                  <LoginSocial></LoginSocial>
               </>
               ) : (
                  <>
                     {cookies.userProfile ? (
                        <LoginSocialMobile></LoginSocialMobile>
                     ) : (
                        <LoginSocial></LoginSocial>
                     )}
                  </>

               )}
            </IonCol>
         </IonGrid>
      </>
   );
};

export default HomeLogin;
