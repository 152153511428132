import { IonIcon, IonInput, IonItem, IonToast } from "@ionic/react";
import "./SearchTopbar.css";
import { useEffect, useRef, useState } from "react";
import { searchOutline } from "ionicons/icons";
import searchToken from "../../../api/search-token";
import selectToken from "../../../api/select-token";
import { useAppContext } from "../../../context/context";
import { CHAIN_NETWORKS } from "../../../utils/Constants";
import useDebounce from "../../../hooks/debounce/useDebounce";
import SearchTokenList from "../SearchTokenList/SearchTokenList";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "react-responsive";

type Token = {
   address: string;
   name: string;
   symbol: string;
   chain: number;
   priceUsd: number;
   priceChange: number;
   volume: number;
};

const SearchTopbar: React.FC = () => {
   const isDesktop = useMediaQuery({ minWidth: 992 });

   const containerRef = useRef<HTMLDivElement>(null);
   const [inputWidth, setInputWidth] = useState<number>(0);

   const history = useHistory();
   const [error, setError] = useState<string>("");
   const [isLoading, setIsLoading] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [results, setResults] = useState<Token[]>([]);
   const debounceSearchValue = useDebounce(searchValue, 300);
   const [showToast, setShowToast] = useState<boolean>(false);
   const [isListVisible, setIsListVisible] = useState<boolean>(false);
   const { setSwapInstruction } = useAppContext();
   const [cookiesChainProfile] = useCookies(["userChainProfile"]);

   const updateWidth = () => {
      if (containerRef.current) {
         const inputElement = containerRef.current.querySelector(".custom-input-search") as HTMLElement;
         if (inputElement) {
            const width = inputElement.offsetWidth;
            if (width > 0) {
               setInputWidth(width);
            }
         }
      }
   };

   useEffect(() => {
      updateWidth();

      // Set up mutation observer to detect DOM changes
      const observer = new MutationObserver(updateWidth);
      if (containerRef.current) {
         observer.observe(containerRef.current, {
            attributes: true,
            childList: true,
            subtree: true,
         });
      }

      // Set up resize observer
      const resizeObserver = new ResizeObserver(updateWidth);
      if (containerRef.current) {
         resizeObserver.observe(containerRef.current);
      }

      window.addEventListener("resize", updateWidth);

      return () => {
         observer.disconnect();
         resizeObserver.disconnect();
         window.removeEventListener("resize", updateWidth);
      };
   }, []);

   useEffect(() => {
      if (!debounceSearchValue) return;
      const fetchData = async () => {
         setIsLoading(true);
         try {
            const searchData = await searchToken(debounceSearchValue, CHAIN_NETWORKS[cookiesChainProfile.userChainProfile].chainId);
            setResults(searchData.tokens || []);
         } catch (error) {
            console.error("Error fetching tokens: ", error);
         } finally {
            setIsLoading(false);
         }
      };

      fetchData();
   }, [debounceSearchValue, cookiesChainProfile.userChainProfile]);


   return (
      <div ref={containerRef} style={{ width: "100%" }}>
         <IonItem style={{ position: "relative" }}>
            <IonInput
               id="message"
               name="message"
               value={searchValue}
               placeholder="Search Crypto"
               className="custom-input-search"
               onClick={() => setIsListVisible(true)}
               onBlur={() => setTimeout(() => setIsListVisible(false), 200)}
               onIonInput={(e) => setSearchValue(e.detail.value || "")}
            >
               <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="icon-search" />
            </IonInput>
            {isListVisible && <SearchTokenList isLoading={isLoading} tokens={results} width={inputWidth} />}
            <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={error || "Selection failed, please try it again."} duration={3000} color="danger" />
         </IonItem>
      </div>
   );
};

export default SearchTopbar;
