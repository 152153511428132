export class ApiErrorHandler {
   static async handleResponseError(response: Response): Promise<{
      statusCode: number;
      errorMessage: string;
   }> {
      if (!response.ok) {
         console.error("==== Error in API Response ====", response);

         const statusCode = response.status;
         let errorMessage: string;

     
         // read as JSON
         const errorBody = await response.json();
         errorMessage = errorBody?.message?.error;

         console.error(`Status Code: ${statusCode}, Error Message: ${errorMessage}`);

         return { statusCode, errorMessage };
      }

      // If response OK
      return { statusCode: response.status, errorMessage: "" };
   }
}
