import "./SwapStep2.css";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/context";
import { swapGenerate, swapValidate } from "../../../api/swap";
import { IonButton, IonToast } from "@ionic/react";
import Loader from "../../Loader/Loader";

interface SwapStep2Props {
   prevStep: () => void;
   nextStep: () => void;
}

const SwapStep2: React.FC<SwapStep2Props> = ({ prevStep, nextStep }) => {
   const [error, setError] = useState<string>("");
   const [tx_uuid, setTx_uuid] = useState<string>("");
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [showToast, setShowToast] = useState<boolean>(false);
   const { quote, swapInstruction, setSwapInstruction, setSwapSuccessResponse } = useAppContext();

   console.log("quote: ", quote);
   console.log("tx_uuid: ", tx_uuid);
   console.log("swapInstruction: ", swapInstruction);

   const amountOut = quote?.out_amount?.toFixed(6);
   const handleSwapGenerate = async () => {
      setIsLoading(true);
      try {
         const response = await swapGenerate({
            sell_token_id: quote.sell_token_id,
            buy_token_id: quote.buy_token_id,
            chain: quote.chain,
            amount: quote.in_amount,
            slippage: Number(swapInstruction.slippage),
         });
         console.log("response: ", response);
         const { swap, error } = response;
         if (error || swap?.error || !swap?.confirm) {
            setError(error || swap.error || "Transaction failed, please try it again.");
            setShowToast(true);
         } else {
            setTx_uuid(swap.confirm);
         }
      } finally {
         setIsLoading(false);
      }
   };

   const handleSwapConfirm = async () => {
      if (!tx_uuid) {
         console.error("tx_uuid is empty");
         return;
      }

      setIsLoading(true);
      try {
         const response = await swapValidate({ tx_uuid, confirm: true });
         console.table(response);
         const { swap, error } = response;
         if (error || swap?.error) {
            setSwapInstruction((prev) => ({ ...prev, fromNotReload: true }));
            setError(error || swap.error || "Transaction failed, please try it again.");
            setShowToast(true);
         } else {
            setSwapSuccessResponse(swap);
            nextStep();
         }
      } finally {
         setIsLoading(false);
      }
   };

   const handlePrevStep = () => {
      setSwapInstruction((prev) => ({ ...prev, fromNotReload: true }));
      prevStep();
   };

   useEffect(() => {
      if (tx_uuid) return;
      handleSwapGenerate();
   }, []);

   return (
      <div className="swap-container-card-content">
         {/* TITLE*/}

         {/* RESUME SWAP */}
         <div className="swap-step2-container">
            <div className="send-step3-item-container">
               <div className="send-step3-item-details send-step3-item-details-top">
                  <div>You pay:</div>
                  <h4>
                     {quote.in_amount} {quote?.sell_token_name?.toLocaleUpperCase()}
                  </h4>
               </div>
               <div className="send-step3-item-details send-step3-item-details-middle">
                  <div>You receive:</div>
                  <h4>
                     {quote.out_amount?.toFixed(6)} {quote?.buy_token_name?.toLocaleUpperCase()}
                  </h4>
               </div>
               <div className="send-step3-item-details send-step3-item-details-middle">
                  <div>Price:</div>
                  <h4>
                     {quote.in_amount} {quote?.sell_token_name?.toLocaleUpperCase()} ≃ {amountOut} {quote?.buy_token_name?.toLocaleUpperCase()}
                  </h4>
               </div>
               <div className="send-step3-item-details send-step3-item-details-middle">
                  <div>Network fee:</div>
                  <h4>${quote.fees?.toFixed(4)}</h4>
               </div>
               <div className="send-step3-item-details send-step3-item-details-bottom">
                  <div>Slippage:</div>
                  <h4>{swapInstruction.slippage}%</h4>
               </div>
            </div>
         </div>

         <br />

         {/* BUTTON BACK & SWAP */}
         <div className="swap-content-btn-container">
            <IonButton className="swap-container-card-content-check-btn" onClick={handlePrevStep}>
               Back
            </IonButton>
            <IonButton className="swap-container-card-content-check-btn" onClick={handleSwapConfirm} disabled={isLoading || error.length > 0}>
               {isLoading ? <Loader /> : "Confirm"}
            </IonButton>
         </div>
         <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={error || "Transaction failed, please try it again"} duration={15000} color="danger" />
      </div>
   );
};

export default SwapStep2;
