import "./TopBarNotConnected.css";

import SearchTopbar from "../SearchTopbar/SearchTopbar";
import BlockchainSelect from "../BlockchainSelect/BlockchainSelect";
import ModalLogin from "../../Login/ModalLogin/ModalLogin";
import { useMediaQuery } from "react-responsive";
import { IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";

interface TopBarNotConnectedProps {
   setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
   searchOpen: boolean;
}

const TopBarNotConnected: React.FC<TopBarNotConnectedProps> = ({ searchOpen, setSearchOpen }) => {
   const isDesktop = useMediaQuery({ minWidth: 992 });
   return (
      <>
         <div className="not-connected-search">
            {isDesktop ? (
               <SearchTopbar></SearchTopbar>
            ) : (
               <IonIcon slot="start" icon={searchOutline} aria-hidden="true" className="mobile-search-icon" onClick={() => setSearchOpen(!searchOpen)} />
            )}
         </div>

         <div className="container-not-connected-right">
            {isDesktop && <BlockchainSelect></BlockchainSelect>}
            <ModalLogin></ModalLogin>
         </div>
      </>
   );
};

export default TopBarNotConnected;
