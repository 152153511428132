import React from "react";
const DuoIconsApproved = () => (
   <svg width={87} height={87} viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         opacity="0.3"
         fillRule="evenodd"
         clipRule="evenodd"
         d="M38.3738 7.61245C39.657 6.33091 41.3739 5.57684 43.1858 5.499C44.9976 5.42115 46.7729 6.02519 48.1613 7.19195L48.6253 7.61607L55.5128 14.4999H65.2495C67.078 14.5003 68.8388 15.1915 70.1792 16.4351C71.5196 17.6787 72.3406 19.3829 72.4778 21.2062L72.4995 21.7499V31.4867L79.387 38.3742C80.6696 39.6576 81.4243 41.3752 81.5021 43.188C81.58 45.0007 80.9753 46.7767 79.8075 48.1653L79.3834 48.6257L72.4959 55.5132V65.2499C72.4965 67.079 71.8057 68.8408 70.562 70.1819C69.3183 71.5231 67.6136 72.3446 65.7896 72.4818L65.2495 72.4999H55.5164L48.6289 79.3874C47.3455 80.67 45.6279 81.4247 43.8151 81.5026C42.0024 81.5804 40.2264 80.9757 38.8378 79.8079L38.3774 79.3874L31.4899 72.4999H21.7495C19.9204 72.5005 18.1587 71.8097 16.8175 70.566C15.4764 69.3223 14.6548 67.6176 14.5177 65.7937L14.4995 65.2499V55.5132L7.61203 48.6257C6.32948 47.3423 5.57477 45.6247 5.49692 43.8119C5.41907 41.9992 6.02374 40.2232 7.19153 38.8346L7.61203 38.3742L14.4995 31.4867V21.7499C14.4999 19.9215 15.1911 18.1607 16.4347 16.8203C17.6783 15.4798 19.3825 14.6588 21.2058 14.5217L21.7495 14.4999H31.4863L38.3738 7.61245Z"
         fill="#2947AC"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M54.6618 32.5634L39.2773 47.9479L32.8683 41.5389C32.1881 40.8591 31.2657 40.4775 30.3041 40.4778C29.3425 40.4782 28.4205 40.8605 27.7407 41.5407C27.061 42.2209 26.6793 43.1432 26.6797 44.1048C26.68 45.0664 27.0623 45.9885 27.7425 46.6682L36.457 55.3827C36.8273 55.7532 37.267 56.0471 37.7509 56.2476C38.2348 56.4481 38.7535 56.5513 39.2773 56.5513C39.8011 56.5513 40.3198 56.4481 40.8037 56.2476C41.2876 56.0471 41.7272 55.7532 42.0975 55.3827L59.7875 37.6891C60.4479 37.0054 60.8132 36.0897 60.805 35.1393C60.7967 34.1888 60.4155 33.2796 59.7434 32.6075C59.0713 31.9354 58.1621 31.5542 57.2116 31.5459C56.2612 31.5377 55.3455 31.903 54.6618 32.5634Z"
         fill="#3259DE"
      />
   </svg>
);

export default DuoIconsApproved;
