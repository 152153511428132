import { IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";
import TopBarConnected from "../TopBarConnected/TopBarConnected";
import TopBarNotConnected from "../TopBarNotConnected/TopBarNotConnected";
import "./TopToolBar.css";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "react-responsive";
import SearchTopbar from "../SearchTopbar/SearchTopbar";

interface TopToolBarProps {
   items: any;
}

const TopToolBar: React.FC<TopToolBarProps> = ({ items }) => {
   const [provider, setProvider] = useState("");
   const history = useHistory();

   const [, , removeCookie] = useCookies(["userProfile"]);
   const [, , removeClaimKeyCookie] = useCookies(["claimKey"]);
   const [searchOpen, setSearchOpen] = useState(false);

   const handleLogout = () => {
      items = undefined;
      setProvider("");
      localStorage.clear();
      removeCookie("userProfile", { path: "/" });
      removeClaimKeyCookie("claimKey", { path: "/" });
      history.push(`/home`);
   };

   const isDesktop = useMediaQuery({ minWidth: 992 });

   return (
      <>
         <div className="top-toolbar-container">
            {!isDesktop && (
               <h1 className="title">
                  <div className="container-logo">
                     <IonImg className="img-logo" src="/assets/logo2.png" alt="img-logo"></IonImg>
                     <span className="logo0 text-logo">SOCIAL</span>
                     <span className="logo1 text-logo">PAL</span>
                  </div>
               </h1>
            )}
            {items && items?.userdata ? (
               <TopBarConnected handleLogout={handleLogout} provider={provider} profile={items} setSearchOpen={setSearchOpen} searchOpen={searchOpen} />
            ) : (
               <TopBarNotConnected setSearchOpen={setSearchOpen} searchOpen={searchOpen} />
            )}
         </div>
         {!isDesktop && searchOpen && <SearchTopbar />}
      </>
   );
};

export default TopToolBar;
