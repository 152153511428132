import { getToken, setTokenAccess, setTokenRefresh } from "../../services/auth/authService";
import { API_URLS } from "../../config/api/endpoint";
import { ApiErrorHandler } from "../../utils/ApiErrorHandler";

interface AuthTokenResponse {
   access: string;
   refresh: string;
}

interface AuthTokenResult {
   token: string | null;
   error: string | null;
}

const authTokenService = async (): Promise<AuthTokenResult> => {
   try {
      const jwt = getToken();

      if (!jwt) {
         return { token: null, error: "No token or auth data available" };
      }
      const response = await fetch(`${API_URLS.REFRESH_TOKEN}?token=${jwt?.access}`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
         body: JSON.stringify({
            // auth: authData,
            refresh: jwt?.refresh,
         }),
      });

      const { statusCode, errorMessage } = await ApiErrorHandler.handleResponseError(response);
      
      if (statusCode === 200) {
         const data: AuthTokenResponse = await response.json();
         setTokenAccess(data.access);
         setTokenRefresh(data.refresh);
         return { token: data.access, error: null };
      } else {
         console.log('error when refresh token', errorMessage);
         return { token: null, error: errorMessage };
      }
   } catch (err: any) {
      console.error("Error when refresh token : ", err);
      return { token: null, error: err.message };
   }
};

export default authTokenService;
