import { API_URLS } from "../../config/api/endpoint";
import authTokenService from "../auth/authTokenService";


export interface AddContactParams {
    user: string;
    social: string;
}
 

export interface DelContactParams {
    user: string;
    social: string;
}

class ContactUserService {
  private readonly headers: HeadersInit = {
    'Content-Type': 'application/json',
    "ct-token": process.env.REACT_APP_CT_TOKEN || "",
    "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
  };

  /**
   * API Get Contacts
   * @returns 
   */
  async getContacts(): Promise<Response> {

    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.USER_CONTACTS}`, {
      method: 'GET',
      headers: mergeHeaders,
    });
    if (!response.ok) {
      console.warn("no contacts available");
    }
    const data = await response;
    return data;

  }


  async addContact(params: AddContactParams): Promise<Response> {

    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.USER_ADD_CONTACTS}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no contacts available");
    }
    const data = await response;
    return data;

  }
  
  async delContact(params: DelContactParams): Promise<Response> {

    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.USER_DEL_CONTACTS}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no contacts available");
    }
    const data = await response;
    return data;

  }

}

export const contactUserService = new ContactUserService();