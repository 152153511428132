import { create } from "zustand";


interface TokenUserState {
  reloadBalance: Number;
  triggerReloadBalance: () => void;
}

const useTokenUserStore = create<TokenUserState>((set) => ({
  reloadBalance: Date.now(),
  triggerReloadBalance: () => set({ reloadBalance: Date.now() })
}));

/*
const useTokenUserStore = create((set) => ({
  reloadBalance: Date.now(),
  triggerReloadBalance: () => set({ reloadBalance: Date.now() }),
}));
*/
export default useTokenUserStore;