import { IonContent, IonHeader, IonPage } from "@ionic/react";
import "./Swap.css";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import Trending from "../../components/Trending/Trending";
import { useLocation } from "react-router";
import SwapProcess from "../../components/Swap/SwapProcess/SwapProcess";
import { useCookies } from "react-cookie";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";

const Swap: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);

   const location = useLocation();

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            {cookies.userProfile ? (
               <SwapProcess key={location.pathname}></SwapProcess>
            ) : (
               <>
                  <HomeLogin></HomeLogin>
               </>
            )}
         </IonContent>
      </IonPage>
   );
};

export default Swap;
