import { IonButton, IonContent, IonFooter, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonPopover } from "@ionic/react";
import { useLocation } from "react-router-dom";
import {
   walletOutline,
   walletSharp,
   documentOutline,
   documentSharp,
   swapHorizontalOutline,
   swapHorizontalSharp,
   arrowUpOutline,
   arrowUpSharp,
   arrowDownOutline,
   arrowDownSharp,
   settingsOutline,
   settingsSharp,
   chevronUp,
} from "ionicons/icons";
import { chevronDown, chevronForward, personOutline } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";
import "./Menu.css";
import { useState } from "react";
import React from "react";

interface AppPage {
   url: string;
   iosIcon: string;
   mdIcon: string;
   title: string;
}

const appPages: AppPage[] = [
   {
      title: "Assets",
      url: "/home",
      iosIcon: walletOutline,
      mdIcon: walletSharp,
   },
   {
      title: "Swap",
      url: "/swap",
      iosIcon: swapHorizontalOutline,
      mdIcon: swapHorizontalSharp,
   },
   {
      title: "Send",
      url: "/send",
      iosIcon: arrowUpOutline,
      mdIcon: arrowUpSharp,
   },
   {
      title: "Receive",
      url: "/receive",
      iosIcon: arrowDownOutline,
      mdIcon: arrowDownSharp,
   },
];

interface MenuProps {
   profile: any;
}

const Menu: React.FC<MenuProps> = () => {
   const location = useLocation();
   const isDesktop = useMediaQuery({ minWidth: 992 });

   const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

   const toggleSubmenu = () => {
      setIsSubmenuOpen(!isSubmenuOpen);
   };

   return (
      <>
         {isDesktop && (
            <IonMenu contentId="main" type="overlay" className="custom-input menu-border">
               <IonContent className="side-menu-container">
                  <div className="side-menu-container">
                     <IonList id="inbox-list" className="menu-list">
                        <IonListHeader className="header-padding menu-padding-border">
                           <h1 className="title">
                              <div className="container-logo">
                                 <IonImg className="img-logo" src="/assets/logo2.png" alt="logo"></IonImg>
                                 <span className="logo0 text-logo">SOCIAL</span>
                                 <span className="logo1 text-logo">PAL</span>
                              </div>
                           </h1>
                        </IonListHeader>
                        <div className="app-links-container">
                           {appPages.map((appPage, index) => {
                              return (
                                 <IonMenuToggle key={index} autoHide={false} className="">
                                    <IonItem
                                       className={`${location.pathname === appPage.url ? "selected" : ""}`}
                                       routerLink={appPage.url}
                                       onClick={() => setIsSubmenuOpen(false)}
                                       routerDirection="none"
                                       lines="none"
                                       detail={false}
                                    >
                                       <div className="app-link-item">
                                          <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                          <IonLabel className="menu-text">{appPage.title}</IonLabel>
                                       </div>
                                    </IonItem>
                                 </IonMenuToggle>
                              );
                           })}
                        </div>
                     </IonList>

                     <IonFooter>
                        <div className="footer-links-container">
                           <IonItem
                              className={location.pathname === "/settings" ? "selected" : ""}
                              routerLink="/settings"
                              routerDirection="none"
                              lines="none"
                              detail={false}
                              onClick={() => setIsSubmenuOpen(false)}
                           >
                              <div className="app-link-item">
                                 <IonIcon className="menu-bottom-icon" slot="start" ios={settingsOutline} md={settingsSharp} />
                                 <IonLabel className="menu-docs">Settings</IonLabel>
                              </div>
                           </IonItem>

                           <a className="menu-bottom-link" href="https://docs.socialpal.io/" target="_blank" rel="noopener noreferrer" onClick={() => setIsSubmenuOpen(false)}>
                              <IonItem lines="none" detail={false}>
                                 <div className="app-link-item">
                                    <IonIcon className="menu-bottom-icon" ios={documentOutline} md={documentSharp} />
                                    <IonLabel className="menu-docs">Documentation</IonLabel>
                                 </div>
                              </IonItem>
                           </a>
                           <div className="social-menu-container">
                              <IonItem button onClick={toggleSubmenu}>
                                 <div className="social-btn-container">
                                    <div className="social-btn-item">
                                       <IonIcon slot="start" icon={personOutline} />
                                       <IonLabel>Socials</IonLabel>
                                    </div>
                                    <IonIcon slot="end" icon={isSubmenuOpen ? chevronUp : chevronForward} />
                                 </div>
                              </IonItem>

                              <div className={`submenu-wrapper ${isSubmenuOpen ? "open" : ""}`}>
                                 <div className="submenu-content">
                                    <a
                                       href="https://t.me/SocialPal_Web3"
                                       target="_blank"
                                       rel="noreferrer"
                                       className="footer-menu-social-item-link"
                                       onClick={() => setIsSubmenuOpen(false)}
                                    >
                                       <div className="social-link-item">
                                          <IonImg className="icon-socials-custom" src="/assets/icon/icon-telegram2.png" alt="telegram"></IonImg>
                                          <IonLabel className="footer-menu-social-item-label">Telegram</IonLabel>
                                       </div>
                                    </a>

                                    <a
                                       href="https://x.com/socialpal_web3"
                                       target="_blank"
                                       rel="noreferrer"
                                       className="footer-menu-social-item-link"
                                       onClick={() => setIsSubmenuOpen(false)}
                                    >
                                       <div className="social-link-item">
                                          <IonImg className="icon-socials-custom" src="/assets/icon/icon-x.png" alt="x"></IonImg>
                                          <IonLabel className="footer-menu-social-item-label">X</IonLabel>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </IonFooter>
                  </div>
               </IonContent>
            </IonMenu>
         )}
      </>
   );
};

export default Menu;
