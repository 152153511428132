import "./SwapStep3.css";
import { IonText, IonButton } from "@ionic/react";
import { useAppContext } from "../../../context/context";
import useTokenUserStore from "../../../store/TokenUserStore";
import { useEffect } from "react";
import DuoIconsApproved from "../../../assets/DuoApprovedIcon";

interface SwapStep3Props {
   closeBegin: () => void;
}

const SwapStep3: React.FC<SwapStep3Props> = ({ closeBegin }) => {
   const { swapSuccessResponse, quote, setSwapSuccessResponse, setQuote, setSwapInstruction } = useAppContext();
   console.log("swapSuccessResponse: ", swapSuccessResponse);

   const triggerReloadBalance = useTokenUserStore((state) => state.triggerReloadBalance);

   useEffect(() => {
      triggerReloadBalance();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleClose = () => {
      setSwapInstruction((prev) => ({ ...prev, fromNotReload: true }));
      setQuote({} as any);
      setSwapSuccessResponse({} as any);
      closeBegin();
   };

   return (
      <>
         <div className="swap-step3-container">
            <div>
               <div className="send-swap3-icon">
                  <div className="send-swap3-icon-circle">
                     <DuoIconsApproved />
                  </div>
               </div>
               <div>
                  <br></br>
                  <IonText className="ion-text-center">
                     <h1>
                        <b>Transaction successful.</b>
                     </h1>
                  </IonText>
               </div>
               <div className="swap-step3-message text-center">
                  {quote.in_amount} {quote.sell_token_name.toUpperCase()} was successfully swapped for {quote.out_amount?.toFixed(6)} {quote.buy_token_name.toUpperCase()}{" "}
               </div>
               <br />
               <div style={{ textAlign: "center" }}>
                  <a href={swapSuccessResponse.explorer_url} target="_blank" rel="noreferrer" className="swap-transaction-url">
                     View transaction
                  </a>
               </div>
               <br></br>
               <br></br>
            </div>
            <div className="send-step4-close">
               <div className="send-step4-close-btn">
                  <a href="/swap">
                  <IonButton className="send-content-add-contact-btn">
                     Back
                  </IonButton>
                  </a>
               </div>
            </div>
         </div>
      </>
   );
};

export default SwapStep3;
