import { API_URLS } from "../../config/api/endpoint";
import authTokenService from "../auth/authTokenService";

export interface Enable2FAParams {
  action: string;
}

export interface Validate2FAParams {
  action: string;
  pin: string;
}

export interface Disable2FAParams {
  action: string;
  pin: string;
}

export interface Recovery2FAParams {
  pin: string;
}

export interface Auth2FAParams {
  handle: string;
  social: string;
  pin: string;
}



class UserApiService {

  private readonly headers: HeadersInit = {
    'Content-Type': 'application/json',
    "ct-token": process.env.REACT_APP_CT_TOKEN || "",
    "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
  };
  /**
   * API Enable 2FA
   * @param params
   * @returns 
   */
  async enable2FA(params: Enable2FAParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.ENABLE_2FA}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no enable2FA generated");
    }
    const data = await response;
    return data;
  }

  async validate2FA(params: Validate2FAParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.VALIDATE_2FA}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no validate2FA generated");
    }
    const data = await response;
    return data;

  }

  async disable2FA(params: Disable2FAParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.DISABLE_2FA}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no disable2FA generated");
    }
    const data = await response;
    return data;

  }

  async recovery2FA(params: Recovery2FAParams): Promise<Response> {
    
    const result = await authTokenService();
    const mergeHeaders = { ...this.headers, 'Authorization': `Bearer ${result.token}`};

    const response = await fetch(`${API_URLS.RECOVERY_2FA}`, {
      method: 'POST',
      headers: mergeHeaders,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no recovery2FA generated");
    }
    const data = await response;
    return data;

  }

  async auth2FA(params: Auth2FAParams): Promise<Response> {

    const response = await fetch(`${API_URLS.RECOVERY_USER_2FA}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(params)
    });
    if (!response.ok) {
      console.warn("no auth2FA generated");
    }
    const data = await response;
    return data;
  }

}
export const userApiService = new UserApiService();