import { API_URLS } from "../config/api/endpoint";
import authTokenService from "../services/auth/authTokenService";
import { ApiErrorHandler } from "../utils/ApiErrorHandler";

const selectToken = async (address: string, chain: number) => {
   try {
      const result = await authTokenService();
      const response = await fetch(`${API_URLS.SELECT_TOKEN}`, {
         method: "POST",
         body: JSON.stringify({ address, chain }),
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });
      await ApiErrorHandler.handleResponseError(response);

      if (response.ok) {
         const data = await response.json();
         return { token: data.message.token, error: null };
      } else {
         return { token: null, error: 'Failed to select token' };
      }
   } catch (err: any) {
      console.error("Error when select token : ", err);
      return { token: null, error: err.message };
   }
};

export default selectToken;
