import { IonContent, IonHeader, IonPage } from "@ionic/react";

import "./Send.css";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import { useCookies } from "react-cookie";
import SendProcess from "../../components/Send/SendProcess/SendProcess";
import Trending from "../../components/Trending/Trending";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";

const Send: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            {cookies.userProfile ? (
               <>
                  <SendProcess></SendProcess>
               </>
            ) : (
               <>
                  <HomeLogin></HomeLogin>
               </>
            )}
         </IonContent>
      </IonPage>
   );
};

export default Send;
