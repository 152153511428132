import React, { FC, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./MenuWalletTabsNotConnected.css";
import { IonLabel } from "@ionic/react";
import TokenPublic from "../../../TokenPublic/TokenPublic";
import { useCookies } from "react-cookie";

interface MenuTabsProps {}

const MenuWalletTabsNotConnected: FC<MenuTabsProps> = () => {
   const [tabIndex, setTabIndex] = useState(0);

   const [cookiesChainProfile] = useCookies(["userChainProfile"]);

   return (
      <>
         {/* <div className="container-react-tabs">
         <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
            <Tab>
            <IonLabel className="menu-wallet-tabs-label">Crypto</IonLabel>
            </Tab>
            </TabList>
            <TabPanel>
            <TokenPublic chain={cookiesChainProfile.userChainProfile}></TokenPublic>
            </TabPanel>
            </Tabs>

         
      </div> 
      
         <div className="wallet-assets-container">
            <div className="wallet-asset-header-container">
               <span className="wallet-asset-tab-title">Crypto</span>
            </div>

            <TokenPublic chain={cookiesChainProfile.userChainProfile} />
         </div>
         */}
      </>
   );
};

export default MenuWalletTabsNotConnected;
