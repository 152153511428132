import { API_URLS } from "../config/api/endpoint";
import { ChainProfile } from "../entities/chains.entity";
import authTokenService from "../services/auth/authTokenService";


const getBalance = async (chain: ChainProfile, id = -1) => {
   try {
      const result = await authTokenService();
      const url = id === -1 ? `${API_URLS.BASE_URL_BALANCE}${chain}/-1` : `${API_URLS.BASE_URL_BALANCE}${chain}/${id}`;
      const response = await fetch(url, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${result.token}`,
            "ct-token": process.env.REACT_APP_CT_TOKEN || "",
            "ct-origin": process.env.REACT_APP_CT_ORIGIN || "",
         },
      });

      if (!response.ok) {
         const error = await response.text();
         console.log('error: ', error);
         return { balance: null, error: "Failed to fetch balance" };
      }
      const data = await response.json();
      return { balance: Number(data.message.balance), error: null };
   } catch (err: any) {
      console.error("Error when fetch balance: ", err);
      return { balance: null, error: err.message };
   }
};

export default getBalance;