import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { useCookies } from "react-cookie";
import TopToolBar from "../../components/Top/TopToolBar/TopToolBar";
import Trending from "../../components/Trending/Trending";
import SettingProcess from "../../components/Setting/SettingProcess/SettingProcess";
import "./Settings.css";
import HomeLogin from "../../components/Home/HomeLogin/HomeLogin";

const Settings: React.FC = () => {
   const [cookies] = useCookies(["userProfile"]);

   return (
      <IonPage>
         <IonHeader>
            <TopToolBar items={cookies.userProfile}></TopToolBar>
            <Trending></Trending>
         </IonHeader>
         <IonContent>
            {cookies.userProfile ? (
               <>
                  <SettingProcess></SettingProcess>
               </>
            ) : (
               <HomeLogin />
            )}
         </IonContent>
      </IonPage>
   );
};

export default Settings;
